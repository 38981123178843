import { Backdrop, Box, Chip, CircularProgress, ClickAwayListener, Grid, IconButton, InputAdornment, TextField, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Snackbar from '@material-ui/core/Snackbar';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CancelIcon from '@material-ui/icons/Cancel';
import SearchIcon from "@material-ui/icons/Search";
import { Autocomplete, AutocompleteCloseReason } from '@material-ui/lab';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import Paper from '@mui/material/Paper';
import React, { useState } from "react";
import { Scrollbars } from 'react-custom-scrollbars';
import PuffLoader from "react-spinners/PuffLoader";
import { ClientUserAccessContext, trustStatementReportShareStatus } from "../../models/accesscontrol/ClientUserAccessContext";
import { IUserInfo } from '../../models/accesscontrol/IUserInfo';
import { IClientInfo } from '../../models/client/IClientInfo';
import { GlobalStateAction, useGlobalState } from '../../store/GlobalStore';
import { useFetch, usePost } from '../../utils/apiHelper';
import { DialogTitleHeader } from '../GlobalStyles/DialogStyle';
import { Transition } from '../../components/GlobalStyles/DialogBoxTransition';

const useRowStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
        marginBottom: "0%",
        margin: theme.spacing(2),
        width: '25ch',
      },
    },
    button: {
      borderRadius: 20,
      fontSize: 11,
      background: "green",
      '&:hover': {
        background: "green",
      }
    },
    formControl: {
      width: "385px",
      margin: theme.spacing(2),
      minWidth: 385,
      borderBottom: '3px solid blue',
      borderRadius: '5px',
    },
    edit: {
      float: 'right',
      marginRight: '35px',
      marginBottom: '0.3cm',
      borderRadius: '50px',
      backgroundColor: 'green',
      '&:hover': {
        backgroundColor: 'green',
      }
    },
    close1: {
      float: 'right',
      marginBottom: '0.3cm',
      marginRight: '10px',
      borderRadius: '50px',
      backgroundColor: 'red',
      '&:hover': {
        backgroundColor: 'red',
      }
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    GreenColor: {
      color: 'green',
    },
    paper1: {
      border: "2px solid black",
    },
    titleheader1: {
      marginLeft: '2.3cm',
      fontSize: 17,
      marginTop: '20px'
    },
    titleheader: {
      fontWeight: 'bold',
      marginTop: '5px',
      color: 'white'
    },
    checkBoxStyle: {
      backgroundColor: "white",
      height: "45px",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap"
    },
    IconSizeStyle: {
      height: '25px',
      width: '25px'
    },
    textBoxStyle: {
      marginTop: '10px',
      fontSize: 10,
    },
    LabelBoxStyle: {
      marginLeft: '15px'
    },
    paper2: {
      border: '2px solid black',
      height: 'auto',
      margin: 0
    },
    clientCodeBox: {
      width: '385px',
      marginLeft: '15px',
      marginTop: '15px',
      borderRadius: '5px',
      '& .MuiOutlinedInput-root': {
        borderBottom: '3px solid blue',
        borderRadius: '5px'
      }
    },
    boxBorder: {
      marginTop: '10px',
      border: '2px solid gray',
      marginLeft: '15px',
      padding: '2px 8px',
      borderRadius: '5px',
      maxWidth: '385px',
      boxShadow: 'rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset'
    },
    textBoxStyle2: {
      fontSize: 10,
      '& .MuiChip-labelSmall': {
        fontSize: 10
      }
    },
    scrollMargin: {
      '& div:first-child': {
        marginBottom: '5px',
      },
      '& div:nth-child(3) div': {
        backgroundColor: '#2377e4 !important'
      },
      '& div:nth-child(3)': {
        top: '10px !important',
        bottom: '5px !important'
      },
    },
    valueContainer: {
      "& > :not(:last-child)": {
        marginRight: '5px'
      },
      "& > *": {
        marginBottom: '5px'
      }
    },
    CheckBoxBorder: {
      borderTop: '1px solid gray'
    },
    textFieldStyle: {
      minWidth: 385,
      backgroundColor: 'transparent',
      color: "white",
      height: 40,
      fontSize: 12,
      fontWeight: 'bold',
      borderRadius: '10px',
      border: 'none',
    },
    outlinedInput: {
      fontSize: 14,
      color: "black",
      height: 40,
      border: 'none',
      '&$focused $notchedOutline': {
        color: 'white',
        border: 'none'
      },
    },
    noBorder: {
      border: "1px solid #215176",
      fontWeight: 'bold',
      borderBottom: "3px solid blue",
      fontSize: 14,
      '&:hover': {
        borderBottom: "3px solid blue",
      }
    },
    NameTextBoxStyle: {
      width: "40%",
      backgroundColor: 'transparent',
      color: "white",
      height: 40,
      fontSize: 12,
      fontWeight: 'bold',
      borderRadius: '10px',
      border: 'none',
    },
    dialogePaper: {
      minWidth: "800px"
    },
    permission: {
      margin: 0,
      fontSize: 14,
    }
  })
);


const EditUser: React.FC<{ userRow: IUserInfo, onSave: (userRow: IUserInfo) => void, onRemove: () => void }> = (props) => {
  const { userRow } = props
  const classes = useRowStyles();
  const [open, setOpen] = React.useState(false);
  const { state, dispatch } = useGlobalState();
  var newUser: ClientUserAccessContext = new ClientUserAccessContext();
  const [localState, setLocalState] = React.useState(newUser);
  const [confirmedClients, setConfirmedClients] = React.useState([]);
  const [selectedClients, setSelectedClients] = useState<any>([]);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [firstnameerror, setFirstNameError] = useState("");
  const [Lastnameerror, setLastNameError] = useState("");
  const [isDisable, setIsDisable] = useState(false);
  const [submit, setSubmit] = React.useState(false);
  const [ProgressBar, setShowProgressBar] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [checkAll, setCheckAll] = React.useState(false);
  const [ClientCodeOpen, setClientCodeOpen] = React.useState(false);
  const [searchClientCode, setSearchClientCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [deselectClient, setDeselectClient] = useState(false);

  const handleClickOpen = () => {
    (async () => {
      dispatch({ type: GlobalStateAction.Busy })
      setConfirmedClients([]);
      setLoading(true);
      setOpen(true);
      let request = {
        "userId": state?.userAccessContext?.id,
        "client_code": searchClientCode?.trim().toLocaleUpperCase(),
        "isAdmin": false
      }
      await useFetch<ClientUserAccessContext>("User/GetUser?userId=" + userRow.id).then(async (UserList) => {
        setLocalState(UserList.data);
        await usePost<IClientInfo[]>("Client/searchClientCode", request).then((GetClientCode) => {
          setConfirmedClients(GetClientCode.data);
          setLoading(false);
          {
            UserList.data && !selectedClients.length ?
              setSelectedClients(GetClientCode.data.filter(p => {
                return UserList.data.clientCodes?.filter(r => { return r === p.client_Code }).length > 0
              }))
              : null
          }
        })
      }).finally(() => {
        setCheckAll(false);
      });
    })()
  }

  const handleClose = () => {
    setOpen(false);
    setFirstNameError(null);
    setLastNameError(null);
    setClientCodeOpen(false);
    setCheckAll(false);
    setSearchClientCode("");
    setConfirmedClients([]);
    setSelectedClients([]);
  };

  const handleSave = () => {
    (async () => {
      setOpen(false);
      setShowProgressBar(true);
      let user = localState;
      user.clientCodes = selectedClients.map(r => r.client_Code);
      if (selectedClients.length <= 0) {
        setIsValid(true);
      } else {
        await usePost("User/SaveUser", user).then((saveUserDetails) => {
          if (saveUserDetails.status == 400) {
            dispatch({ type: GlobalStateAction.Error, error: saveUserDetails.statusText })
          }
          else if (saveUserDetails.status == 200) {
            (async () => {
              let trustStatementReportRequest = {
                "actionTakenBy": state?.userAccessContext?.id,
                "emailAddress": userRow?.userName,
                "actionId": localState?.trustStatement ? trustStatementReportShareStatus.TrustNotificationAccessProvided : trustStatementReportShareStatus?.TrustNotificationAccessRemoved,
              }
              await usePost<any>("InsertTrustReportShareHistory", trustStatementReportRequest);
            })()
            props.onSave(localState);
            setShowProgressBar(false);
            setSubmit(true);
          }
        }).finally(() => {
          setShowProgressBar(false);
        });
      }
    })()
  };

  const HandleFirstNameVal = (e) => {
    const FirstNameVal = e.target.value;
    if (!FirstNameVal.match(/[~!@#%^&*()-=+<>,.{};|?:[\]\\$'"]/)) {
      setFirstNameError("");
      setIsDisable(false);
    } else {
      setFirstNameError("please enter characters only");
      setIsDisable(true);
    }
  };

  const HandleLastNameVal = (e) => {
    const LastNameVal = e.target.value;
    if (!LastNameVal.match(/[~!@#%^&*()-=+<>,.{};|?:[\]\\$'"]/)) {
      setLastNameError("");
      setIsDisable(false);
    } else {
      setLastNameError("please enter characters only");
      setIsDisable(true);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setLocalState({
      ...localState,
      [name]: event.target.value
    });
  };

  const handleChecked = (event) => {
    const name = event.target.name;
    setLocalState({
      ...localState,
      [name]: event.target.checked
    });
  };

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSubmit(false);
    setIsValid(false);
  };

  const handleClientSelect = (event, selected) => {
    setSelectedClients(selected);
    if (selected?.length <= 0) {
      setCheckAll(false);
    }
  }
  const checkAllChange = (event) => {
    setCheckAll(event.target.checked);
    if (event.target.checked) {
      var NewClientList = confirmedClients.filter((c) => {
        return !selectedClients.find((r) => {
          return c.client_Code == r.client_Code
        })
      });
      setSelectedClients([...new Set(selectedClients?.concat(NewClientList?.map((r) => r)))]);
      setClientCodeOpen(false);
    }
    setCheckAll(false);
    handleClear();
    setSearchClientCode("");
  };

  const DeselectAllChange = (event) => {
    setDeselectClient(event.target.checked);
    if (event.target.checked) {
      var NewClientList = selectedClients.filter((c) => {
        return !confirmedClients.find((r) => {
          return c.client_Code == r.client_Code
        })
      });
      setSelectedClients(NewClientList);
      setClientCodeOpen(false);
    }
    setDeselectClient(false);
    handleClear();
    setSearchClientCode("");
  };

  const handleSearchClient = (e) => {
    setSearchClientCode(e.target.value);
  }

  const handleClear = () => {
    (async () => {
      setOpen(true);
      let request = {
        "userId": state?.userAccessContext?.id,
        "client_code": "",
        "isAdmin": false
      }
      await usePost<IClientInfo[]>("Client/searchClientCode", request).then((r) => {
        setConfirmedClients(r.data);
      }).finally(() => {
        setShowProgressBar(false);
      });
    })()
  };

  const handleClickAway = (e) => {
    (async () => {
      setClientCodeOpen(false);
      let request = {
        "userId": state?.userAccessContext?.id,
        "client_code": "",
        "isAdmin": false
      }
      await usePost<IClientInfo[]>("Client/searchClientCode", request).then((r) => {
        setConfirmedClients(r.data);
      }).finally(() => {
        setShowProgressBar(false);
      });
    })()
  };

  const onDelete = (clientcode) => () => {
    setSelectedClients((value) => value.filter((v) => v.client_Code !== clientcode));
  };

  return (
    <React.Fragment>
      <Button id="CAU_Edit" variant="contained" size="small" startIcon={<BorderColorIcon />} color="primary" onClick={handleClickOpen}
        className={classes.button} >
        Edit
      </Button>
      <Backdrop className={classes.backdrop} open={ProgressBar}>
        <PuffLoader size={80} color={"white"} speedMultiplier={1} />
      </Backdrop>
      <div>
        <Dialog TransitionComponent={Transition}
          fullScreen={fullScreen}
          open={open}
          classes={{ paper: classes.dialogePaper }}
          PaperProps={{ style: { borderRadius: 15 } }}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          fullWidth={true} >
          <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
            <Typography variant="h6" gutterBottom className={classes.titleheader}>
              EDIT USER
            </Typography>
          </DialogTitleHeader>
          <DialogContent >
            <Grid container>
              <Grid item xs={7} style={{ marginTop: '15px' }}>
                <div>
                  <form className={classes.root} noValidate autoComplete="off" >
                    <TextField id="CAU_E_FirstName" size="small" value={localState.firstName} onChange={(e) => { handleChange(e); HandleFirstNameVal(e); }} helperText={firstnameerror}
                      error={!!firstnameerror} inputProps={{ maxlength: 25 }} label="First Name" name="firstName" variant="outlined"
                      InputProps={{
                        classes: {
                          root: classes.outlinedInput,
                          notchedOutline: classes.noBorder
                        }
                      }}
                      className={classes.NameTextBoxStyle} />
                    <TextField id="CAU_E_LastName" size="small" value={localState.lastName} onChange={(e) => { handleChange(e); HandleLastNameVal(e); }} helperText={Lastnameerror} error={!!Lastnameerror}
                      inputProps={{ maxlength: 25 }} label="Last Name" name="lastName" variant="outlined"
                      InputProps={{
                        classes: {
                          root: classes.outlinedInput,
                          notchedOutline: classes.noBorder
                        }
                      }}
                      className={classes.NameTextBoxStyle} />
                  </form>
                </div>
                <form className={classes.root} noValidate autoComplete="off" >
                  <TextField id="CAU_E_Email" size="small" value={localState.userName} disabled={true} label="Email" name="userName" variant="outlined" className={classes.textFieldStyle}
                    InputProps={{
                      classes: {
                        root: classes.outlinedInput,
                        notchedOutline: classes.noBorder
                      }
                    }} />
                </form>
                <FormControl variant="outlined" size="small" className={classes.formControl} >
                  <InputLabel htmlFor="outlined-age-native-simple">Admin Level</InputLabel>
                  <Select native
                    id="CAU_E_AdminLevel"
                    onChange={handleChange}
                    value={localState.role}
                    label="Template"
                    inputProps={{
                      name: 'Role',
                      id: 'Admin Level',
                    }}
                  >
                    <option value={0}>Regular User</option>
                    <option value={1}>Client Admin</option>
                  </Select>
                </FormControl>
                <Box className={classes.LabelBoxStyle}>
                  <FormLabel style={{ fontWeight: 'bold', color: 'green' }}> Selected Client Code : {selectedClients?.length}</FormLabel>
                </Box>
                <div>
                  {!loading && selectedClients?.length > 0 ?
                    <Paper className={classes.boxBorder}>
                      <Scrollbars autoHide
                        autoHideTimeout={1000}
                        autoHideDuration={10}
                        autoHeight
                        className={`${classes.textBoxStyle2} ${classes.scrollMargin}`}
                        autoHeightMin="auto"
                        autoHeightMax={120}
                        style={{ width: '365px' }}>
                        <div className={`${classes.valueContainer}`}>
                          {selectedClients.map((v) => (
                            <Chip key={v.client_Code} size="small" color="secondary" label={v.client_Code} onDelete={onDelete(v.client_Code)} />
                          ))}
                        </div>
                      </Scrollbars>
                    </Paper>
                    :
                    null
                  }
                  <ClickAwayListener onClickAway={handleClickAway}>
                    <Box>
                      <Autocomplete style={{ marginTop: '10px' }}
                        multiple
                        loading={loading}
                        filterOptions={(options, state) => options}
                        className={classes.clientCodeBox}
                        autoComplete={false}
                        id="checkboxes-tags-demo"
                        options={confirmedClients}
                        classes={{ paper: classes.paper2 }}
                        disableClearable
                        value={selectedClients}
                        open={ClientCodeOpen}
                        renderTags={() => null}
                        onChange={(e, selected) => { handleClientSelect(e, selected); }}
                        onOpen={() => {
                          setClientCodeOpen(true);
                        }}
                        onClose={(e: any, reason: AutocompleteCloseReason) => {
                          if (reason === "toggleInput") {
                            setClientCodeOpen(false);
                          }
                        }}
                        getOptionDisabled={(option) => option.client_Code}
                        getOptionLabel={(option) => option.client_Code}
                        getOptionSelected={(option, value) => option.client_Code == value.client_Code}
                        renderOption={(option, { selected }) => (
                          <span className={`${selected ? classes.GreenColor : null}`} style={{ fontSize: 12, marginLeft: '8px' }}>
                            {option.client_Code}
                          </span>
                        )}
                        ListboxProps={
                          {
                            style: {
                              maxHeight: '200px'
                            }
                          }
                        }
                        renderInput={(params) => (
                          <TextField {...params} fullWidth
                            value={searchClientCode} variant="outlined"
                            type='text' placeholder="Search Clients"
                            onChange={(e) => { handleSearchClient(e) }}
                            onKeyDown={event => {
                              if (event.key === 'Enter') { handleSearchClient(event); handleClickOpen(); setClientCodeOpen(true); }
                            }}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {loading ? <CircularProgress color="primary" size={20} /> : null}
                                  {params.InputProps.endAdornment}
                                  <InputAdornment position="end">
                                    <IconButton onClick={(_) => { handleClickOpen(); setClientCodeOpen(true); }}>
                                      <SearchIcon className={classes.IconSizeStyle} />
                                    </IconButton>
                                  </InputAdornment>
                                  <Box display="flex" width="100%" className={classes.CheckBoxBorder}>
                                    <Box className={classes.checkBoxStyle} width="60%">
                                      <Checkbox size="small" disabled={loading || !confirmedClients.length}
                                        checked={checkAll}
                                        onChange={checkAllChange}
                                        id="check-all"
                                      />
                                      <span style={{ fontSize: 14 }}>Select All</span>
                                    </Box>
                                    <Box className={classes.checkBoxStyle} width="40%">
                                      <Checkbox size="small" disabled={loading || !confirmedClients.length}
                                        checked={deselectClient}
                                        onChange={(e) => { DeselectAllChange(e); }}
                                        id="check-all"
                                      />
                                      <span style={{ fontSize: 14 }}>Deselect All</span>
                                    </Box>
                                  </Box>
                                </React.Fragment>
                              ),
                            }}
                            className={classes.textBoxStyle}
                          />
                        )}
                      />
                    </Box>
                  </ClickAwayListener>
                </div>
              </Grid >
              <Grid item xs={5}>
                <Typography id="responsive-dialog-title" variant="h6" gutterBottom className={classes.titleheader1}>{"PERMISSIONS"}</Typography>
                <Box display="flex" alignItems="center" justifyContent="center">
                  <FormControl component="fieldset">
                    <FormGroup>
                      <FormControlLabel
                        id="CAU_E_paymentUpdates" className={classes.permission}
                        control={<Checkbox size="small" onChange={handleChecked} checked={localState.paymentUpdates} name="paymentUpdates" />}
                        labelPlacement="end"
                        label="Payment Updates"
                      />
                      <FormControlLabel
                        id="CAU_E_closingAccounts" className={classes.permission}
                        control={<Checkbox size="small" onChange={handleChecked} checked={localState.closingAccounts} name="closingAccounts" />}
                        labelPlacement="end"
                        label="Close Accounts"
                      />
                      <FormControlLabel
                        id="CAU_E_rollAccounts" className={classes.permission}
                        control={<Checkbox size="small" onChange={handleChecked} checked={localState.rollAccounts} name="rollAccounts" />}
                        labelPlacement="end"
                        label="Roll Accounts"
                      />
                      <FormControlLabel
                        id="CAU_E_placeAccounts" className={classes.permission}
                        control={<Checkbox size="small" onChange={handleChecked} checked={localState.placeAccounts} name="placeAccounts" />}
                        labelPlacement="end"
                        label="Place Accounts"
                      />
                      <FormControlLabel
                        id="CAU_E_editReports" className={classes.permission}
                        control={<Checkbox size="small" onChange={handleChecked} checked={localState.editReports} name="editReports" />}
                        labelPlacement="end"
                        label="Edit Reports"
                      />
                      <FormControlLabel
                        id="CAU_E_createNewUsers" className={classes.permission}
                        control={<Checkbox size="small" onChange={handleChecked} checked={localState.createNewUsers} name="createNewUsers" />}
                        labelPlacement="end"
                        label="Create New Users"
                      />
                      <FormControlLabel
                        id="CAU_E_UploadUnmappedFile" className={classes.permission}
                        control={<Checkbox size="small" onChange={handleChecked} checked={localState.uploadUnmappedFile} name="uploadUnmappedFile" />}
                        labelPlacement="end"
                        label="Upload Unmapped File"
                      />
                      <FormControlLabel className={classes.permission}
                        id="U_CU_trustStatement"
                        control={<Checkbox size="small" onChange={handleChecked} name="trustStatement" checked={localState?.trustStatement} />}
                        label="View Trust Statement"
                      />
                    </FormGroup>
                  </FormControl>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Button className={classes.close1} size="small" id="U_CU_Close_btn" onClick={handleClose} color="primary" variant="contained" startIcon={<CancelIcon />}>
                  Close
                </Button>
                <Button className={classes.edit} size="small" id="CAU_E_UpdateUser" startIcon={<DoneAllIcon />} autoFocus onClick={(_) => { handleSave(); }} disabled={isDisable || localState.firstName === "" || localState.lastName === ""} color="primary" variant="contained" >
                  Update User
                </Button>
              </Grid>
            </Grid >
          </DialogContent >
        </Dialog >
        <Snackbar open={submit} className="snackBarStyle" anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }} autoHideDuration={4000} onClose={closeSnackbar}>
          <Alert onClose={closeSnackbar} severity="success" className="alertStyle">
            {localState.firstName} Details has been Updated Successfully!
          </Alert>
        </Snackbar>
      </div >
    </React.Fragment >
  );
}

export default EditUser