import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

interface RadialProps {
    value: number;
  }
  
  function RadialProgress(props: RadialProps & { value: number },) {
    return (
      <div style={{ width: '100px', height: '100px', position: 'relative' }}>
        <CircularProgressbar
          value={props.value}
          text={`${props.value}%`}
          styles={buildStyles({
            textColor: "#fff",
            pathColor: "rgb(62 152 199)",
            trailColor: "rgb(214 214 214)"
          })}
        />
      </div> 
    )
  }

  export default RadialProgress;