import React, { useEffect, useState } from 'react';
import { TextField, Grid, Autocomplete, Typography, Box } from '@mui/material';
import { ICfpbFieldName } from '../../../../../models/accesscontrol/PlacementTemplateRule';
import { cfpbValidationColumns } from '../../../../../constants/Constant';
import { OPERAND_COUNT, OPERATORS } from './LoadMoackData';

const QueryBuilderEquation: React.FC<{ ruleName: string, onEquationChange: (equation) => void; ruleCondition: string }> = (props) => {
    const { ruleName, onEquationChange, ruleCondition } = props;
    const [operands, setOperands] = useState<ICfpbFieldName[]>(Array(OPERAND_COUNT).fill(null));

    useEffect(() => {
        if (ruleCondition) {
            const parsedCondition = JSON.parse(ruleCondition);
            const fields = Object.keys(parsedCondition)?.map((key) => {
                const fieldName = parsedCondition[key];
                // Match the fieldName with the available columns
                return cfpbValidationColumns.find((col) => col.fieldName === fieldName) || null;
            });
            setOperands(fields);
        } else {
            setOperands(Array(OPERAND_COUNT).fill(null));
        }
    }, [ruleCondition]);

    const getFilteredOptions = (index: number) => {
        const selected = operands.filter((operand, i) => operand && i !== index);
        return cfpbValidationColumns.filter(option => !selected.includes(option));
    };

    const handleOperandChange = (index: number, newValue: ICfpbFieldName | null) => {
        const updatedOperands = [...operands];
        updatedOperands[index] = newValue;
        setOperands(updatedOperands);

        const formattedOutput: Record<string, string> = updatedOperands.reduce((acc, operand, i) => {
            if (operand) {
                acc[`Field${i + 1}`] = operand.fieldName;
            }
            return acc;
        }, {}); 

        onEquationChange(JSON.stringify(formattedOutput));
    };

    return (
        <Box p={1}>
            <Typography variant="body1" mb={2} fontWeight={'bold'}>
                {ruleName}
            </Typography>
            <Grid container alignItems="center" spacing={1}>
                {Array.from({ length: OPERAND_COUNT }).map((_, index) => (
                    <React.Fragment key={index}>
                        {index > 0 && <Grid item><Typography variant="h6">{OPERATORS[index - 1]}</Typography></Grid>}
                        <Grid item xs={3.5}>
                            <Autocomplete
                                size="small"
                                options={getFilteredOptions(index)}
                                getOptionLabel={(option) => option.fieldName}
                                value={operands[index]}
                                onChange={(event, newValue) => handleOperandChange(index, newValue)}
                                renderInput={(params) => <TextField {...params} label={`Field ${index + 1}`} />}
                            />
                        </Grid>
                    </React.Fragment>
                ))}
            </Grid>
        </Box>
    );
};

export default QueryBuilderEquation;
