import SettingsIcon from '@mui/icons-material/Settings';
import {
    Backdrop,
    Button, Dialog, DialogActions, DialogContent, Grid, Paper,
    Table, TableBody, TableCell, TableHead, TableRow, Typography
} from '@mui/material';
import React, { useState } from 'react';
import { Transition } from '../../../../GlobalStyles/DialogBoxTransition';
import { DialogTitleHeader } from '../../../../GlobalStyles/DialogStyle';
import { useStyles } from '../../ManagementCSS';
import { TableContainer } from '@material-ui/core';
import { IGetRecordType } from '../../../../../models/accesscontrol/PlacementTemplateRule';
import { useFetch } from '../../../../../utils/apiHelper';
import { PuffLoader } from 'react-spinners';
import CreateRecordType from './CreateRecordType';
import EditRecordType from './EditRecordType';

const ManageRecordType = () => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [ProgressBar, setShowProgressBar] = useState(false);
    const [recordType, setRecordType] = useState<IGetRecordType[]>([]);

    const handleClose = () => {
        setOpen(false);
    }

    const handleOpen = () => {
        setOpen(true);
        getRecordTypeDetail();
    }

    const getRecordTypeDetail = () => {
        setShowProgressBar(true);
        (async () => {
            await useFetch<IGetRecordType[]>("GlobalSettings/GetRecordTypeDetail").then((result) => {
                setRecordType(result.data);
            }).finally(() => {
                setShowProgressBar(false);
            })
        })()
    }

    const handleCallback = () => {
        getRecordTypeDetail();
    }

    return (
        <React.Fragment>
            <Button id="CreateTemplate_btn" size="small" startIcon={<SettingsIcon />} variant="contained" color="primary" onClick={handleOpen} className={classes.btnManTemp} >
                MANAGE RECORD TYPE
            </Button>
            <Dialog open={open} PaperProps={{ style: { borderRadius: 15 } }} maxWidth={'md'} TransitionComponent={Transition}
                onClose={handleClose} aria-labelledby="responsive-dialog-title" >
                <Backdrop className={classes.backdrop} open={ProgressBar}>
                    <PuffLoader size={80} color={"white"} speedMultiplier={1} />
                </Backdrop>
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                    <Typography variant="h6" gutterBottom className={classes.titleheader} fontWeight={600}>
                        MANAGE RECORD TYPE
                    </Typography>
                </DialogTitleHeader>
                <DialogContent >
                    <Grid container spacing={0}>
                        <Grid item xs={12} display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
                            <CreateRecordType onCreate={handleCallback} />
                        </Grid>
                        <Grid item xs={12}>
                            <TableContainer component={Paper} sx={{ mt: 2 }}>
                                <Table size="small" aria-label="dense table" stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left" className={classes.TableCellStyle}>RECORD TYPE</TableCell>
                                            <TableCell align="left" className={classes.TableCellStyle}>RECORD DESCRIPTION</TableCell>
                                            <TableCell align="left" className={classes.TableCellStyle}>ACTIONS</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {recordType?.map((row) => (
                                            <TableRow key={row.recordTypeId}>
                                                <TableCell align="left" className={classes.TableCellStyle1}>{row.recordType}</TableCell>
                                                <TableCell align="left" className={classes.TableCellStyle1}>{row.description}</TableCell>
                                                <TableCell align="center" className={classes.TableCellStyle1}>
                                                    <EditRecordType onEdit={handleCallback} recordType={row} />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' size='small' color='error' sx={{ mr: 2 }} onClick={handleClose}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

export default ManageRecordType