import DateFnsUtils from '@date-io/date-fns';
import {
    Backdrop, Button, Dialog, DialogContent, Grid, IconButton, Snackbar, TextField, Typography,
    Box, Checkbox, Chip, CircularProgress, ClickAwayListener, Paper, InputAdornment, FormLabel, FormControlLabel, FormControl
} from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
// Icons
import CancelIcon from '@material-ui/icons/Cancel';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import SearchIcon from "@material-ui/icons/Search";
import GroupAddIcon from '@mui/icons-material/GroupAdd';
// Models
import { Alert } from '@material-ui/lab';
import { Autocomplete, AutocompleteCloseReason } from "@material-ui/lab";
import { DialogActions } from '@mui/material';
import { PuffLoader } from 'react-spinners';
import * as constant from '../../../constants/Constant';
import { IClientCodeContract, IGetKeyValues } from '../../../models/GLobalSettings/IGetKeyValueDetails';
import { useGlobalState } from '../../../store/GlobalStore';
import { useFetch, usePost } from '../../../utils/apiHelper';
import { Transition } from '../../GlobalStyles/DialogBoxTransition';
import { DialogTitleHeader } from '../../GlobalStyles/DialogStyle';
import { useStyles } from './ManagementCSS';
import { Scrollbars } from 'react-custom-scrollbars';
import { IAckClientInfo } from '../../../models/IAckReport/IAckClientInfo';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

const EditKeyDetails: React.FC<{ onSave: () => void, row: IGetKeyValues, clientList: IClientCodeContract[] }> = (props) => {
    const classes = useStyles();
    const { row, clientList } = props;
    const [open, setOpen] = useState<boolean>(false);
    const textInputRef = useRef(null);
    const [isCopy, setIsCopy] = useState<boolean>(false);
    const { state } = useGlobalState();
    const [ProgressBar, setShowProgressBar] = useState(false);
    const [Emailerror, setEmailError] = useState(""); // Display error message on email address
    const [StartDate, setStartDate] = useState(row?.startDate);
    const [ExpiryDate, setExpiryDate] = useState(row?.expiryDate);

    const [selectedClients, setSelectedClients] = useState<any>([]);
    const [searchClientCode, setSearchClientCode] = useState("");
    const [ClientCodeOpen, setClientCodeOpen] = useState(false);
    const [confirmedClients, setConfirmedClients] = useState([]);
    const [loading, setLoading] = useState(false);
    const [checkAll, setCheckAll] = useState(false);
    const [deselectClient, setDeselectClient] = useState(false);
    const [clientNameError, setClientNameError] = useState<string>("");
    const [isValidDate, setValidDate] = useState(true);
    const [isKeyDetailsUpdated, setIsKeyDetailsUpdated] = useState(false);
    const [IsEmailSent, setIsEmailSent] = useState(false);
    const [isClientCodeModified, setIsClientCodeModified] = useState(false);
    const [oldClientList, setOldClientList] = useState<IClientCodeContract[]>([]);
    const [disableClientList, setDisableClientList] = useState<IClientCodeContract[]>(props.clientList);

    let CurrentList: IGetKeyValues = {
        apiKeyId: row?.apiKeyId,
        clientKey: row?.clientKey,
        clientName: row?.clientName,
        emailAddress: row?.emailAddress,
        expiryDate: row?.expiryDate,
        isDisable: row?.isDisable,
        modifiedDate: row?.modifiedDate,
        startDate: row?.startDate
    }
    const [localState, setLocalState] = useState(CurrentList);

    const DateFormat = state.GlobalUtils?.settingValue === "DD/MM/YYYY" ? "dd/MM/yyyy" : state.GlobalUtils?.settingValue === "MM/DD/YYYY" ? "MM/dd/yyyy" : state.GlobalUtils?.settingValue === "YYYY/MM/DD" ? "yyyy/MM/dd" : "MM/dd/yyyy";

    const handleCopyClick = () => {
        textInputRef.current.select();
        document.execCommand('copy');
        setIsCopy(true);
    };

    const handleFirstDateChange = (date: Date | null) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date: Date | null) => {
        setExpiryDate(date);
    };

    useEffect(() => {
        if (localState.startDate > localState.expiryDate) {
            setValidDate(true);
        }
        else {
            setValidDate(false);
        }
    }, [localState.startDate, localState.expiryDate, StartDate, ExpiryDate])

    const handleClickOpen = () => {
        (async () => {
            setOpen(true);
            setConfirmedClients([]);
            setLoading(true);
            let request = {
                "userId": state?.userAccessContext?.id,
                "client_code": searchClientCode?.trim().toLocaleUpperCase(),
                "isAdmin": state?.userAccessContext.role
            }
            await useFetch<IClientCodeContract[]>("GlobalSettings/GetClientCodeList?ApiKeyId=" + row?.apiKeyId).then(async (result) => {
                setOldClientList(result?.data);
                setDisableClientList(clientList);
                await usePost<IAckClientInfo[]>("Client/searchAckClientCode", request).then((clients) => {
                    setConfirmedClients(clients.data);
                    setLoading(false);
                    {
                        !selectedClients?.length ?
                            setSelectedClients(clients.data.filter(p => {
                                return result.data?.filter(r => { return r.clientCode === p.client_code }).length > 0
                            }))
                            : null
                    }
                });
            });
            setOpen(true);
        })();
    };

    const handleClose = () => {
        setOpen(false);
        setIsCopy(false);
        props.onSave();
        setEmailError("");
        setCheckAll(false);
        setSelectedClients([]);
        setSearchClientCode("");
        setConfirmedClients([]);
        setClientCodeOpen(false);
        setStartDate(row?.startDate);
        setIsClientCodeModified(false);
        setExpiryDate(row?.expiryDate);
    };

    const UpdateMessage = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setIsKeyDetailsUpdated(false);
        setIsCopy(false);
    };

    const handleSearchClient = (e) => {
        setSearchClientCode(e.target.value);
    }

    const handleChange = (event) => {
        const name = event.target.name;
        setLocalState({
            ...localState,
            [name]: event.target.value
        });
    };

    const handleUpdateKeyDetails = () => {
        setShowProgressBar(true);
        let newClientCodeList = [];
        selectedClients?.map(result => {
            let newList: IClientCodeContract = {
                clientCode: result.client_code
            }
            newClientCodeList.push(newList);
        });

        (async () => {
            let request = {
                "apiKeyId": localState?.apiKeyId,
                "clientName": localState?.clientName,
                "ClientCodeDetails": newClientCodeList,
                "emailSentTo": localState?.emailAddress,
                "userId": state?.userAccessContext?.id,
                "startDate": StartDate,
                "expiryDate": ExpiryDate,
                "isEmailSent": IsEmailSent,
                "apiKeyValue": localState?.clientKey,
                "clientCodeList": selectedClients
            };
            await usePost("GlobalSettings/UpdatePlacementKeyInformation", request).then((result) => {
                setIsKeyDetailsUpdated(true);
            });
        })().finally(() => {
            setShowProgressBar(false);
            if (localState?.clientName != CurrentList?.clientName || localState?.emailAddress != CurrentList?.emailAddress) {
                handleInsertHistory(newClientCodeList, 4);
            }
            if (isClientCodeModified) {
                handleInsertHistory(newClientCodeList, 5);
            }
            if (StartDate != CurrentList?.startDate || ExpiryDate != CurrentList?.expiryDate) {
                handleInsertHistory(newClientCodeList, 6);
            }
        })
    }

    const handleInsertHistory = (newClientCodeList: any[], actionType: number) => {
        // Convert array of objects to an array of client codes
        const clientCodes = oldClientList.map(item => item.clientCode);
        const newClientCodes = newClientCodeList.map(item => item.clientCode);

        // Convert the array of client codes to a comma-separated string
        const OldClientCodeList = clientCodes.join(', ');
        const selectedClientCodeList = newClientCodes.join(',');

        (async () => {
            let request = {
                "apiKeyId": localState?.apiKeyId,
                "clientName": localState?.clientName,
                "emailAddress": localState?.emailAddress,
                "startDate": StartDate,
                "expiryDate": ExpiryDate,
                "userId": state?.userAccessContext?.id,
                "oldClientCodeList": actionType === 5 ? OldClientCodeList : null,
                "newClientCodeList": actionType === 5 ? selectedClientCodeList : null,
                "actionType": actionType
            };
            await usePost("GlobalSettings/InsertAPIKeyHistory", request).then((result) => {
                setIsKeyDetailsUpdated(true);
            });
        })().finally(() => {
            setShowProgressBar(false);
        })
    }

    const validateEmail = (e) => {
        const email = e.target.value;
        if (constant.emailRegex.test(email)) {
            setEmailError("");
        } else {
            setEmailError("Please enter valid Email address");
        }
    };

    const handleClickAway = (e) => {
        (async () => {
            setClientCodeOpen(false);
            let request = {
                "userId": state?.userAccessContext?.id,
                "client_code": "",
                "isAdmin": true
            }
            await usePost<IAckClientInfo[]>("Client/searchAckClientCode", request).then((r) => {
                setConfirmedClients(r.data);
            }).finally(() => {
                setShowProgressBar(false);
            });
        })()
    };

    const onDelete = (clientcode) => () => {
        setSelectedClients((value) => value.filter((v) => v.client_code !== clientcode));
        setDisableClientList((value) => value.filter((v) => v.clientCode !== clientcode));
    };

    const handleClientSelect = (event, selected) => {
        setSelectedClients(selected);
        setIsClientCodeModified(true);
        if (selected?.length <= 0) {
            setCheckAll(false);
        }
    }

    const handleSearch = () => {
        (async () => {
            setConfirmedClients([]);
            setLoading(true);
            setShowProgressBar(true);
            setOpen(true);
            let request = {
                "userId": state?.userAccessContext?.id,
                "client_code": searchClientCode?.trim().toLocaleUpperCase(),
                "isAdmin": true
            }
            await usePost<IAckClientInfo[]>("Client/searchAckClientCode", request).then((r) => {
                setConfirmedClients(r.data);
                setLoading(false);
            }).finally(() => {
                setShowProgressBar(false);
                setCheckAll(false);
            });
        })()
    };

    const checkAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsClientCodeModified(true);
        setCheckAll(event.target.checked);
        if (event.target.checked) {

            // Filter out items in disableClientList from confirmedClients
            const newClientList = confirmedClients.filter((c) => (
                !disableClientList.find((y) => y.clientCode === c.client_code)
            ));

            // Filter out duplicates when adding new clients
            const filteredNewClientList = newClientList.filter((c) => (
                !selectedClients.find((r) => c.client_code === r.client_code)
            ));

            setSelectedClients([...new Set(selectedClients?.concat(filteredNewClientList?.map((r) => r)))]);
            setClientCodeOpen(false);
        }
        setCheckAll(false);
        handleClear();
        setSearchClientCode("");
    };

    const DeselectAllChange = (event) => {
        setDeselectClient(event.target.checked);
        if (event.target.checked) {
            var NewClientList = selectedClients.filter((c) => {
                return !confirmedClients.find((r) => {
                    return c.client_code == r.client_code
                })
            });
            setSelectedClients(NewClientList);
            setClientCodeOpen(false);
        }
        // Add a logic to filter the client codes from the Disable Client List
        setDisableClientList((value) => value.filter((v) => {
            return !selectedClients.find((r) => {
                return v.clientCode == r.client_code
            })
        }));

        //End
        setDeselectClient(false);
        handleClear();
        setSearchClientCode("");
    };

    const handleClear = () => {
        (async () => {
            setOpen(true);
            let request = {
                "userId": state?.userAccessContext?.id,
                "client_code": "",
                "isAdmin": state?.userAccessContext?.role
            }
            await usePost<IAckClientInfo[]>("Client/searchAckClientCode", request).then((r) => {
                setConfirmedClients(r.data);
            }).finally(() => {
                setShowProgressBar(false);
            });
        })()
    };

    const validateClientName = (e) => {
        const clientName = e.target.value;
        if (constant.NameRegex.test(clientName)) {
            setClientNameError("");
        } else {
            setClientNameError("Please enter valid Client Name");
        }
    };

    const handleChecked = (event) => {
        setIsEmailSent(event.target.checked);
    };

    return (
        <React.Fragment>
            <Button id="CreateTemplate_btn" disabled={!row?.isDisable} size="small" startIcon={<BorderColorIcon />} variant="contained" color="primary" onClick={handleClickOpen} className={classes.btnEditTemp}>
                EDIT
            </Button>

            <Dialog open={open} PaperProps={{ style: { borderRadius: 15 } }} maxWidth={'sm'} TransitionComponent={Transition}
                onClose={handleClose} aria-labelledby="responsive-dialog-title" >
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                    <Typography variant="h6" gutterBottom className={classes.titleheader}>
                        ISSUE A.R.M.DEV API KEY
                    </Typography>
                </DialogTitleHeader>
                <DialogContent >
                    <Backdrop className={classes.backdrop} open={ProgressBar}>
                        <PuffLoader size={80} color={"white"} speedMultiplier={1} />
                    </Backdrop>
                    <Grid container spacing={1}>
                        <Grid item xs={6} sm={6}>
                            <Typography variant='subtitle1' className={classes.headerName}>
                                <b>Client Name</b>
                            </Typography>

                            <TextField id="clientName" name="clientName" label="Client Name"
                                autoComplete="off"
                                size="small"
                                value={localState?.clientName}
                                className={classes.keyTextFiled}
                                onChange={(e) => { handleChange(e); validateClientName(e); }}
                                fullWidth
                                variant="outlined"
                                required helperText={clientNameError} error={!!clientNameError}
                                inputProps={{ maxlength: 100 }}
                            />
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <Typography variant='subtitle1' className={classes.headerName}>
                                <b>API Key to be sent to</b>
                            </Typography>

                            <TextField id="Email" name="emailAddress" label="Email Id" autoComplete="off" size="small"
                                value={localState?.emailAddress}
                                className={classes.keyTextFiled}
                                onChange={(e) => { handleChange(e); validateEmail(e); }}
                                fullWidth variant="outlined"
                                required helperText={Emailerror} error={!!Emailerror}
                                inputProps={{ maxlength: 100 }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} style={{ marginTop: '10px' }}>
                            <FormLabel className={classes.labelStyle}> Selected Client Code : {selectedClients.length}</FormLabel>
                            <Paper className={classes.boxBorder}>
                                <Scrollbars autoHide
                                    autoHideTimeout={1000}
                                    autoHideDuration={10}
                                    autoHeight
                                    className={`${classes.textBoxStyle2} ${classes.scrollMargin}`}
                                    autoHeightMin={90}
                                    autoHeightMax={90}>
                                    {!selectedClients.length ?
                                        <div className={classes.clientStyle}>
                                            <GroupAddIcon style={{ color: '#364F6B', fontSize: 40, textAlign: 'center' }} />
                                            <Typography variant="h6" style={{ fontSize: 15, textAlign: 'center' }}>
                                                <b>Kindly Select Client Codes</b>
                                            </Typography>
                                        </div>
                                        :
                                        <div className={`${classes.valueContainer}`} >
                                            {selectedClients.map((v) => (
                                                <Chip key={v.client_code} color="secondary" size="small" label={v.client_code} onDelete={onDelete(v.client_code)} />
                                            ))}
                                        </div>
                                    }
                                </Scrollbars>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Box>
                                <div >
                                    <ClickAwayListener onClickAway={handleClickAway}>
                                        <Box>
                                            <Autocomplete
                                                multiple size="small"
                                                loading={loading}
                                                filterOptions={(options, state) => options}
                                                className={classes.clientCodeBox}
                                                autoComplete={false}
                                                id="checkboxes-tags-demo"
                                                options={confirmedClients}
                                                classes={{ paper: classes.paper2 }}
                                                disableClearable
                                                value={selectedClients}
                                                open={ClientCodeOpen}
                                                renderTags={() => null}
                                                onChange={(e, selected) => { handleClientSelect(e, selected); }}
                                                onOpen={() => { setClientCodeOpen(true); }}
                                                onClose={(e: any, reason: AutocompleteCloseReason) => {
                                                    if (reason === "toggleInput") {
                                                        setClientCodeOpen(false);
                                                    }
                                                }}
                                                getOptionDisabled={(option) => option.client_code}
                                                getOptionLabel={(option) => option.client_code}
                                                getOptionSelected={(option, value) => option.client_code == value.client_code}
                                                renderOption={(option, { selected }) => {
                                                    const isDisabled = props?.clientList.some((selectedClient) => selectedClient.clientCode === option.client_code);
                                                    return (
                                                        <span className={`${selected ? classes.GreenColor : ''} ${isDisabled ? classes.DisabledOption : ''}`} style={{ fontSize: 13, marginLeft: '5px' }}>
                                                            {option?.client_code + " -- " + option?.client_name}
                                                        </span>
                                                    );
                                                }}
                                                ListboxProps={{ style: { maxHeight: '160px' } }}
                                                renderInput={(params) => (
                                                    <TextField {...params} fullWidth size="small"
                                                        value={searchClientCode} variant="outlined"
                                                        type='text' placeholder="Search Clients"
                                                        onChange={(e) => { handleSearchClient(e) }}
                                                        onKeyDown={event => {
                                                            if (event.key === 'Enter') { handleSearchClient(event); handleSearch(); setClientCodeOpen(true); }
                                                        }}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <React.Fragment>
                                                                    {loading ? <CircularProgress color="primary" size={20} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                    <InputAdornment position="end">
                                                                        <IconButton onClick={(_) => { handleSearch(); setClientCodeOpen(true); }}>
                                                                            <SearchIcon className={classes.IconSizeStyle} />
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                    <Box display="flex" width="100%" className={classes.CheckBoxBorder}>
                                                                        <Box className={classes.checkBoxStyle} width="60%">
                                                                            <Checkbox size="small" disabled={loading || !confirmedClients.length}
                                                                                checked={checkAll}
                                                                                onChange={checkAllChange}
                                                                                id="check-all"
                                                                            />
                                                                            <span style={{ fontSize: 12 }}>Select All</span>
                                                                        </Box>
                                                                        <Box className={classes.checkBoxStyle} width="40%">
                                                                            <Checkbox size="small" disabled={loading || !confirmedClients.length}
                                                                                checked={deselectClient}
                                                                                onChange={(e) => { DeselectAllChange(e); }}
                                                                                id="check-all"
                                                                            />
                                                                            <span style={{ fontSize: 12 }}>Deselect All</span>
                                                                        </Box>
                                                                    </Box>
                                                                </React.Fragment>
                                                            ),
                                                        }}
                                                        className={classes.textBoxStyle1}
                                                    />
                                                )}
                                            />
                                        </Box>
                                    </ClickAwayListener>
                                </div>
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Typography variant='subtitle1' className={classes.headerName}>
                                <b>API Key Usage Start Date</b>
                            </Typography>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <FormControl >
                                    <KeyboardDatePicker inputVariant="outlined" variant="inline" size="small"
                                        format={DateFormat} name="startDate"
                                        className={classes.keyTextFiled}
                                        disableToolbar autoOk={true}
                                        margin="normal" id="DocUpLog_StartDate"
                                        value={StartDate}
                                        onChange={handleFirstDateChange}
                                        KeyboardButtonProps={{ 'aria-label': 'change date' }}
                                    />
                                </FormControl>
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant='subtitle1' className={classes.headerName}>
                                <b>API Key Expiry Date</b>
                            </Typography>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <FormControl >
                                    <KeyboardDatePicker size="small" name="expiryDate"
                                        disableToolbar autoOk={true}
                                        inputVariant="outlined" variant="inline"
                                        format={DateFormat}
                                        className={classes.keyTextFiled}
                                        margin="normal" id="DocUpLog_EndDate"
                                        value={ExpiryDate}
                                        onChange={handleEndDateChange}
                                        KeyboardButtonProps={{ 'aria-label': 'change date' }}
                                    />
                                </FormControl>
                            </MuiPickersUtilsProvider>
                            {StartDate > ExpiryDate ?
                                <Typography variant='h6' className={classes.clientCodeError}>Expiry date should be grater than start date</Typography>
                                :
                                null
                            }
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <Typography variant='subtitle1' className={classes.headerName}>
                                <b>API Key </b>
                            </Typography>
                            <TextField
                                inputRef={textInputRef}
                                variant="outlined"
                                value={localState?.clientKey}
                                fullWidth
                                className={classes.copyTextField}
                                size='small'
                                InputProps={{
                                    classes: {
                                        root: classes.outlinedInput,
                                        notchedOutline: classes.notchedOutline,
                                    },
                                    endAdornment: (
                                        localState?.clientKey?.length ?
                                            <IconButton onClick={handleCopyClick} style={{ padding: '3px' }}>
                                                {isCopy ? <DoneAllIcon style={{ color: 'green' }} /> : <FileCopyIcon color='primary' />}
                                            </IconButton>
                                            : null
                                    )
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions style={{ padding: '10px 25px' }}>
                    <Box m={0} width="50%">
                        <FormControlLabel
                            id="sendEmail"
                            control={<Checkbox size="small" onClick={handleChecked} name="sendEmail" />}
                            label="Send Email"
                        />
                    </Box>
                    <Box m={0} width="50%" style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                        <Button variant='contained' size="small" color='primary' onClick={handleUpdateKeyDetails} className={classes.createGroup} startIcon={<VpnKeyIcon />}
                            disabled={!selectedClients?.length || !localState?.emailAddress?.length || isValidDate || !!Emailerror.length || !localState?.clientKey?.length}>
                            Update
                        </Button>
                        <Button variant='contained' size="small" onClick={handleClose} className={classes.cancelGroup} startIcon={<CancelIcon />}>
                            Close
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>

            <Snackbar className="snackBarStyle" open={isKeyDetailsUpdated} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={UpdateMessage}>
                <Alert onClose={UpdateMessage} severity="success" className="alertStyle">
                    Key Details are updated Successfully.
                </Alert>
            </Snackbar>

            <Snackbar className="snackBarStyle" open={isCopy} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={UpdateMessage}>
                <Alert onClose={UpdateMessage} severity="success" className="alertStyle">
                    Copied
                </Alert>
            </Snackbar>
        </React.Fragment >
    );
}

export default EditKeyDetails