import {
  Button, Dialog, DialogActions, DialogContent, IconButton, Snackbar, Typography, makeStyles, useMediaQuery, useTheme
} from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import React, { useRef, useState } from 'react';
import { IRequestedReportDetails } from '../../../models/Reports/IReportStatusList';
import { usePostDownloadProgress } from '../../../utils/apiHelper';
import { DialogTitleHeader } from '../../GlobalStyles/DialogStyle';
import { Backdrop, CircularProgress } from '@mui/material';
import { useParams } from 'react-router';
import RadialProgress from '../../../pages/FileProgressBar';

import { PDFDocument, rgb } from 'pdf-lib';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import JSZip from 'jszip';


const useStyles = makeStyles((theme) => ({
  fileDownloadLink: {
    fontSize: 13,
    color: "blue",
  },
  yesbutton: {
    backgroundColor: 'green',
    fontSize: 12,
    borderRadius: '20px',
    color: 'white',
    '&:hover': {
      backgroundColor: 'green',
      color: 'white',
    }
  },
  nobutton: {
    background: "red",
    fontSize: 12,
    borderRadius: "20px",
    color: 'white',
    '&:hover': {
      backgroundColor: 'red',
      color: 'white',
    }
  },
  titleheader: {
    fontWeight: 'bold',
    fontSize: 18,
    marginTop: '5px',
    color: 'white'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  downloadIconStyle: {
    color: "#a1a1a1",
    fontSize: 26,
    marginLeft: '15px'
  },
  downloadIconStyle1: {
    color: "green",
    fontSize: 26,
    marginLeft: '15px'
  },
  fabProgressPrint: {
    color: 'green'[500],
    position: 'absolute',
    top: '-4px',
    left: 9.5,
    zIndex: 1
  }
}))

const DownloadReportRequested: React.FC<{ row: IRequestedReportDetails }> = (props) => {
  const classes = useStyles();
  const { row } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = useState(false);
  const [fileExists, setFileExists] = useState(false);
  const [loading, setLoading] = useState(false);
  let params = useParams();
  const [progress, setProgress] = useState(0);
  const [uploadLoading, setUploadLoading] = useState(false);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setFileExists(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose1 = () => {
    setOpen(false);
  };

  function handleDownloadFile() {
    setOpen(false);
    if (row?.fileType === true) {
      DownloadPdfFileAsync();
    }
    else {
      downloadZipWithCombinedPDF();
    }
  };

  const removeAfterUnderscore = (filename: string): string => {
    const lastUnderscoreIndex = filename?.lastIndexOf('_');
    const extensionIndex = filename?.lastIndexOf('.');
    if (lastUnderscoreIndex !== -1 && lastUnderscoreIndex < extensionIndex) {
      return filename.substring(0, lastUnderscoreIndex) + filename?.substring(extensionIndex);
    }
    return filename;
  };

  const DownloadPdfFileAsync = async () => {
    setLoading(true);
    setUploadLoading(true);
    setProgress(0);
    try {
      intervalRef.current = setInterval(() => {
        setProgress(prev => (prev < 100 ? prev + 1 : 100));
      }, 2000);
      const progressUpdate = (progressEvent: ProgressEvent) => {
        if (progressEvent.total && progressEvent.total > 0) {
          const percentComplete = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setProgress(percentComplete);
          if (percentComplete === 100) {
            clearInterval(intervalRef.current);
          }
        }
      };
      try {
        let newFileName = removeAfterUnderscore(row?.fileName);
        let request = {
          "blobUri": row?.blobUri,
          "fileName": newFileName?.split(".")[0]
        };

        const response = await usePostDownloadProgress<any>("File/SplitLargeFileAndCreateZipAsync", request, progressUpdate)
        const url = window.URL.createObjectURL(new Blob([response?.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${newFileName}.zip`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error('Error downloading the file', error);
      }
    } catch (error) {
      console.error('Download Error:', error);
      setFileExists(true);
    }
    finally {
      setLoading(false);
      setUploadLoading(false);
      setProgress(100);
    }
  };

  const specifiedHeaders = [
    "AgencyID", "ClntAcct1", "Client_Name_1", "Client_Name_2", "Name",
    "Address", "PhoneNumber", "PrincipalDue", "Fee", "PrincipalReceived",
    "TotalDue", "Status", "StatusDate", "ReferralDate"
  ];
  const PAGE_HEIGHT = 595;
  const PAGE_WIDTH = 842;  // A4 landscape width in points
  const MARGIN = 10;
  const ROWS_PER_PAGE = 50;

  // Utility to clean text for unsupported WinAnsi characters
  const cleanText = (text) => text.replace(/[^\x20-\x7E]/g, ''); // Keep only ASCII characters

  const downloadZipWithCombinedPDF = async () => {
    setLoading(true);
    setUploadLoading(true);
    setProgress(0);

    // Start an interval to increment progress during both download and generation stages
    intervalRef.current = setInterval(() => {
      setProgress((prev) => Math.min(prev + 1, 95)); // Increment up to 95% smoothly
    }, 200);

    try {
      // Function to handle progress during file download

      const newFileName = removeAfterUnderscore(row?.fileName);
      const request = { blobUri: row?.blobUri, fileName: newFileName?.split(".")[0] };
      const response = await usePostDownloadProgress("File/SplitLargeFileAndCreateZipAsync", request);

      // Set progress at 85% after download, moving to PDF generation
      setProgress(95);

      // Process CSV files and generate PDF
      const csvFiles = await extractCsvFilesFromResponse(response?.data);
      const pdfDoc = await PDFDocument.create();
      let page = null;
      let yPosition;
      let currentRowCount = 0;
      let hasDataOnPage = false;

      for (const csvFile of csvFiles) {
        const fileStream = await csvFile.text();

        await new Promise((resolve) => {
          Papa.parse(fileStream, {
            header: true,
            skipEmptyLines: true,
            chunk: (results) => {
              const filteredData = results.data.filter((row) =>
                specifiedHeaders.some((header) => row[header]?.trim() !== "")
              );

              if (filteredData.length > 0) {
                filteredData.forEach((row) => {
                  // Create a new page if there is no page or ROWS_PER_PAGE limit reached
                  if (!page || currentRowCount >= ROWS_PER_PAGE) {
                    if (page && hasDataOnPage) {
                      pdfDoc.addPage(page);
                    }

                    // Start a new page and draw headers
                    page = pdfDoc.addPage([PAGE_WIDTH, PAGE_HEIGHT]);
                    yPosition = PAGE_HEIGHT - 40;
                    hasDataOnPage = false;

                    const headerText = specifiedHeaders.join(" | ");
                    page.drawText(headerText, { x: MARGIN, y: yPosition, size: 10, color: rgb(0, 0, 0) });
                    yPosition -= 20;
                    currentRowCount = 0;
                  }

                  const dataRow = specifiedHeaders.map(header => cleanText(formatValue(row[header] || "", header)));
                  const hasData = dataRow.some(cell => cell.trim() !== "");

                  if (hasData) {
                    const rowContent = dataRow.join(" | ");
                    page.drawText(rowContent, { x: MARGIN, y: yPosition, size: 7, color: rgb(0, 0, 0) });
                    yPosition -= 15;
                    currentRowCount++;
                    hasDataOnPage = true;
                  }
                });
              }
            },
            complete: resolve,
            error: (error) => console.error("Error parsing CSV file:", error),
          });
        });

        // Increment progress periodically during PDF creation phase up to 95%
        setProgress((prev) => Math.min(prev + 1, 95));
      }

      if (page && hasDataOnPage) {
        pdfDoc.addPage(page);
      }

      const pdfBytes = await pdfDoc.save();
      saveAs(new Blob([pdfBytes]), `${newFileName}.pdf`);

      // Set progress to 100% after the PDF is created and saved
      setProgress(100);
    } catch (error) {
      console.error("Error downloading the file", error);
    } finally {
      // Clear interval and reset loading state after completion
      clearInterval(intervalRef.current);
      setLoading(false);
      setUploadLoading(false);
      setTimeout(() => setProgress(0), 1000); // Reset progress after a short delay
    }
  };

  // Helper functions
  const formatValue = (value, header) => {
    if (value === null || value?.trim() === "") return value;
    if (header === "StatusDate" || header === "ReferralDate") {
      const date = new Date(value);
      return !isNaN(date.getTime()) ? date.toISOString().split("T")[0] : value;
    }
    const currencyHeaders = ["PrincipalDue", "Fee", "TotalDue"];
    if (currencyHeaders.includes(header)) {
      const parsedValue = parseFloat(value);
      return !isNaN(parsedValue) ? parsedValue.toFixed(2) : value;
    }
    return value;
  };

  const extractCsvFilesFromResponse = async (blob) => {
    const zip = await JSZip.loadAsync(blob);
    const csvFiles = [];
    await Promise.all(
      Object.keys(zip.files).map(async (fileName) => {
        const file = zip.files[fileName];
        if (fileName.endsWith(".csv")) {
          const content = await file.async("blob");
          csvFiles.push(new File([content], fileName, { type: "text/csv" }));
        }
      })
    );
    return csvFiles;
  };

  // const specifiedHeaders = [
  //   "AgencyID", "ClntAcct1", "Client_Name_1", "Client_Name_2", "Name",
  //   "Address", "PhoneNumber", "PrincipalDue", "Fee", "PrincipalReceived",
  //   "TotalDue", "Status", "StatusDate", "ReferralDate"
  // ];

  // const PAGE_HEIGHT = 595; // Landscape mode
  // const PAGE_WIDTH = 842; // Landscape mode
  // const HEADER_FONT_SIZE = 8;
  // const BODY_FONT_SIZE = 7;
  // const ROW_HEIGHT = BODY_FONT_SIZE + 2; // Set a static row height with padding
  // const ROWS_PER_PAGE = 35;
  // const MAX_CHARS_PER_CELL = 15; // Adjust based on full page width

  // const downloadZipWithCombinedPDF = async () => {
  //   setLoading(true);
  //   setUploadLoading(true);
  //   setProgress(0);

  //   try {
  //     intervalRef.current = setInterval(() => {
  //       setProgress(prev => (prev < 100 ? prev + 1 : 100));
  //     }, 2000);

  //     const progressUpdate = (progressEvent) => {
  //       if (progressEvent.total && progressEvent.total > 0) {
  //         const percentComplete = Math.round((progressEvent.loaded * 100) / progressEvent.total);
  //         setProgress(percentComplete);
  //         if (percentComplete === 100) clearInterval(intervalRef.current);
  //       }
  //     };

  //     const newFileName = removeAfterUnderscore(row?.fileName);
  //     const request = { blobUri: row?.blobUri, fileName: newFileName?.split(".")[0] };
  //     const response = await usePostDownloadProgress("File/SplitLargeFileAndCreateZipAsync", request, progressUpdate);

  //     const csvFiles = await extractCsvFilesFromResponse(response?.data);
  //     const pdfDoc = await PDFDocument.create();

  //     for (const csvFile of csvFiles) {
  //       const fileStream = await csvFile.text();
  //       const parsedCsv = Papa.parse(fileStream, {
  //         header: true,
  //         skipEmptyLines: true,
  //       });

  //       let currentRowCount = 0;
  //       let yPosition = PAGE_HEIGHT - 30;
  //       let page = pdfDoc.addPage([PAGE_WIDTH, PAGE_HEIGHT]);

  //       // Draw table headers on the first page of each CSV
  //       specifiedHeaders.forEach((header, index) => {
  //         page.drawText(header, {
  //           x: index * (PAGE_WIDTH / specifiedHeaders.length),
  //           y: yPosition,
  //           size: HEADER_FONT_SIZE,
  //           color: rgb(0, 0, 0),
  //         });
  //       });
  //       yPosition -= HEADER_FONT_SIZE; // Move down for header height

  //       parsedCsv.data.forEach((row) => {
  //         // Check if we need to add a new page
  //         if (currentRowCount >= ROWS_PER_PAGE) {
  //           page = pdfDoc.addPage([PAGE_WIDTH, PAGE_HEIGHT]);
  //           yPosition = PAGE_HEIGHT - 30;

  //           // Redraw headers on the new page
  //           specifiedHeaders.forEach((header, index) => {
  //             page.drawText(header, {
  //               x: index * (PAGE_WIDTH / specifiedHeaders.length),
  //               y: yPosition,
  //               size: HEADER_FONT_SIZE,
  //               color: rgb(0, 0, 0),
  //             });
  //           });
  //           yPosition -= HEADER_FONT_SIZE;
  //           currentRowCount = 0;
  //         }

  //         // Draw each cell in the current row without wrapping or dynamic height
  //         specifiedHeaders.forEach((header, index) => {
  //           const cellText = truncateText(formatValue(row[header] || "", header), BODY_FONT_SIZE);
  //           const cellX = index * (PAGE_WIDTH / specifiedHeaders.length);

  //           page.drawText(cellText, {
  //             x: cellX,
  //             y: yPosition,
  //             size: BODY_FONT_SIZE,
  //             color: rgb(0, 0, 0),
  //           });
  //         });

  //         yPosition -= ROW_HEIGHT;
  //         currentRowCount++;
  //       });
  //     }

  //     const newFileName1 = newFileName.endsWith(".csv") ? newFileName.slice(0, -4) : newFileName;
  //     const pdfBytes = await pdfDoc.save();
  //     saveAs(new Blob([pdfBytes], { type: "application/pdf" }), `${newFileName1}.pdf`);
  //     clearInterval(intervalRef.current);

  //   } catch (error) {
  //     console.error("Error downloading the file", error);
  //   } finally {
  //     setLoading(false);
  //     setUploadLoading(false);
  //     setProgress(0);
  //   }
  // };

  // // Truncate text based on font size and max characters per cell
  // const truncateText = (text, fontSize) => {
  //   const maxChars = Math.floor(MAX_CHARS_PER_CELL * (BODY_FONT_SIZE / fontSize));
  //   return text.length <= maxChars ? text : text.slice(0, maxChars) + "...";
  // };

  // // Adjusted formatValue function for formatting currency and dates
  // const formatValue = (value, header) => {
  //   if (value === null || value.trim() === "") return value;
  //   if (header === "StatusDate" || header === "ReferralDate") {
  //     const date = new Date(value);
  //     return !isNaN(date.getTime()) ? date.toISOString().split("T")[0] : value;
  //   }
  //   const currencyHeaders = ["PrincipalDue", "Fee", "TotalDue"];
  //   if (currencyHeaders.includes(header)) {
  //     const parsedValue = parseFloat(value);
  //     return !isNaN(parsedValue) ? parsedValue.toFixed(2) : value;
  //   }
  //   return value;
  // };

  // const extractCsvFilesFromResponse = async (blob) => {
  //   const zip = await JSZip.loadAsync(blob);
  //   const csvFiles = [];
  //   await Promise.all(
  //     Object.keys(zip.files).map(async (fileName) => {
  //       const file = zip.files[fileName];
  //       if (fileName.endsWith(".csv")) {
  //         const content = await file.async("blob");
  //         csvFiles.push(new File([content], fileName, { type: "text/csv" }));
  //       }
  //     })
  //   );
  //   return csvFiles;
  // };

  return (
    <React.Fragment>
      <Backdrop className={classes.backdrop} open={uploadLoading}>
        <RadialProgress value={progress} />
      </Backdrop>

      <IconButton size='small' style={{ padding: 0 }} onClick={handleClickOpen} disabled={row?.isRequested === 1 || row?.isRequested === 2} className={row?.isRequested !== 3 ? classes.downloadIconStyle : classes.downloadIconStyle1}>
        <CloudDownloadIcon fontSize='medium' className={row?.isRequested !== 3 ? classes.downloadIconStyle : classes.downloadIconStyle1} />
        {loading && <CircularProgress size={35} className={classes.fabProgressPrint} />}
      </IconButton>
      <Dialog maxWidth={'sm'} PaperProps={{ style: { borderRadius: 15 } }}
        fullScreen={fullScreen} open={open}
        onClose={(_) => { handleClose1(); }}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose1}>
          <Typography variant="h6" gutterBottom className={classes.titleheader}>
            DOWNLOAD FILE
          </Typography>
        </DialogTitleHeader>
        <DialogContent>
          <Typography variant="h6" style={{ color: "black", fontSize: 18 }} gutterBottom>
            Are you sure, you want to Download this file ?
          </Typography>
        </DialogContent>
        <DialogActions >
          <Button variant="contained" size="small" onClick={(_) => { handleDownloadFile(); }} className={classes.yesbutton} color="primary" autoFocus>
            Yes
          </Button>
          <Button variant="contained" size="small" autoFocus className={classes.nobutton} onClick={(_) => { handleClose1(); }} color="primary">
            No
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={fileExists} className="snackBarStyle" anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }} autoHideDuration={4000} onClose={closeSnackbar}>
        <Alert onClose={() => { setFileExists(false) }} severity="error" className="alertStyle">
          File does not exists!
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}

export default DownloadReportRequested