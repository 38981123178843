import DateFnsUtils from '@date-io/date-fns';
import {
    Backdrop, FormControl, IconButton, InputAdornment, InputLabel, Paper, Select, Table,
    TableBody, TableCell, TableHead, TablePagination, TableRow, TextField
} from '@material-ui/core';
import FolderIcon from '@material-ui/icons/Folder';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import SearchIcon from "@material-ui/icons/Search";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import ArchiveFile from '@mui/icons-material/Archive';
import CloseIcon from '@mui/icons-material/Close';
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Button, Checkbox, Collapse, Grid, Link, List, ListItem, ListItemIcon, ListItemText, TableContainer, Typography } from '@mui/material';
import moment from 'moment';
import React, { /* ChangeEvent */ useEffect, useState } from 'react';
import { PuffLoader } from 'react-spinners';
import emptyFolder from '../../../images/trustStatement/emptyFolder.svg';
import norecord from '../../../images/trustStatement/noFiles.svg';
import { IClientCodeByFilesResult, ITrustStatementClientCodeResult } from '../../../models/NotificationCenter/TrustStatementClientCodeResult';
import { trustStatementAction, trustStatementFileStatus } from '../../../models/accesscontrol/ClientUserAccessContext';
import { useStyles } from '../../../pages/NotificationCenter/NotificationCenterCss';
import { useGlobalState } from '../../../store/GlobalStore';
import { usePost } from '../../../utils/apiHelper';
import TooltipSmall from '../../GlobalStyles/TooltipSmall';
import DocumentPDFViewer from './DocumentPDFViewer';
import DownloadDocumentFile from './DownloadDocumentFile';
// import PaymentsIcon from '@mui/icons-material/Payments'; Future use
import PaymentIcon from '@mui/icons-material/Payment';
// import UnarchiveIcon from '@mui/icons-material/Unarchive'; Future use

const TrustStatementFiles: React.FC<{ onFileUpdate: () => void }> = (props) => {
    const classes = useStyles();
    const popoverClasses = useStyles();
    const { state } = useGlobalState();
    const [open, setOpen] = useState(false);
    const [ProgressBar, setShowProgressBar] = useState(false);
    const [showSearchFileIcon, setShowSearchFileIcon] = useState(true);
    const [folderId, setFolderId] = useState<number>(0);
    const [folderList, setFolderList] = useState<ITrustStatementClientCodeResult[]>([]);
    const [trustStatementFiles, setTrustStatementFiles] = useState<IClientCodeByFilesResult[]>([]);
    const [isArchive, setIsArchive] = useState(false);
    const [searchFiles, setSearchFiles] = useState("");
    const [triggerFileSearch, setTriggerFileSearch] = useState(false);
    const [searchFolders, setSearchFolders] = useState("");
    const [showSearchFolderIcon, setShowSearchFolderIcon] = useState(true);
    const [triggerFolderSearch, setTriggerFolderSearch] = useState(false);
    const [totalFiles, setTotalFiles] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [page, setPage] = useState(0);

    const [StartDate, setStartDate] = useState(new Date());
    const [EndDate, setEndDate] = useState(new Date());
    const [selectedStartDate, setSelectedStartDate] = useState(false);
    const [selectedEndDate, setSelectedEndDate] = useState(false);
    const [selectedFileType, setSelectedFileType] = useState<number>(1);

    const archivedFiles = trustStatementFiles?.filter(r => r?.fileStatusId === trustStatementFileStatus?.FileArchieved);

    const DateFormat = state.GlobalUtils?.settingValue === "DD/MM/YYYY" ? "dd/MM/yyyy" : state.GlobalUtils?.settingValue === "MM/DD/YYYY" ? "MM/dd/yyyy" : state.GlobalUtils?.settingValue === "YYYY/MM/DD" ? "yyyy/MM/dd" : "MM/dd/yyyy";

    const handleStartDateChange = (date: Date | null) => {
        setStartDate(date);
        setSelectedStartDate(true);
    };

    const handleEndDateChange = (date: Date | null) => {
        setEndDate(date);
        setSelectedEndDate(true);
    };

    const handleGetSubFolder = () => {
        setShowProgressBar(true);
        (async () => {
            let request = {
                userId: state.userAccessContext?.id,
                folderName: searchFolders
            }
            await usePost<ITrustStatementClientCodeResult[]>("GetClientCodeByUserId", request).then((r) => {
                setFolderList(r?.data);
                if (folderId === 0) {
                    setFolderId(r?.data[0]?.subFolderId);
                }
            })
        })().finally(() => {
            setShowProgressBar(false);
        })
    }

    const GetFilesBasedFolder = () => {
        setShowProgressBar(true);
        setTrustStatementFiles([]);
        (async () => {
            let request = {
                subFolderId: folderId,
                userId: state?.userAccessContext?.id,
                fileStatus: selectedFileType,
                fileName: searchFiles,
                startDate: (selectedStartDate && StartDate === null ? '' : selectedStartDate ? moment(StartDate).format('YYYY-MM-DD') : ''),
                endDate: (selectedEndDate && EndDate === null ? '' : selectedEndDate ? moment(EndDate).format('YYYY-MM-DD') : '')
            };
            await usePost<{ getFilesByClientCodeDetails: IClientCodeByFilesResult[], total: number }>("GetFilesByClientCode", request).then((response) => {
                setTrustStatementFiles(response?.data.getFilesByClientCodeDetails);
                setTotalFiles(response?.data?.total);
            }).finally(() => {
                setShowProgressBar(false);
            });
        })();
    }

    useEffect(() => {
        handleGetSubFolder();
        GetFilesBasedFolder();
    }, [isArchive, folderId, triggerFileSearch, triggerFolderSearch])

    {/* Future Use */ }
    /* function handleSwitched(e: ChangeEvent<HTMLInputElement>) {
        setIsArchive(e.target.checked);
    } */

    const handleClickArchieveFile = (fileId: number) => {
        setShowProgressBar(true);
        (async () => {
            let request = {
                "fileId": fileId,
                "actionId": trustStatementAction?.FileArchieved,
                "userId": state?.userAccessContext?.id
            }
            await usePost<any>("TrustStatementAuditHistory", request).then((r) => {
            }).finally(() => {
                GetFilesBasedFolder();
                setShowProgressBar(false);
                props?.onFileUpdate();
            });
        })();
    }

    // Future Use
    /* const handleClickUnArchieveFile = (fileId: number) => {
        setShowProgressBar(true);
        (async () => {
            let request = {
                "fileId": fileId,
                "actionId": trustStatementAction?.FileUnarchieved,
                "userId": state?.userAccessContext?.id
            }
            await usePost<any>("TrustStatementAuditHistory", request).then((r) => {
            }).finally(() => {
                GetFilesBasedFolder();
                setShowProgressBar(false);
            });
        })();
    } */

    const initiateSearch = () => {
        setTrustStatementFiles([]);
        setTriggerFileSearch(!triggerFileSearch);
    };

    const initiateFolderSearch = () => {
        setFolderList([]);
        setTriggerFolderSearch(!triggerFolderSearch);
    };

    const cancelSearch = () => {
        setShowProgressBar(true);
        setSearchFiles("");
        setTrustStatementFiles([]);
        GetFilesBasedFolder();
    };

    const cancelFolderSearch = () => {
        setShowProgressBar(true);
        setSearchFolders("");
        setFolderList([]);
        (async () => {
            let request = {
                userId: state.userAccessContext?.id,
                folderName: null
            }
            await usePost<ITrustStatementClientCodeResult[]>("GetClientCodeByUserId", request).then((r) => {
                setFolderList(r?.data);
            })
        })().finally(() => {
            setShowProgressBar(false);
        })
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
    };

    const handleCheckboxChangeToggle = (subFolderId: number) => {
        setFolderId(subFolderId);
    };

    const handleFileType = (event) => {
        setSelectedFileType(event.target.value);
    };

    const HandleFilter = () => {
        GetFilesBasedFolder();
    }

    const handleClearFilter = () => {
        setSelectedStartDate(false);
        setSelectedEndDate(false);
        setStartDate(null);
        setEndDate(null);
        setSelectedFileType(1);
        (async () => {
            let request = {
                subFolderId: folderId,
                userId: state?.userAccessContext?.id,
                fileStatus: trustStatementFileStatus?.AllFileProcess,
                fileName: searchFiles,
                startDate: null,
                endDate: null
            };
            await usePost<{ getFilesByClientCodeDetails: IClientCodeByFilesResult[], total: number }>("GetFilesByClientCode", request).then((response) => {
                setTrustStatementFiles(response?.data.getFilesByClientCodeDetails);
                setTotalFiles(response?.data?.total);
            }).finally(() => {
                setShowProgressBar(false);
            });
        })();
    }



    return (
        <React.Fragment>
            <Grid container spacing={0} padding={0} boxShadow={2} borderRadius={1} border={'1px solid lightgray'}>
                <Backdrop className={classes.backdrop} open={ProgressBar}>
                    <PuffLoader size={100} color={"white"} speedMultiplier={1} />
                </Backdrop>
                <Grid item xs={12} borderBottom={'1px solid lightgray'} padding={0.4}>
                    <Grid container spacing={0} padding={0}>
                        <Grid item xs={6}>
                            <Typography variant="body1" align='left' padding={0.6} mt={'3px'} color={'black'} borderRadius={1}>
                                <b>Trust Statement Files</b>
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField value={searchFiles} variant="outlined"
                                type='text' placeholder="Search selected client code Files"
                                className={`${classes.searchClientFiles}`}
                                onChange={e => setSearchFiles(e.target.value.trim())}
                                onKeyPress={event => {
                                    if (event.key === 'Enter' && searchFiles != "") {
                                        initiateSearch(); setShowSearchFileIcon(false);
                                    } else if (event.key === 'Enter' && searchFiles === "") {
                                        initiateSearch(); setShowSearchFileIcon(true);
                                    }
                                }}
                                style={{ marginBottom: '5px' }}
                                inputProps={{ maxlength: 255 }}
                                InputProps={{
                                    classes: {
                                        root: classes.ftpoutlinedInput1,
                                        notchedOutline: classes.ftpnotchedOutline,
                                    },
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {showSearchFileIcon === true ?
                                                <IconButton onClick={e => { initiateSearch(); setShowSearchFileIcon(false); }} style={{ padding: 2 }}>
                                                    <SearchIcon fontSize={'small'} />
                                                </IconButton>
                                                :
                                                <IconButton onClick={e => { cancelSearch(); setShowSearchFileIcon(true); }} style={{ padding: 2 }}>
                                                    <CloseIcon fontSize={'small'} />
                                                </IconButton>
                                            }
                                        </InputAdornment>
                                    )
                                }}
                            />
                            {/* Future Use */}
                            {/* <FormControlLabel
                            labelPlacement="start"
                            control={<ArchiveSwitch checked={isArchive} onChange={handleSwitched} />}
                            label={<Typography variant='body2' color={'#006BD6'}>
                                <b>{isArchive ? "Switch to view Processed Files" : "Switch to view Archive Files"}</b>
                            </Typography>}
                        /> */}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={2} borderRight={'1px solid lightgray'} padding={0.5}>
                    <TextField value={searchFolders} variant="outlined"
                        type='text' placeholder="Search Client Code"
                        className={`${classes.searchClientCode}`}
                        onChange={e => setSearchFolders(e.target.value.trim())}
                        onKeyPress={event => {
                            if (event.key === 'Enter' && searchFolders != "") {
                                initiateFolderSearch(); setShowSearchFolderIcon(false);
                            } else if (event.key === 'Enter' && searchFolders === "") {
                                initiateFolderSearch(); setShowSearchFolderIcon(true);
                            }
                        }}
                        inputProps={{ maxlength: 255 }}
                        InputProps={{
                            classes: {
                                root: classes.ftpoutlinedInput,
                                notchedOutline: classes.ftpnotchedOutline,
                            },
                            endAdornment: (
                                <InputAdornment position="end">
                                    {showSearchFolderIcon === true ?
                                        <IconButton onClick={e => { initiateFolderSearch(); setShowSearchFolderIcon(false); }} style={{ padding: 2 }}>
                                            <SearchIcon fontSize={'small'} />
                                        </IconButton>
                                        :
                                        <IconButton onClick={e => { cancelFolderSearch(); setShowSearchFolderIcon(true); }} style={{ padding: 2 }}>
                                            <CloseIcon fontSize={'small'} style={{ padding: 2 }} />
                                        </IconButton>
                                    }
                                </InputAdornment>
                            )
                        }}
                    />

                    <List >
                        {folderList?.map((row, index) => (
                            <ListItem key={index} role="listitem" className={classes.listStyle} onClick={() => handleCheckboxChangeToggle(row?.subFolderId)}>
                                <ListItemIcon className={classes.fileNameStyle}>
                                    <Checkbox
                                        id="FV_Checkbox"
                                        size="small"
                                        color='success'
                                        className={classes.CheckBoxStyle}
                                        checked={row?.subFolderId == folderId}
                                        tabIndex={-1}
                                    />
                                </ListItemIcon>
                                <ListItemIcon className={classes.fileNameStyle}>
                                    {row?.subFolderId == folderId ?
                                        <FolderOpenIcon className={classes.listIconSize} style={{ color: '#FFC107' }} />
                                        : <FolderIcon className={classes.listIconSize} style={{ color: "#FFC107" }} />}
                                </ListItemIcon>
                                <ListItemText primary={
                                    <Typography variant='body2' fontSize={12} className={`${searchFolders === "" ? classes.NormalText : row?.subFolderName === null ? null : row?.subFolderName?.toUpperCase().includes(searchFolders.toUpperCase()) ? classes.TextHighlight : ""}`}>
                                        {row?.subFolderName}
                                    </Typography>}
                                />
                                {/* <SendEmailToUsers clientCode={row?.subFolderName} />
                                <SendEmailUserList clientCode={row?.subFolderName} folderId={row?.subFolderId} /> */}
                            </ListItem>
                        ))}
                    </List >
                    {!ProgressBar && !folderList?.length ?
                        <Box m={0} p={0} mt={10} display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'}>
                            <img alt="noRecords" src={emptyFolder} width="80px" style={{ textAlign: 'center' }} />
                            <Typography variant='body2' align='center' color='red' mt={1}>
                                <b>No folder to display</b>
                            </Typography>
                        </Box>
                        : null
                    }
                </Grid>
                <Grid item xs={10} padding={"0px"}>
                    <Grid container spacing={1}>
                        <Grid item xs={3}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    label="Start Date"
                                    variant="inline"
                                    size="small"
                                    inputVariant="outlined"
                                    format={DateFormat}
                                    disableToolbar
                                    autoOk={true}
                                    margin="normal"
                                    id="NC_StartDate"
                                    value={StartDate}
                                    className={classes.dateStyle}
                                    onChange={handleStartDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    disableFuture={true}
                                    PopoverProps={{ classes: popoverClasses }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={3}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    label="End Date"
                                    variant="inline"
                                    size="small"
                                    inputVariant="outlined"
                                    format={DateFormat}
                                    disableToolbar
                                    autoOk={true}
                                    margin="normal"
                                    className={classes.dateStyle}
                                    id="NC_StartDate"
                                    value={EndDate}
                                    onChange={handleEndDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    disableFuture={true}
                                    PopoverProps={{ classes: popoverClasses }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl variant="outlined" size='small' fullWidth style={{ marginTop: '10px' }}>
                                <InputLabel htmlFor="outlined-age-native-simple">Select File Type</InputLabel>
                                <Select
                                    native
                                    label="Select File Type"
                                    value={selectedFileType}
                                    onChange={handleFileType}
                                    inputProps={{
                                        name: 'age',
                                        id: 'outlined-age-native-simple',
                                        classes: {
                                            root: classes.smallFontSize,
                                        },
                                    }}

                                >
                                    <option value={0}>--Select File Type--</option>
                                    <option value={1}>All Trust Notification</option>
                                    <option value={2}>Unread Trust Notifications</option>
                                    <option value={3}>Archived Trust Notifications</option>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <Button variant="contained" size='small' color='primary' startIcon={<FilterListIcon fontSize='small' />} onClick={HandleFilter}
                                className={classes.filterButton} >Filter</Button>
                            <Button variant="contained" size='small' color='error' startIcon={<FilterListOffIcon fontSize='small' />} onClick={handleClearFilter}
                                className={classes.clearFilterButton} >Clear Filter</Button>
                        </Grid>
                    </Grid>


                    <TableContainer className={`${classes.trustStatement} ${"scrollbox"} ${"on-scrollbar"}`} style={{ marginTop: '0px' }}>
                        <Table aria-label="customized table" size="small" stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.TableCellHeadStyle}>
                                        FILE NAME
                                    </TableCell>
                                    <TableCell className={classes.TableCellHeadStyle}>
                                        CREATED DATE
                                    </TableCell>
                                    <TableCell className={classes.TableCellHeadStyle}>
                                        PDF PREVIEW
                                    </TableCell>
                                    <TableCell className={classes.TableCellHeadStyle}>
                                        DOWNLOAD
                                    </TableCell>
                                    <TableCell className={classes.TableCellHeadStyle}>
                                        ARCHIVE
                                    </TableCell>
                                    <TableCell className={classes.TableCellHeadStyle}>
                                        PAY
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {trustStatementFiles?.map((r) => (
                                    <React.Fragment>
                                        {r?.fileStatusId === trustStatementFileStatus?.FileProcessed && (
                                            <TableRow>
                                                <TableCell className={classes.tablecellPadding} >
                                                    <Typography variant='body2' fontSize={12} className={`${searchFiles === "" ? classes.NormalText : r?.fileName === null ? null : r?.fileName?.toUpperCase().includes(searchFiles.toUpperCase()) ? classes.TextHighlight : ""}`}>
                                                        {r?.fileName}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell className={classes.tablecellPadding}>
                                                    {moment(r?.createdDate).format(state.GlobalUtils?.settingValue)} {moment(r?.createdDate).format('HH:mm:ss')}
                                                </TableCell>
                                                <TableCell className={classes.tablecellPadding} >
                                                    <DocumentPDFViewer row={r} enable={r?.fileName?.split('.')?.pop() === 'pdf' ? true : r?.fileName?.split('.')?.pop() === 'PDF' ? true : false} />
                                                </TableCell>
                                                <TableCell className={classes.tablecellPadding} >
                                                    <DownloadDocumentFile row={r} />
                                                </TableCell>
                                                <TableCell className={classes.tablecellPadding} >
                                                    {!isArchive ?
                                                        <IconButton size='small' className={classes.archieveStyle} onClick={() => { handleClickArchieveFile(r?.fileId); }}>
                                                            <TooltipSmall title="Archive File">
                                                                <ArchiveFile fontSize='small' style={{ color: '#f77f00' }} />
                                                            </TooltipSmall>
                                                        </IconButton>
                                                        : null
                                                        /* Future Use */
                                                        // <IconButton size='small' className={classes.archieveStyle} onClick={() => { handleClickUnArchieveFile(r?.fileId); }}>
                                                        //     <TooltipSmall title="Unarchive File">
                                                        //         <UnarchiveIcon fontSize='small' style={{ color: '#f77f00' }} />
                                                        //     </TooltipSmall>
                                                        // </IconButton>
                                                    }
                                                </TableCell>
                                                <TableCell className={classes.tablecellPadding} >
                                                    <Link href="https://app.payinvoice.com/paynow/armsolutions" underline="none" target='_blank' sx={{ textDecoration: "none !important" }}>
                                                        <IconButton size='small'>
                                                            <TooltipSmall title="Pay Now">
                                                                <PaymentIcon fontSize='small' color='primary' />
                                                            </TooltipSmall>
                                                        </IconButton>
                                                    </Link>
                                                </TableCell>
                                            </TableRow >
                                        )}
                                    </React.Fragment>
                                ))}
                                {archivedFiles?.length > 0 ? (
                                    <React.Fragment>
                                        <TableRow className={classes.archievedStyle}>
                                            <TableCell colSpan={5} className={classes.tablecellPadding}>
                                                <Typography variant='body2' color="#000000">
                                                    <b>Archived Files ({archivedFiles?.length})</b>
                                                </Typography>
                                            </TableCell>
                                            <TableCell className={classes.tablecellPadding}>
                                                <IconButton
                                                    aria-label="expand row"
                                                    size="small"
                                                    onClick={() => setOpen(!open)}
                                                >
                                                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                                                <Collapse in={open} timeout="auto" unmountOnExit style={{ padding: "8px" }}>
                                                    <TableContainer component={Paper}>
                                                        <Table aria-label="customized table" size="small" stickyHeader>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell className={classes.TableCellHeadStyle}>
                                                                        FILE NAME
                                                                    </TableCell>
                                                                    <TableCell className={classes.TableCellHeadStyle}>
                                                                        CREATED DATE
                                                                    </TableCell>
                                                                    <TableCell className={classes.TableCellHeadStyle}>
                                                                        PDF PREVIEW
                                                                    </TableCell>
                                                                    <TableCell className={classes.TableCellHeadStyle}>
                                                                        DOWNLOAD
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {archivedFiles.map((r) => (
                                                                    <TableRow key={r?.fileId}>
                                                                        <TableCell className={classes.tablecellPadding}>
                                                                            <Typography variant='body2' fontSize={12} className={`${searchFiles === "" ? classes.NormalText : r?.fileName === null ? null : r?.fileName?.toUpperCase().includes(searchFiles.toUpperCase()) ? classes.TextHighlight : ""}`}>
                                                                                {r?.fileName}
                                                                            </Typography>
                                                                        </TableCell>
                                                                        <TableCell className={classes.tablecellPadding}>
                                                                            <span className={classes.paymentText}>
                                                                                {moment(r?.createdDate).format(state.GlobalUtils?.settingValue)} {moment(r?.createdDate).format('HH:mm:ss')}
                                                                            </span>
                                                                        </TableCell>
                                                                        <TableCell className={classes.tablecellPadding}>
                                                                            <DocumentPDFViewer row={r} enable={r?.fileName?.split('.')?.pop()?.toUpperCase() === 'PDF'} />
                                                                        </TableCell>
                                                                        <TableCell className={classes.tablecellPadding}>
                                                                            <DownloadDocumentFile row={r} />
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>
                                    </React.Fragment>
                                ) : (
                                    null
                                )}
                            </TableBody>
                        </Table >
                        {!trustStatementFiles?.length && !ProgressBar ?
                            <Box m={0} p={0} mt={7} display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'}>
                                <img alt="noRecords" src={norecord} width="150px" style={{ textAlign: 'center' }} />
                                <Typography variant='body2' align='center' color='red' mt={1}>
                                    <b>No files to display</b>
                                </Typography>
                            </Box>
                            : null}
                    </TableContainer>

                    <TablePagination
                        id="Files_TablePagination"
                        rowsPerPageOptions={[20, 50, 100]}
                        component="div"
                        count={totalFiles === undefined ? 0 : totalFiles}
                        rowsPerPage={rowsPerPage}
                        style={{ borderTop: '1px solid lightgray' }}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </Grid>
            </Grid >
        </React.Fragment >
    )
}

export default TrustStatementFiles