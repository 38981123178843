import { Box, Grid, IconButton, makeStyles, TableContainer, Typography } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer/Footer';
import { useGlobalState } from '../store/GlobalStore';
// Icon Image
import MassUpload from "../components/GlobalAdmin/MassDocUpload/MassUpload";
import AdfEnvironment from '../images/ADF/AdfEnvironment.svg';
import Adf from '../images/admin/Adf.svg';
import AgentUser from '../images/admin/agentuser.svg';
import Authorization from '../images/admin/authorization.svg';
import Backend from '../images/admin/backend.svg';
import Faq from '../images/admin/faq.svg';
import NewFileUpload from '../images/admin/fileupload.svg';
import Message from '../images/admin/global.svg';
import Notificationmessage from '../images/admin/messagenotification.svg';
import NotificationRollback from '../images/admin/notification.svg';
import Placement from '../images/admin/placement.svg';
import Settings from '../images/admin/settings.svg';
import UpdateHistory from '../images/admin/updatehistory.svg';
import LoginHistory from '../images/admin/userloginhistory.svg';
import User from '../images/admin/users.svg';
import agentTemplate from '../images/agentUser/agentTemplate.svg';
import cloudFile from '../images/files/data-storage.svg';
import prospectusers from '../images/users/prospectusers.svg';
import apiKeyManagement from '../images/admin/ManageAPIKey.svg';

const useStyles = makeStyles((theme) => ({
  text: {
    color: "black",
    textAlign: "center",
    fontSize: '16px',
    fontWeight: 'bold',
  },
  card1: {
    display: 'block',
    width: '5.5cm',
    height: '4cm',
    marginLeft: '30px',
    marginTop: '0.5cm',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    textDecoration: 'none',
    '&:hover': {
      color: 'black',
      backgroundColor: 'white',
      borderRadius: '10px',
      transform: 'scale(1.1)',
      border: '2px blue solid',
      cursor: 'pointer',
      boxShadow: '0 10px 10px rgba(0, 0, 0, 0.4)',
    },
  },
  div: {
    flexGrow: 1,
  },
  adminLayout: {
    maxHeight: 800,
    minHeight: 800,
    [theme.breakpoints.up('lg')]: {
      maxHeight: 'calc( 100vh - 170px)',
      minHeight: 'calc( 100vh - 170px)',
    }
  },
  image: {
    width: '70px',
    height: '70px',
    textAlign: 'center'
  },
  IconButtonripple: {
    '&:hover': {
      backgroundColor: 'transparent',
    }
  },
  Tooltip: {
    fontSize: '14px',
  },
  textStyle: {
    marginTop: '5px',
    marginLeft: '15px',
    float: 'left'
  },
  TextNormal: {
    marginTop: '-180px'
  },
  footerStyle: {
    marginTop: '30px'
  },
  footerStyle1: {
    marginTop: '200px',
    float: 'left',
    marginLeft: '20px',
  }
}));

export default function NativeSelects() {
  const classes = useStyles();
  const { state } = useGlobalState();

  return (
    <React.Fragment>
      <div className={classes.div}>
        {state.userAccessContext?.role === 3 ?
          <Typography variant="h5" gutterBottom className={`${classes.textStyle} ${"headertitle"}`}>
            <b> SUPER ADMIN</b>
          </Typography>
          :
          <Typography variant="h5" gutterBottom className={`${classes.textStyle} ${"headertitle"}`}>
            <b> GLOBAL ADMIN</b>
          </Typography>
        }
        <TableContainer className={` ${classes.adminLayout} ${"scrollbox"} ${"on-scrollbar"}`}>
          <Grid container spacing={0} >
            <Grid item xs={6} sm={6} md={4} lg={2} >
              <Link to="/globalmessage" className={classes.card1}>
                <Box>
                  <IconButton id="Admin_Page_GlobalMessage_Button" aria-label="global_message" component={Link} to="/globalmessage" className={classes.IconButtonripple}>
                    <img src={Message} alt="Admin_Global_Message" className={classes.image} />
                  </IconButton>
                  <Box>
                    <Typography variant="h6" className={classes.text}>
                      Global Message
                    </Typography>
                  </Box>
                </Box>
              </Link>
            </Grid>

            <Grid item xs={6} sm={6} md={4} lg={2} >
              <Link to="/GAFAQ" className={classes.card1}>
                <Box>
                  <IconButton id="Admin_Page_FAQ_Button" aria-label="faq" component={Link} to="/GAFAQ" className={classes.IconButtonripple}>
                    <img src={Faq} alt="Admin_Faq" className={classes.image} />
                  </IconButton>
                  <Box>
                    <Typography variant="h6" className={classes.text}>
                      FAQ
                    </Typography>
                  </Box>
                </Box>
              </Link>
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={2} >
              <Link to="/users" className={classes.card1}>
                <Box>
                  <IconButton id="Admin_Page_Users_Button" aria-label="users" component={Link} to="/users" className={classes.IconButtonripple}>
                    <img src={User} alt="Admin_User" className={classes.image} />
                  </IconButton>
                  <Box>
                    <Typography variant="h6" className={classes.text}>
                      Users
                    </Typography>
                  </Box>
                </Box>
              </Link>
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={2} >
              <Link to="/clientplacementoptions" className={classes.card1}>
                <Box>
                  <IconButton id="Admin_Page_ClientPlacementOptions_Button" aria-label="client_placement_options" component={Link} to="/clientplacementoptions" className={classes.IconButtonripple}>
                    <img src={Placement} alt="Admin_Client_Placement_Option" className={classes.image} />
                  </IconButton>
                  <Box>
                    <Typography variant="h6" className={classes.text}>
                      Client Placement Options
                    </Typography>
                  </Box>
                </Box>
              </Link>
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={2} >
              <Link to="/userloginhistory" className={classes.card1}>
                <Box>
                  <IconButton id="Admin_Page_UserLoginHistory_Button" aria-label="user_login_history" component={Link} to="/userloginhistory" className={classes.IconButtonripple}>
                    <img src={LoginHistory} alt="Admin_User_Login_History" className={classes.image} />
                  </IconButton>
                  <Box>
                    <Typography variant="h6" className={classes.text}>
                      User Login History
                    </Typography>
                  </Box>
                </Box>
              </Link>
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={2} >
              <Link to="/updatehistory" className={classes.card1}>
                <Box>
                  <IconButton id="Admin_Page_UpdateHistory_Button" aria-label="update_history" component={Link} to="/updatehistory" className={classes.IconButtonripple}>
                    <img src={UpdateHistory} alt="Admin_Update_History" className={classes.image} />
                  </IconButton>
                  <Box>
                    <Typography variant="h6" className={classes.text}>
                      Update History
                    </Typography>
                  </Box>
                </Box>
              </Link>
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={2} >
              <Link to="/newfileupload" className={classes.card1}>
                <Box>
                  <IconButton id="SN_NewFileUpload_btn" aria-label="newfile_upload" component={Link} to="/newfileupload" className={classes.IconButtonripple}>
                    <img src={NewFileUpload} alt="Admin_Notificatio_File_Upload" className={classes.image} />
                  </IconButton>
                  <Box>
                    <Typography variant="h6" className={classes.text}>
                      Notification File Upload
                    </Typography>
                  </Box>
                </Box>
              </Link>
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={2} >
              <Link to="/settlementauthorization" className={classes.card1}>
                <Box>
                  <IconButton id="SN_Settlement_btn" aria-label="settlement_authorization" component={Link} to="/settlementauthorization" className={classes.IconButtonripple}>
                    <img src={Authorization} alt="Admin_Settlement_Authorization" className={classes.image} />
                  </IconButton>
                  <Box>
                    <Typography variant="h6" className={classes.text}>
                      Settlement Authorization
                    </Typography>
                  </Box>
                </Box>
              </Link>
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={2} >
              <Link to="/message" className={classes.card1}>
                <Box>
                  <IconButton id="SN_Message_btn" aria-label="message" component={Link} to="/message" className={classes.IconButtonripple}>
                    <img src={Notificationmessage} alt="Admin_Notification_Message" className={classes.image} />
                  </IconButton>
                  <Box>
                    <Typography variant="h6" className={classes.text}>
                      Notification Message
                    </Typography>
                  </Box>
                </Box>
              </Link>
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={2} >
              <Link to="/notificationrollback" className={classes.card1}>
                <Box>
                  <IconButton id="Admin_Page_NotificationRollBack_Button" aria-label="notification_rollback" component={Link} to="/notificationrollback" className={classes.IconButtonripple}>
                    <img src={NotificationRollback} alt="Admin_Notification_RollBack" className={classes.image} />
                  </IconButton>
                  <Box>
                    <Typography variant="h6" className={classes.text}>
                      Notification RollBack
                    </Typography>
                  </Box>
                </Box>
              </Link>
            </Grid>
            {state.userAccessContext?.role === 3 ?
              <>
                <Grid item xs={6} sm={6} md={4} lg={2} >
                  <Link to="/globalsettings" className={classes.card1}>
                    <Box>
                      <IconButton id="Admin_Page_GlobalSettings_Button" aria-label="global_settings" disabled={!state?.userAccessContext?.isGlobalSettings} component={Link} to="/globalsettings" className={classes.IconButtonripple}>
                        <img src={Settings} alt="Admin_Global_Settings" className={classes.image} />
                      </IconButton>
                      <Box>
                        <Typography variant="h6" className={classes.text}>
                          Global Settings
                        </Typography>
                      </Box>
                    </Box>
                  </Link>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={2} >
                  <Link to="/adfpipelinelogs" className={classes.card1}>
                    <Box>
                      <IconButton id="Admin_Page_ADFPipeline_Button" aria-label="adf_logs" disabled={!state?.userAccessContext?.isAdfLogs} component={Link} to="/adfpipelinelogs" className={classes.IconButtonripple}>
                        <img src={Adf} alt="Admin_ADF_Logs" className={classes.image} />
                      </IconButton>
                      <Box>
                        <Typography variant="h6" className={classes.text}>
                          ADF Logs
                        </Typography>
                      </Box>
                    </Box>
                  </Link>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={2} >
                  <Link to="/azfunctionlogs" className={classes.card1}>
                    <Box>
                      <IconButton id="Admin_Page_AzFunction_Button" aria-label="azure_function" disabled={!state?.userAccessContext?.isAzureFunc} component={Link} to="/azfunctionlogs" className={classes.IconButtonripple}>
                        <img src={Backend} alt="Admin_Backend Jobs" className={classes.image} />
                      </IconButton>
                      <Box>
                        <Typography variant="h6" className={classes.text}>
                          Backend Jobs
                        </Typography>
                      </Box>
                    </Box>
                  </Link>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={2} >
                  <Link to="/adfcontrol" className={classes.card1}>
                    <Box>
                      <IconButton id="Admin_Page_AZEnvironment_Button" aria-label="azure_environment" disabled={!state?.userAccessContext?.isAzureFunc} component={Link} to="/adfcontrol" className={classes.IconButtonripple}>
                        <img src={AdfEnvironment} alt="Admin_ ADF_Environment" className={classes.image} />
                      </IconButton>
                      <Box>
                        <Typography variant="h6" className={classes.text}>
                          ADF Environment
                        </Typography>
                      </Box>
                    </Box>
                  </Link>
                </Grid>

                <Grid item xs={6} sm={6} md={4} lg={2} >
                  <Link to="/webviewfiles" className={classes.card1}>
                    <Box>
                      <IconButton id="Admin_Page_ManageFileAccess_Button" aria-label="manage_file_access" component={Link} to="/webviewfiles" className={classes.IconButtonripple}>
                        <img src={cloudFile} alt="Admin_Manage_File_Access" className={classes.image} />
                      </IconButton>
                      <Box>
                        <Typography variant="h6" className={classes.text}>
                          WebView Files
                        </Typography>
                      </Box>
                    </Box>
                  </Link>
                </Grid>

                <MassUpload />
              </>
              : null
            }

            {!state?.userAccessContext?.agentCreateUser ? null :
              <React.Fragment>
                <Grid item xs={6} sm={6} md={4} lg={2} >
                  <Link to="/agentuser" className={classes.card1}>
                    <Box>
                      <IconButton id="Admin_Agent_User" aria-label="agent_user" component={Link} to="/agentuser" className={classes.IconButtonripple}>
                        <img src={AgentUser} alt="Admin_Agent_User" className={classes.image} />
                      </IconButton>
                      <Box>
                        <Typography variant="h6" className={classes.text}>
                          Agent Users
                        </Typography>
                      </Box>
                    </Box>
                  </Link>
                </Grid>

                <Grid item xs={6} sm={6} md={4} lg={2} >
                  <Link to="/agenttemplate" className={classes.card1}>
                    <Box>
                      <IconButton id="Admin_Page_ManageFileAccess_Button" aria-label="manage_file_access" component={Link} to="/agenttemplate" className={classes.IconButtonripple}>
                        <img src={agentTemplate} alt="Admin_Manage_File_Access" className={classes.image} />
                      </IconButton>
                      <Box>
                        <Typography variant="h6" className={classes.text}>
                          Manage Activity Template
                        </Typography>
                      </Box>
                    </Box>
                  </Link>
                </Grid>
              </React.Fragment>
            }

            <Grid item xs={6} sm={6} md={4} lg={2} >
              <Link to="/prospectUsersList" className={classes.card1}>
                <Box>
                  <IconButton id="Admin_Page_ProspectUser" aria-label="prospectuser" component={Link} to="/prospectUsersList" className={classes.IconButtonripple}>
                    <img src={prospectusers} alt="Admin_Page_ProspectUser" className={classes.image} />
                  </IconButton>
                  <Box>
                    <Typography variant="h6" className={classes.text}>
                      Prospect Users
                    </Typography>
                  </Box>
                </Box>
              </Link>
            </Grid>

            <Grid item xs={6} sm={6} md={4} lg={2} >
              <Link to="/UploadedFiles" className={classes.card1}>
                <Box>
                  <IconButton id="Admin_Page_ProspectUser" aria-label="prospectuser" component={Link} to="/UploadedFiles" className={classes.IconButtonripple}>
                    <img src={cloudFile} alt="Admin_Page_ProspectUser" className={classes.image} />
                  </IconButton>
                  <Box>
                    <Typography variant="h6" className={classes.text}>
                      File Manager
                    </Typography>
                  </Box>
                </Box>
              </Link>
            </Grid>

            <Grid item xs={6} sm={6} md={4} lg={2} >
              <Link to="/apiKeyManagement" className={classes.card1}>
                <Box>
                  <IconButton id="apiKeyManagement" aria-label="apiKeyManagement" component={Link} to="/apiKeyManagement" className={classes.IconButtonripple}>
                    <img src={apiKeyManagement} alt="apiKeyManagement" className={classes.image} />
                  </IconButton>
                  <Box>
                    <Typography variant="h6" className={classes.text}>
                      Manage API Keys
                    </Typography>
                  </Box>
                </Box>
              </Link>
            </Grid>
          </Grid>
        </TableContainer>
        <Footer />
      </div>
    </React.Fragment >
  );
}