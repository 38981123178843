import DateFnsUtils from '@date-io/date-fns';
import { FormControl, InputAdornment, InputLabel, Select, TablePagination, TextField } from '@material-ui/core';
import SearchIcon from "@material-ui/icons/Search";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import CloseIcon from '@mui/icons-material/Close';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import {
  Backdrop, Box, Button, Chip, Grid, IconButton, Table, TableBody, TableContainer,
  TableHead, TableSortLabel, Typography
} from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { PuffLoader } from 'react-spinners';
import Footer from '../../components/Footer/Footer';
import StyledTableRow from '../../components/GlobalStyles/StyledTableRow';
import { StyledTableCell } from '../../components/GlobalStyles/TableStyledCell';
import TooltipSmall from '../../components/GlobalStyles/TooltipSmall';
import { ITrustStatementClientCode, ITrustStatementReportFile } from '../../models/Reports/ITrustStatementReportFile';
import { useGlobalState } from '../../store/GlobalStore';
import { useFetch, usePost } from '../../utils/apiHelper';
import TrustStamenetReportPDFPreview from './TrustStamenetReportPDFPreview';
import TrustStatementHistory from './TrustStatementHistory';
import FileStatusPieChart from './TrustStatementHistoryChart/FileStatusPieChart';
import { useStylesTrustStatementReport } from './TrustStatementReportCss';
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import FilePlacedChart from './TrustStatementHistoryChart/FilePlacedChart';
import ClientCodeBasedChart from './TrustStatementHistoryChart/ClientCodeBasedChart';

const TrustStatementReport = () => {
  const classes = useStylesTrustStatementReport();
  const { state } = useGlobalState();
  const [ProgressBar, setShowProgressBar] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [StartDate, setStartDate] = useState(new Date());
  const [EndDate, setEndDate] = useState(new Date());
  const [searchFiles, setSearchFiles] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState(false);
  const [selectedEndDate, setSelectedEndDate] = useState(false);
  const [showSearchFileIcon, setShowSearchFileIcon] = useState(true);
  const [triggerFileSearch, setTriggerFileSearch] = useState(false);
  const [selectedFileType, setSelectedFileType] = useState<number>(null);
  const [trustStatementReportList, setTrustStatementReportList] = useState<ITrustStatementReportFile[]>([]);
  const [trustStamentClientCode, setTrustStatementClientCode] = useState<ITrustStatementClientCode[]>([]);
  const [selectedClientCodeId, setselectedClientCodeId] = useState<number>(null);

  const DateFormat = state.GlobalUtils?.settingValue === "DD/MM/YYYY" ? "dd/MM/yyyy" : state.GlobalUtils?.settingValue === "MM/DD/YYYY" ? "MM/dd/yyyy"
    : state.GlobalUtils?.settingValue === "YYYY/MM/DD" ? "yyyy/MM/dd" : "MM/dd/yyyy";

  const handleStartDateChange = (date: Date | null) => {
    setStartDate(date);
    setSelectedStartDate(true);
  };

  const handleEndDateChange = (date: Date | null) => {
    setEndDate(date);
    setSelectedEndDate(true);
  };

  const headCells = [
    { id: "date", numeric: false, disablePadding: false, label: "DATE", sortable: true },
    { id: "time", numeric: false, disablePadding: false, label: "TIME", sortable: true },
    { id: "clientCode", numeric: false, disablePadding: false, label: "CLIENT CODE", sortable: true },
    { id: "fileName", numeric: false, disablePadding: false, label: "FILE NAME", sortable: true },
    { id: "fileSize", numeric: false, disablePadding: false, label: "FILE SIZE", sortable: true },
    { id: "status", numeric: false, disablePadding: false, label: "STATUS", sortable: true },
    { id: "preview", numeric: false, disablePadding: false, label: "PREVIEW", sortable: false },
    { id: "history", numeric: false, disablePadding: false, label: "HISTORY", sortable: false },
  ];

  function EnhancedTableHead(props) {
    const {
      classes,
      order,
      orderBy,
      onRequestSort
    } = props;
    const createSortHandler = property => event => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <StyledTableRow>
          {headCells.map(headCell => (
            <StyledTableCell
              key={headCell?.id}
              sortDirection={orderBy === headCell.id ? order : false}>
              <TableSortLabel active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}>
                {headCell.label || headCell.sortable}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </StyledTableCell>
          ))}
        </StyledTableRow>
      </TableHead>
    );
  }

  useEffect(() => {
    GetTrustStatementReportFile();
    GetTrustStatementClientCode();
  }, [triggerFileSearch])

  const GetTrustStatementReportFile = () => {
    setShowProgressBar(true);
    (async () => {
      let request = {
        startDate: (selectedStartDate && StartDate === null ? '' : selectedStartDate ? moment(StartDate).format('YYYY-MM-DD') : ''),
        endDate: (selectedEndDate && EndDate === null ? '' : selectedEndDate ? moment(EndDate).format('YYYY-MM-DD') : ''),
        subFolderId: selectedClientCodeId,
        fileStatus: selectedFileType,
        fileName: searchFiles,
      }
      await usePost<{ trustStatementReportResult: ITrustStatementReportFile[], totalCount: number }>("GetTrustStatementReportFiles", request).then((result) => {
        setTrustStatementReportList(result?.data["trustStatementReportResult"]);
        setTotalCount(result?.data["totalCount"]);
      }).finally(() => {
        setShowProgressBar(false);
      })
    })()
  }

  const GetTrustStatementClientCode = () => {
    setShowProgressBar(true);
    (async () => {
      await useFetch<ITrustStatementClientCode[]>("GetTrustStatementClientCode").then((result) => {
        setTrustStatementClientCode(result?.data);
      }).finally(() => {
        setShowProgressBar(false);
      })
    })()
  }

  const initiateSearch = () => {
    setTrustStatementReportList([]);
    setTriggerFileSearch(!triggerFileSearch);
  };

  const cancelSearch = () => {
    setShowProgressBar(true);
    setSearchFiles("");
    setTrustStatementReportList([]);
    setTrustStatementClientCode([]);
    setSelectedFileType(null);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
  };

  const handleFileType = (event) => {
    setSelectedFileType(event.target.value);
  };

  const HandleFilter = () => {
    GetTrustStatementReportFile();
  }

  const handleClearFilter = () => {
    setSelectedStartDate(false);
    setSelectedEndDate(false);
    setStartDate(null);
    setEndDate(null);
    setSelectedFileType(null);
    setShowProgressBar(true);
    setTrustStatementClientCode([]);
    (async () => {
      let request = {
        startDate: null,
        endDate: null,
        subFolderId: null,
        fileStatus: null,
        fileName: null,
      }
      await usePost<{ trustStatementReportResult: ITrustStatementReportFile[], totalCount: number }>("GetTrustStatementReportFiles", request).then((result) => {
        setTrustStatementReportList(result?.data["trustStatementReportResult"]);
        setTotalCount(result?.data["totalCount"]);
      }).finally(() => {
        setShowProgressBar(false);
      })
    })();
  }

  const handleClientSelect = (event, selected) => {
    setselectedClientCodeId(selected?.clientCodeId);
  }

  return (
    <div className={classes.div}>
      <Backdrop className={classes.backdrop} open={ProgressBar}>
        <PuffLoader size={80} color={"white"} speedMultiplier={1} />
      </Backdrop>
      <Grid container spacing={0}>
        <Grid item xs={3}>
          <Typography variant="h6" textAlign={'left'} gutterBottom className={` ${"headertitle"}`} p={1}>
            <b>HISTORIC TRUST STATEMENTS</b>
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <TextField value={searchFiles} variant="outlined"
            type='text' placeholder="Search files here..."
            className={`${classes.searchClientFiles}`}
            onChange={e => setSearchFiles(e.target.value.trim())}
            onKeyPress={event => {
              if (event.key === 'Enter' && searchFiles != "") {
                initiateSearch(); setShowSearchFileIcon(false);
              } else if (event.key === 'Enter' && searchFiles === "") {
                initiateSearch(); setShowSearchFileIcon(true);
              }
            }}
            style={{ marginBottom: '5px' }}
            inputProps={{ maxlength: 255 }}
            InputProps={{
              classes: {
                root: classes.ftpoutlinedInput1,
                notchedOutline: classes.ftpnotchedOutline,
              },
              endAdornment: (
                <InputAdornment position="end">
                  {showSearchFileIcon === true ?
                    <IconButton onClick={e => { initiateSearch(); setShowSearchFileIcon(false); }} style={{ padding: 2 }}>
                      <SearchIcon fontSize={'small'} />
                    </IconButton>
                    :
                    <IconButton onClick={e => { cancelSearch(); setShowSearchFileIcon(true); }} style={{ padding: 2 }}>
                      <CloseIcon fontSize={'small'} />
                    </IconButton>
                  }
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={1} >
          <Button variant='contained' color="primary" size="small" component={Link} to="/report"
            startIcon={<DoubleArrowIcon style={{ transform: 'rotate(180deg)' }} />} className={classes.backButton}>
            Back
          </Button>
        </Grid>
        <Grid item xs={12} >
          <Grid container spacing={0} className={classes.maingrid}>
            <Grid item xs={2.6} padding={0.5}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  label="Start Date"
                  variant="inline"
                  size="small"
                  inputVariant="outlined"
                  format={DateFormat}
                  disableToolbar
                  autoOk={true}
                  margin="normal"
                  id="NC_StartDate"
                  value={StartDate}
                  className={classes.dateStyle}
                  onChange={handleStartDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  disableFuture={true}
                // PopoverProps={{ classes: popoverClasses }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={2.6} padding={0.5}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  label="End Date"
                  variant="inline"
                  size="small"
                  inputVariant="outlined"
                  format={DateFormat}
                  disableToolbar
                  autoOk={true}
                  margin="normal"
                  className={classes.dateStyle}
                  id="NC_StartDate"
                  value={EndDate}
                  onChange={handleEndDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  disableFuture={true}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={3} padding={"5px 7px"}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={trustStamentClientCode}
                getOptionLabel={(option) => option?.clientCode}
                getOptionSelected={(option, value) => option?.clientCodeId == value?.clientCodeId}
                fullWidth style={{ marginTop: "8px" }}
                onChange={(e, selected) => { handleClientSelect(e, selected); }}
                renderOption={(option) => (
                  <React.Fragment>
                    <Typography variant="body2" color={option?.statusCode == 8 ? "green" : "error"}>
                      {option?.clientCode}
                    </Typography>
                  </React.Fragment>
                )}
                size='small'
                renderInput={(params) => <TextField {...params} label="Client Code" variant='outlined' />}
              />
            </Grid>
            <Grid item xs={3} padding={0.5}>
              <FormControl variant="outlined" size='small' fullWidth style={{ marginTop: '10px' }}>
                <InputLabel htmlFor="outlined-age-native-simple">Select File Type</InputLabel>
                <Select
                  native
                  label="Select File Type"
                  value={selectedFileType}
                  onChange={handleFileType}
                  inputProps={{
                    name: 'age',
                    id: 'outlined-age-native-simple',
                    classes: {
                      root: classes.smallFontSize,
                    },
                  }}

                >
                  <option value={"null"}>--Select File Type--</option>
                  <option value={8}>Success</option>
                  <option value={9}>Failed</option>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={0.8} padding={0.5} display={'flex'} justifyContent={'space-evenly'} >
              <IconButton onClick={HandleFilter}>
                <TooltipSmall title="filter">
                  <FilterListIcon color='primary' fontSize='medium' />
                </TooltipSmall>
              </IconButton>
              <IconButton onClick={handleClearFilter}>
                <TooltipSmall title="filter">
                  <FilterListOffIcon color='error' fontSize='medium' />
                </TooltipSmall>
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <TableContainer sx={{ borderRight: '1px lightgray solid ' }} className={`${classes.tableContainer} ${"scrollbox"} ${"on-scrollbar"}`}>
                <Table aria-label="customized table" size="small" stickyHeader>
                  <EnhancedTableHead classes={classes} />
                  <TableBody>
                    {trustStatementReportList?.map((r) => (
                      <StyledTableRow key={r?.fileId}>
                        <StyledTableCell >
                          <Typography variant='body2' className={classes.textStyle} >
                            {moment(r?.createdDate).format(state.GlobalUtils?.settingValue)}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Typography variant='body2' className={classes.textStyle} >
                            {moment(r?.createdDate).format('HH:mm:ss')}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Typography variant='body2' className={classes.textStyle} >
                            {r?.clientCode}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Typography variant='body2' className={classes.textStyle} >
                            {r?.fileName}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Typography variant='body2' className={classes.textStyle} >
                            {r?.fileSize}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Chip label={r?.status == 8 ? "Success" : "Failed"} color={r?.status == 8 ? "success" : "error"}
                            variant="filled" size='small' />
                        </StyledTableCell>
                        <StyledTableCell>
                          <TrustStamenetReportPDFPreview blobUri={r?.blobUri} enable={r?.fileName?.split('.')?.pop()?.toUpperCase() === 'PDF'} />
                        </StyledTableCell>
                        <StyledTableCell>
                          <TrustStatementHistory row={r} />
                          {/* <HistoryIcon color='secondary' fontSize='medium' /> */}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12} borderTop={'1px solid lightgray'} borderRight={'1px solid lightgray'}>
              <Grid container spacing={0}>
                <Box m={0} width="60%">
                  <div className="footer">
                    <Footer />
                  </div>
                </Box>
                <Box m={0} width="40%">
                  <TablePagination
                    id="Files_TablePagination"
                    rowsPerPageOptions={[20, 50, 100]}
                    component="div"
                    count={totalCount === undefined ? 0 : totalCount}
                    rowsPerPage={rowsPerPage}
                    // style={{ borderTop: '1px solid lightgray' }}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          {/* Future use */}
          {/* <Grid item xs={4}>
              <TableContainer sx={{ borderTop: '1px lightgray solid ' }}>
                <Carousel autoPlay showThumbs={false} showStatus={false} showArrows={false} infiniteLoop >
                  <FileStatusPieChart />
                  <ClientCodeBasedChart dates={data} />
                  <FilePlacedChart />
                </Carousel>
              </TableContainer>
            </Grid> */}
        </Grid>
      </Grid>
    </div >
  )
}

export default TrustStatementReport

/* Future Use */
/* const data = [
  { x: new Date('01-02-2023').toISOString(), y: 60 },
  { x: new Date('02-02-2023').toISOString(), y: 80 },
  { x: new Date('05-03-2023').toISOString(), y: 100 },
  { x: new Date('03-03-2023').toISOString(), y: 120 },
  { x: new Date('06-04-2023').toISOString(), y: 140 },
  { x: new Date('04-04-2023').toISOString(), y: 160 },
  { x: new Date('02-05-2023').toISOString(), y: 180 },
  { x: new Date('03-05-2023').toISOString(), y: 200 },
  // Add more data points as needed
]; */