import CancelIcon from '@material-ui/icons/Cancel';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import {
    Autocomplete, Box, Button,
    Drawer, Grid, IconButton, Paper, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow, TextField, Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { emailRegex } from "../../../constants/Constant";
import norecord from '../../../images/trustStatement/noFiles.svg';
import { ITrustStamentValidEmailAddress } from '../../../models/NotificationCenter/TrustStatementClientCodeResult';
import { IClientInfo } from '../../../models/client/IClientInfo';
import { useGlobalState } from '../../../store/GlobalStore';
import { usePost } from '../../../utils/apiHelper';
import { useStylesTrustReport } from '../SettingsTrustReportShare/TrustReportShareCss';

interface IShareGroupEmailContract {
    emailId: string;
    tsClientCodeDetails: IClientInfo[];
}

interface IShareGroupEmailContractList {
    InsertGroupEmailShareList: IShareGroupEmailContract[];
}

const TrustReportEmailShare = () => {
    const classes = useStylesTrustReport();
    const { state } = useGlobalState();
    const [open, setOpen] = useState(false);
    const [ProgressBar, setShowProgressBar] = useState(false);
    const [reportShared, setReportShared] = useState(false);
    const [emailAddress, setEmailAddress] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [emailExist, setEmailExist] = useState(false);
    const [validEmailAddress, setValidEmailAddress] = useState<ITrustStamentValidEmailAddress[]>([]);
    const [ClientCodeOpen, setClientCodeOpen] = useState(false);
    const [clientCodeList, setClientCodeList] = useState<IClientInfo[]>([]);
    const [entries, setEntries] = useState<IShareGroupEmailContract[]>([]);
    const [currentClientCodes, setCurrentClientCodes] = useState<IClientInfo[]>([]);
    const [editIndex, setEditIndex] = useState<number | null>(null);
    const [editClientCodes, setEditClientCodes] = useState<IClientInfo[]>([]);

    const handleClientCodeChange = (event: React.SyntheticEvent<Element, Event>, value: IClientInfo[]) => {
        setCurrentClientCodes(value);
    };

    const addEntry = () => {
        const newEntry: IShareGroupEmailContract = { emailId: emailAddress, tsClientCodeDetails: currentClientCodes };
        setEntries([...entries, newEntry]);
        setEmailAddress('');
        setCurrentClientCodes([]);
    };

    const handleEditClientCodeChange = (event: React.SyntheticEvent<Element, Event>, value: IClientInfo[]) => {
        setEditClientCodes(value);
    };

    const saveEdit = () => {
        if (editIndex !== null) {
            const updatedEntries = [...entries];
            updatedEntries[editIndex].tsClientCodeDetails = editClientCodes;
            setEntries(updatedEntries);
            setEditIndex(null);
            setEditClientCodes([]);
        }
    };

    const handleEdit = (index: number) => {
        setEditIndex(index);
        setEditClientCodes(entries[index].tsClientCodeDetails);
    };

    const handleDeleteRow = (index: number) => {
        const updatedEntries = entries.filter((_, i) => i !== index);
        setEntries(updatedEntries);
    };

    const toggleDrawer = (newOpen: boolean) => () => {
        setOpen(newOpen);
    };

    const handleClickClose = () => {
        setOpen(false);
        setValidEmailAddress([]);
        setEmailAddress('');
        setErrorMessage('');
        setEmailExist(false);
        setIsValidEmail(false);
        setCurrentClientCodes([]);
    };

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    useEffect(() => {
        handleClientCodeList();
    }, [])

    const handleSubmit = () => {
        setShowProgressBar(true);
        const output: IShareGroupEmailContractList = { InsertGroupEmailShareList: entries };
        console.log(JSON.stringify(output, null, 2));
        (async () => {
            let request = {
                "adminEmailAddress": state?.userAccessContext?.userName,
                "insertGroupEmailShareList": entries
            }
            await usePost("TrustStatementReportShare", request).then((r) => {
            }).finally(() => {
                setReportShared(true);
                setOpen(false);
                setShowProgressBar(false);
                setValidEmailAddress([]);
                setEmailAddress('');
                setErrorMessage('');
                setEntries([]);
            });
        })()
    };

    const handleValidateEmail = (e) => {
        const email = e.target.value;
        if (!email) {
            setErrorMessage('');
            setEmailExist(false);
            setIsValidEmail(false);
            return;
        }
        if (email.match(emailRegex)) {
            setErrorMessage('');
            setEmailExist(false);
            setIsValidEmail(true);
        } else {
            setErrorMessage("Please enter valid email address");
            setEmailExist(true);
            setIsValidEmail(false);
        }
    };

    const handleEmailChange = (event) => {
        const email = event?.target?.value;
        setEmailAddress(email);
        setErrorMessage('');
        setIsValidEmail(false);
    };

    const handleCheckEmailExist = () => {
        if (entries?.some(e => e.emailId === emailAddress)) {
            setErrorMessage(`Error: '${emailAddress}' is already ready to be shared!`);
            setIsValidEmail(false);
            return;
        }
    }

    const handleClientCodeList = () => {
        (async () => {
            let request = {
                "userId": state?.userAccessContext?.id,
                "client_code": "",
                "isAdmin": false
            }
            await usePost<IClientInfo[]>("Client/searchClientCode", request).then((r) => {
                setClientCodeList(r?.data);
            }).finally(() => {
                setShowProgressBar(false);
            });
        })()
    }

    return (
        <React.Fragment>
            <Button variant='contained' color='primary' size='small' onClick={toggleDrawer(true)}
                startIcon={<ShareOutlinedIcon />} className={classes.backButton}>
                SHARE TRUST STATEMENT 
            </Button>

            <Drawer open={open} anchor='right' onClose={() => { }}>
                <Grid container spacing={0.5} padding={2} width={'1000px'}>
                    <Grid item xs={12}>
                        <Typography variant='body1' textAlign={'center'} fontWeight={600} color={'#007FFF'}>
                            SHARE EMAIL REPORT
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="CC" label="From Email Address"
                            value={"clientservices@armsolutions.com"}
                            autoComplete="off" required
                            size="small" fullWidth disabled
                            variant="outlined" style={{ marginTop: "15px" }}
                            InputLabelProps={{ shrink: true, }}
                            inputProps={{ maxLength: 255 }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            fullWidth id="Email" required
                            variant="outlined" autoComplete="on"
                            label="Enter Email Address"
                            placeholder="example@gmail.com"
                            sx={{ marginTop: "16px" }}
                            value={emailAddress} size='small'
                            onChange={(e) => { handleEmailChange(e); handleValidateEmail(e); }}
                            onBlur={handleCheckEmailExist}
                            helperText={errorMessage}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 255 }}
                            error={Boolean(errorMessage)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Autocomplete
                            multiple size='small'
                            disableClearable limitTags={4}
                            disableCloseOnSelect
                            autoComplete={false}
                            sx={{ marginTop: "16px" }}
                            options={clientCodeList}
                            value={currentClientCodes}
                            onChange={(e, selected) => handleClientCodeChange(e, selected)}
                            getOptionLabel={(option) => option.client_Code}
                            isOptionEqualToValue={(option, value) => option.client_Code === value.client_Code}
                            renderOption={(props, option) => (
                                <li {...props}>
                                    <Typography variant='body2' color={props['data-selected'] ? 'green' : 'black'}>
                                        {option.client_Code}
                                    </Typography>
                                </li>
                            )}
                            ListboxProps={{
                                sx: {
                                    maxHeight: '160px'
                                }
                            }}
                            renderInput={(params) => (
                                <TextField {...params} label="Client Code List" variant="outlined" fullWidth />
                            )}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Button variant="contained" color="primary" sx={{ marginTop: "17px" }} disabled={!emailAddress || currentClientCodes?.length === 0 || !isValidEmail}
                            onClick={addEntry} fullWidth>
                            Add
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer component={Paper} sx={{ mt: 1 }} className={`${classes.shareEmailContainer} ${"scrollbox"} ${"on-scrollbar"}`}>
                            <Table stickyHeader size="small" aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.TableCellHeadStyle}>EMAIL ADDRESS</TableCell>
                                        <TableCell className={classes.TableCellHeadStyle}>MAPPED CLIENT CODE</TableCell>
                                        <TableCell className={classes.TableCellHeadStyle}>ACTIONS</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {entries.map((entry, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{entry.emailId}</TableCell>
                                            <TableCell>
                                                {editIndex === index ? (
                                                    <Autocomplete
                                                        multiple
                                                        size="small"
                                                        open={ClientCodeOpen}
                                                        onOpen={() => setClientCodeOpen(true)}
                                                        onClose={() => setClientCodeOpen(false)}
                                                        disableCloseOnSelect
                                                        openOnFocus={true}
                                                        selectOnFocus={true}
                                                        options={clientCodeList}
                                                        value={editClientCodes}
                                                        onChange={handleEditClientCodeChange}
                                                        getOptionLabel={(option) => option.client_Code}
                                                        isOptionEqualToValue={(option, value) => option.client_Code === value.client_Code}
                                                        onBlur={saveEdit}
                                                        renderInput={(params) => (
                                                            <TextField {...params} label="Client Code List" variant="outlined" fullWidth />
                                                        )}
                                                    />
                                                ) : (
                                                    entry.tsClientCodeDetails.map(client => client.client_Code).join(', ')
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                {editIndex === index ? (
                                                    <IconButton size='small' disabled >
                                                        <ModeEditOutlinedIcon color='disabled' fontSize='small' />
                                                    </IconButton>
                                                ) : (
                                                    <IconButton onClick={() => handleEdit(index)} size='small'>
                                                        <ModeEditOutlinedIcon color='success' fontSize='small' />
                                                    </IconButton>
                                                )}
                                                <IconButton onClick={() => handleDeleteRow(index)} size='small'>
                                                    <DeleteOutlineIcon color='error' fontSize='small' />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            {!entries?.length ?
                                <Box m={0} p={0} mt={7} mb={5} display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'}>
                                    <img alt="noRecords" src={norecord} width="80px" style={{ textAlign: 'center' }} />
                                    <Typography variant='body2' align='center' color='red' mt={1} mb={1}>
                                        <b>No email to display</b>
                                    </Typography>
                                </Box>
                                : null}
                        </TableContainer>
                    </Grid>
                    <Grid item xs={6} >
                        <Button variant="contained" color='success' fullWidth startIcon={<DoneAllIcon />} sx={{ mt: 1 }}
                            disabled={!entries?.length || emailAddress.length > 0 || !clientCodeList} onClick={handleSubmit} >
                            Submit
                        </Button>
                    </Grid>
                    <Grid item xs={6} >
                        <Button id="DU_Cancel_btn" variant="contained" sx={{ mt: 1 }}
                            startIcon={<CancelIcon />} fullWidth onClick={handleClickClose} color="error" >
                            CLOSE
                        </Button>
                    </Grid>
                </Grid>
            </Drawer>
        </React.Fragment >
    )
}

export default TrustReportEmailShare