import { Backdrop, Box, Button, Dialog, DialogContent, Grid, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Typography } from '@mui/material';
import moment from "moment";
import { useEffect, useState } from 'react';
import { PuffLoader } from "react-spinners";
import { IClientCodeContract, IClientCodeDetails, IGetKeyValues } from "../../../models/GLobalSettings/IGetKeyValueDetails";
import { useGlobalState } from "../../../store/GlobalStore";
import { useFetch } from '../../../utils/apiHelper';
import { maskGuid } from "../../../utils/dataMasking";
import { Transition } from "../../GlobalStyles/DialogBoxTransition";
import { DialogTitleHeader } from "../../GlobalStyles/DialogStyle";
import StyledTableRow from "../../GlobalStyles/StyledTableRow";
import CreateKeyManagement from './CreateKeyManagement';
import DisableAPIKey from "./DisableAPIKey";
import EditKeyDetails from './EditKeyDetails';
import { useStyles } from './ManagementCSS';
import ManageAPIConfiguration from "./ManageRule/APIDetails/ManageAPIConfiguration";
import ManagePlacementFields from "./ManageRule/PlacementFields/ManagePlacementFields";
import ManageRecordType from "./ManageRule/RecordType/ManageRecordType";
import ViewAPIKeyHistory from './ViewAPIKeyHistory';
import ManageClientAPIRecordType from "./ManageRule/RecordType/ManageClientAPIRecordType";

function EnhancedTableHead(props) {
  const { classes } = props;

  const headCells = [
    { id: "label_Name", numeric: false, disablePadding: false, label: "KEY NAME", sortable: true },
    { id: "control_Name", numeric: false, disablePadding: false, label: "CLIENT NAME", sortable: true },
    { id: "viewClientCodes", numeric: false, disablePadding: false, label: "VIEW CLIENT CODES", sortable: true },
    { id: "date_field", numeric: false, disablePadding: false, label: "MODIFIED DATE", sortable: true },
    { id: "startDate", numeric: false, disablePadding: false, label: "START DATE", sortable: true },
    { id: "expiryDate", numeric: false, disablePadding: false, label: "EXPIRY DATE", sortable: true },
    { id: "history", numeric: false, disablePadding: false, label: "HISTORY", sortable: true },
    { id: "configuration", numeric: false, disablePadding: false, label: "CONFIGURATIONS", sortable: true },
    { id: "actions", numeric: false, disablePadding: false, label: "ACTIONS", sortable: true },
    { id: "disable", numeric: false, disablePadding: false, label: "", sortable: false },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id} id="CPO_TableHeader"
            className={classes.TableCellStyle}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function EnhancedTable(props) {
  const { classes } = props;

  const headCells = [
    { id: "clientCode", numeric: false, disablePadding: false, label: "CLIENT CODE", sortable: true },
    { id: "clientName", numeric: false, disablePadding: false, label: "CLIENT NAME", sortable: true },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell key={headCell.id} id="CPO_TableHeader" className={classes.TableCellStyle} >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const ViewKeyManagement = () => {
  const classes = useStyles();
  const { state } = useGlobalState();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [progressBar, setProgressBar] = useState(false);
  const [getKeyValues, setGetKeyValues] = useState<IGetKeyValues[]>([]);
  const [ClientCodeList, setClientCodeList] = useState<IClientCodeDetails[]>([]);
  const [existingClientCodeList, setExistingClientCodeList] = useState<IClientCodeContract[]>([]);

  useEffect(() => {
    getAllAPIKeyValues();
    handleGetExistingClientCodeList();
  }, [])

  const handleGetExistingClientCodeList = () => {
    setProgressBar(true);
    (async () => {
      await useFetch<IClientCodeContract[]>("GlobalSettings/GetExistingClientCodeList").then((result) => {
        setExistingClientCodeList(result.data);
      }).finally(() => {
        setProgressBar(false);
      });
    })();
  }

  const getAllAPIKeyValues = () => {
    setProgressBar(true);
    handleGetExistingClientCodeList();
    useFetch<IGetKeyValues[]>('GlobalSettings/GetKeyVaultDetails').then((result) => {
      setGetKeyValues(result?.data);
    }).finally(() => {
      setProgressBar(false);
    })
  }

  const handleCloseDialogBox = () => {
    setDialogOpen(false);
  }

  const handleClientDialogOpen = (id: number) => {
    setDialogOpen(true);
    setProgressBar(true);
    (async () => {
      await useFetch<IClientCodeDetails[]>("GlobalSettings/GetClientCodeList?ApiKeyId=" + id).then((r) => {
        setClientCodeList(r.data);
      }).finally(() => {
        setProgressBar(false);
      });
    })()
  }

  return (
    <div className={classes.divRoot}>
      <Backdrop className={classes.backdrop} open={progressBar}>
        <PuffLoader size={80} color={"white"} speedMultiplier={1} />
      </Backdrop>
      <Grid container spacing={0} >
        <Grid item xs={6} sm={8}>
          <Typography variant="h6" style={{ textAlign: "left" }} className={`${classes.UserListStyle} ${"headertitle"}`}>
            <b>API KEY MANAGEMENT</b>
          </Typography>
        </Grid>
        <Grid item xs={6} sm={4}>
          <Grid container spacing={0}>
            <Grid item xs={8} >
              <ManageRecordType />
            </Grid>
            <Grid item xs={4}>
              <CreateKeyManagement clientList={existingClientCodeList} onSave={getAllAPIKeyValues} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} sm={12}>
          <TableContainer component={Paper} className={`${classes.tablebodyActivity} ${"scrollbox"} ${"on-scrollbar"}`}>
            <Table aria-label="customized table" size="small" stickyHeader>
              <EnhancedTableHead classes={classes} />
              <TableBody>
                {getKeyValues?.map((row) => (
                  <StyledTableRow key={row?.clientKey}>
                    <TableCell align="left" className={!row?.isDisable ? classes.disableText : classes.TableCellStyle1}>
                      {maskGuid(row?.clientKey)}
                    </TableCell>
                    <TableCell align="left" className={!row?.isDisable ? classes.disableText : classes.TableCellStyle1}>
                      {row?.clientName} &nbsp;&nbsp;
                    </TableCell>
                    <TableCell align="left" className={!row?.isDisable ? classes.disableText : classes.TableCellStyle1}>
                      {<Link style={{ cursor: 'pointer' }} onClick={(e) => { handleClientDialogOpen(row?.apiKeyId) }}> View Client Code </Link>}
                    </TableCell>
                    <TableCell align="left" className={!row?.isDisable ? classes.disableText : classes.TableCellStyle1}>
                      {moment(row?.modifiedDate)?.format(state?.GlobalUtils?.settingValue)}
                    </TableCell>
                    <TableCell align="left" className={!row?.isDisable ? classes.disableText : classes.TableCellStyle1}>
                      {moment(row?.startDate)?.format(state?.GlobalUtils?.settingValue)}
                    </TableCell>
                    <TableCell align="left" className={!row?.isDisable ? classes.disableText : classes.TableCellStyle1}>
                      {moment(row?.expiryDate)?.format(state?.GlobalUtils?.settingValue)}
                    </TableCell>
                    <TableCell align="left" className={classes.TableCellStyle1}>
                      <ViewAPIKeyHistory apiKeyId={row?.apiKeyId} />
                    </TableCell>
                    <TableCell align="left" className={classes.TableCellStyle1} style={{ display: 'flex' }}>
                      <ManageAPIConfiguration apiKeyId={row?.apiKeyId} />
                      <ManagePlacementFields apiKeyId={row?.apiKeyId} />
                      <ManageClientAPIRecordType apiKeyId={row?.apiKeyId} />
                    </TableCell>
                    <TableCell align="left" className={classes.TableCellStyle1} >
                      <EditKeyDetails row={row} onSave={getAllAPIKeyValues} clientList={existingClientCodeList} />
                    </TableCell>
                    <TableCell align="left" className={classes.TableCellStyle1}>
                      <DisableAPIKey row={row} onFetchDetails={getAllAPIKeyValues} />
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
            {!getKeyValues?.length && !progressBar ? <Typography variant="h6" gutterBottom className={classes.noRecordStyle}>
              No records to display...
            </Typography> : null}
          </TableContainer>
        </Grid>
      </Grid>

      <Dialog TransitionComponent={Transition} open={dialogOpen}
        className={classes.dialogboxOverride}
        PaperProps={{ style: { borderRadius: 15 } }}
        maxWidth={'sm'}
        aria-labelledby="alert-dialog-slide-title" aria-describedby="alert-dialog-slide-description">
        <DialogTitleHeader id="responsive-dialog-title" onClose={handleCloseDialogBox}>
          <Typography variant="h6" gutterBottom className={classes.titleheader}>
            CLIENT CODE LIST
          </Typography>
        </DialogTitleHeader>
        <DialogContent >
          <TableContainer component={Paper} className={`${classes.tablebody} ${"scrollbox"} ${"on-scrollbar"}`}>
            <Table aria-label="customized table" size="small" stickyHeader>
              <EnhancedTable classes={classes} />
              <TableBody>
                {ClientCodeList?.map((row) => (
                  <StyledTableRow >
                    <TableCell align="left" className={classes.TableCellStyle1}>
                      {row?.clientCode}
                    </TableCell>
                    <TableCell align="left" className={classes.TableCellStyle1}>
                      {row?.clientName}
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
            {!ClientCodeList?.length ? <Typography variant="h6" gutterBottom className={classes.noRecordStyle}>
              No records to display...
            </Typography> : null}
          </TableContainer>

        </DialogContent>
        <Box display="flex" alignItems="right" justifyContent="right" className={classes.dialogaction}>
          <Button id="IM_ok_btn" size="small" startIcon={<CheckCircleIcon />} variant="contained" className={classes.yesButton} onClick={() => { setDialogOpen(false); }}>
            Cancel
          </Button>
        </Box>
      </Dialog>
    </div>
  )
}

export default ViewKeyManagement
