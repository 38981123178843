import { Paper } from '@material-ui/core';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Button, Grid, Table, TableBody, TableContainer, TableHead, Typography } from '@mui/material';
import React from 'react';
import { IAgentDebtor } from '../../../models/AgentInquiry/IAgentDebtor';
import { IAgentInquiryHistoryNotesResult, ITransactionHistory } from '../../../models/Inquiry/TransactionHistory/ITransactionHistory';
import { StyledTableCellAgentInquiry, StyledTableRowAgentInquiry } from '../../GlobalStyles/TableStyledCell';
import AccountHistoryFullScreen from './AccountHistoryFullScreen';
import { useStyles } from './AgentActivityCss';
import moment from 'moment';
import { useGlobalState } from '../../../store/GlobalStore';

const AccountHistory: React.FC<{ debtors: IAgentDebtor, histories: ITransactionHistory[], mapped: boolean, AgentInquiryHistoryNotesResult: IAgentInquiryHistoryNotesResult[], Campaign: string }> = (props) => {
    const classes = useStyles();
    const { state } = useGlobalState();
    const { AgentInquiryHistoryNotesResult, Campaign } = props;

    return (
        <React.Fragment >
            <Grid container spacing={0} mb={3} >
                <Grid item xs={4}>
                    <Typography variant='body2' fontWeight={600} color={'#007FFF'} mt={2.2} ml={"5px"}>
                        <b>ACCOUNT HISTORY - {props.debtors.dbT_NO} </b>
                    </Typography>
                </Grid>
                <Grid item xs={7.6} display={'flex'} justifyContent={'flex-end'} gap={'10px'}>
                    <Button variant='text' size='small' startIcon={<InfoOutlinedIcon />} sx={{ fontSize: '13px', mt: '10px' }}>
                        <b>Client note to Collector : {props.debtors?.clT_NAME_1}</b>
                    </Button>

                    <Button variant='text' size='small' startIcon={<HeadsetMicIcon />} sx={{ fontSize: '13px', mt: '10px' }}>
                        <b>{Campaign}</b>
                    </Button>
                </Grid>
                <Grid item xs={0.4} >
                    <AccountHistoryFullScreen accountNumber={props?.debtors?.dbT_NO} clientName1={props?.debtors?.clT_NAME_1} AgentInquiryHistoryNotesResult={AgentInquiryHistoryNotesResult} Campaign={Campaign} />
                </Grid>
                <Grid item xs={12}>
                    <TableContainer component={Paper} className={`${classes.accountHistoryContainer} ${"scrollbox"} ${"on-scrollbar"}`} sx={{ mt: "5px", borderRadius: '5px' }}>
                        <Table aria-label="caption table" stickyHeader>
                            <TableHead className="sticky-header-agent">
                                <StyledTableRowAgentInquiry className="sticky-row-agent">
                                    <StyledTableCellAgentInquiry align='center' rowSpan={2} >Account</StyledTableCellAgentInquiry>
                                    <StyledTableCellAgentInquiry align="center" rowSpan={2} >Date</StyledTableCellAgentInquiry>
                                    <StyledTableCellAgentInquiry align="center" rowSpan={2} >Time</StyledTableCellAgentInquiry>
                                    <StyledTableCellAgentInquiry align="center" rowSpan={2} >Day of Week</StyledTableCellAgentInquiry>
                                    <StyledTableCellAgentInquiry align="center" rowSpan={2} >User</StyledTableCellAgentInquiry>
                                    <StyledTableCellAgentInquiry align="center" rowSpan={2} >Account History Notes</StyledTableCellAgentInquiry>
                                    <StyledTableCellAgentInquiry align="center" rowSpan={2} >ACCOUNT FOLLOW UP NEXT WORK DATE</StyledTableCellAgentInquiry>
                                    <StyledTableCellAgentInquiry align="center" colSpan={3} >Letters</StyledTableCellAgentInquiry>
                                    <StyledTableCellAgentInquiry align="center" colSpan={3} >Dispositions</StyledTableCellAgentInquiry>
                                </StyledTableRowAgentInquiry>
                                <StyledTableRowAgentInquiry className="sticky-row-agent">
                                    <StyledTableCellAgentInquiry align="center" >Series</StyledTableCellAgentInquiry>
                                    <StyledTableCellAgentInquiry align="center" >Next</StyledTableCellAgentInquiry>
                                    <StyledTableCellAgentInquiry align="center" >Letter #</StyledTableCellAgentInquiry>
                                    <StyledTableCellAgentInquiry align="center" >Desk</StyledTableCellAgentInquiry>
                                    <StyledTableCellAgentInquiry align="center" >Status</StyledTableCellAgentInquiry>
                                    <StyledTableCellAgentInquiry align="center" >History Type</StyledTableCellAgentInquiry>
                                </StyledTableRowAgentInquiry>
                            </TableHead>
                            <TableBody>
                                {AgentInquiryHistoryNotesResult?.map((r) => (
                                    <StyledTableRowAgentInquiry className="styled-table-row-agent">
                                        <StyledTableCellAgentInquiry align='center'>{r?.debtorId}</StyledTableCellAgentInquiry>
                                        <StyledTableCellAgentInquiry align='center'>{r?.hst_date ? moment(r.hst_date).format(state.GlobalUtils?.settingValue) : '-'}</StyledTableCellAgentInquiry>
                                        <StyledTableCellAgentInquiry align='center'> {r?.hsT_TIME.length === 4 ? `${r.hsT_TIME.slice(0, 2)}:${r.hsT_TIME.slice(2)}` : r?.hsT_TIME}</StyledTableCellAgentInquiry>
                                        <StyledTableCellAgentInquiry align='center'>{r?.dayOfTheWeek ? r?.dayOfTheWeek : '-'}</StyledTableCellAgentInquiry>
                                        <StyledTableCellAgentInquiry align='center'>{r?.hsT_USR_CODE ? r?.hsT_USR_CODE : '-'}</StyledTableCellAgentInquiry>
                                        <StyledTableCellAgentInquiry align='left'>{r?.hsT_NOTE ? r?.hsT_NOTE : <Typography variant='body2' textAlign={'center'}>-</Typography>}</StyledTableCellAgentInquiry>
                                        <StyledTableCellAgentInquiry align='center'>{r?.hsT_NEXT_WORK_DATE ? moment(r.hsT_NEXT_WORK_DATE).format(state.GlobalUtils?.settingValue) : '-'}</StyledTableCellAgentInquiry>
                                        <StyledTableCellAgentInquiry align='center'>{r?.hsT_LSE_CODE ? r?.hsT_LSE_CODE : '-'}</StyledTableCellAgentInquiry>
                                        <StyledTableCellAgentInquiry align='center'>{r?.hsT_NEXT_LTR_DATE ? moment(r.hsT_NEXT_LTR_DATE).format(state.GlobalUtils?.settingValue) : '-'}</StyledTableCellAgentInquiry>
                                        <StyledTableCellAgentInquiry align='center'>{r?.hsT_LSE_NO ? r?.hsT_LSE_NO : '-'}</StyledTableCellAgentInquiry>
                                        <StyledTableCellAgentInquiry align='center'>{r?.hsT_DSK_CODE ? r?.hsT_DSK_CODE : '-'}</StyledTableCellAgentInquiry>
                                        <StyledTableCellAgentInquiry align='center'>{r?.dbT_STATUS ? r?.dbT_STATUS : '-'}</StyledTableCellAgentInquiry>
                                        <StyledTableCellAgentInquiry align='left'>{r?.histType ? r?.histType : '-'}</StyledTableCellAgentInquiry>
                                    </StyledTableRowAgentInquiry>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default AccountHistory
