import {
    Button, CircularProgress, Dialog, DialogActions, Grid, FormControlLabel,
    IconButton, makeStyles, TextField, Typography, DialogTitle, FormControl, RadioGroup,
    Radio, Snackbar
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
// Icons
import ReplayIcon from "@material-ui/icons/Replay";
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
// Models
import { useGlobalState } from '../../store/GlobalStore';
import { usePost } from '../../utils/apiHelper';
import { DialogTitleHeader } from '../GlobalStyles/DialogStyle';
import { IPlacedAccountContract } from "../../models/Inquiry/IPlacedAccountContract";
import { Transition } from '../GlobalStyles/DialogBoxTransition';
import { IInsertTransactionLog } from '../../models/UpdateHistory/GetTransactionOptions';
import { InsertTransactionLog } from '../TransactionLogs/InsertTransactionLog';

const useStyles = makeStyles((theme) => ({
    button: {
        borderRadius: 7,
        fontSize: 10,
        fontWeight: 'bold',
    },
    inputField: {
        height: '45px',
        fontSize: 14,
        borderRadius: '4px',
        position: 'relative',
        transition: '0.3s',
        width: "300px",
        boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)'
    },
    description: {
        boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
        width: '85%',
        fontSize: 14,
        marginLeft: '30px'
    },
    titleheader: {
        fontWeight: 'bold',
        marginTop: '5px',
        color: 'white'
    },
    confirmButton: {
        color: "white",
        marginTop: "7px",
        float: 'right',
        borderRadius: 20,
        fontSize: 12
    },
    cancelButton: {
        background: "red",
        color: "white",
        marginRight: "25px",
        marginTop: "7px",
        fontSize: 12,
        borderRadius: 20,
        '&:hover': {
            background: "red",
            color: "white",
        }
    },
    formcontrol: {
        marginTop: "0.3cm"
    },
    formcontrol1: {
        marginTop: "0.2cm"
    },
    formcontrol2: {
        marginLeft: '1.4cm',
        marginTop: "0.2cm"
    },
    circleStyle: {
        color: 'white',
        height: "20px",
        width: "20px",
        marginLeft: '10px'
    },
    dialogePaper: {
        minWidth: "800px"
    }
}))

const CloseAccount: React.FC<{ dbtNumber: string, clientId: string, rolled: boolean, removed: boolean, onSubmit: () => void, dbT_PRINCIPAL_DUE: number, BlockAwaitAccess: boolean, closeReason: string, closedDetails: boolean, onUpdate: () => void, undoStatus: number }> = (props) => {
    const classes = useStyles()
    const { undoStatus } = props;
    const { state } = useGlobalState()
    const [clicked, setClicked] = useState(false);
    const [showUndoButton, setshowUndoButton] = useState(false);
    const [inputVal, setinputVal] = useState<any>('');
    const [CloseNote, setCloseNote] = useState<string>("");
    const [closeAcctBal, setcloseAcctBal] = useState<any>();
    const [closeAcctReason, setValue] = useState('');
    const [open, setOpen] = React.useState(false);
    const canCloseAccount = state.userAccessContext?.closingAccounts;
    const [Removed, setRemoved] = useState(props?.closedDetails);
    const UserName = state.userAccessContext?.id;
    const [principalPaymentAmount, setPrincipalPaymentAmount] = useState<any>(0);
    const [updatedPrincipalAmount, setUpdatedPrincipalAmount] = useState(props.dbT_PRINCIPAL_DUE);
    const [isValid, setIsValid] = useState(true);
    const [isReasonValid, setReasonValid] = useState('');
    const [disable, setDisable] = useState(false);
    const [closeAcc, setCloseAcc] = useState(false);
    const [closeAccUndo, setCloseAccUndo] = useState(false);
    const [principalAmount, setPrincipalAmount] = useState<any>(0);
    const [buttonLoading, setButtonLoading] = useState(false);

    const preventMinus = (e) => {
        if (e.code === 'Minus' || e.code === 'NumpadSubtract') {
            e.preventDefault();
        }
    };

    const handleOnValueChange = (value: string): void => {
        setPrincipalPaymentAmount(value === '0' || '' ? setIsValid(true) : value || '');
        setcloseAcctBal(value === '0' || '' ? setIsValid(true) : value || '');
        setinputVal(value === '0' || '' ? setIsValid(true) : value || '');
    };

    useEffect(() => {
        setUpdatedPrincipalAmount(Number((props.dbT_PRINCIPAL_DUE - closeAcctBal).toFixed(2)));
    }, [props.dbT_PRINCIPAL_DUE, closeAcctBal, principalPaymentAmount])

    useEffect(() => {
        closeAcctReason == 'Paid' ? ((closeAcctBal > props.dbT_PRINCIPAL_DUE) ? setIsValid(false) : setIsValid(true)) : setIsValid(true)
    }, [updatedPrincipalAmount, closeAcctReason])

    const handleReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
        setReasonValid(event.target.value);
    };

    const handleReasonNote = (event) => {
        setCloseNote(event.target.value);
    };

    const handleClose = () => {
        setOpen(false);
        setinputVal('');
        setValue('');
        setReasonValid('');
        setcloseAcctBal('');
        setCloseNote("");
    };

    function emptyCheck() {
        setinputVal('');
        setValue('');
        setReasonValid('');
        setcloseAcctBal('');
    }

    const CloseAccount = () => {
        setButtonLoading(true);
        (async () => {
            let CloseBalance = parseInt(closeAcctBal);
            let request = {
                "dbtNumber": props.dbtNumber,
                "close_acct_note": CloseNote,
                "close_acct_bal": CloseBalance,
                "close_acct_reason": closeAcctReason,
                "user_Id": UserName,
                "isCloseRemove": false,
                "isClosed": true
            };

            if (closeAcctReason == 'Paid') {
                let Principal = ((props.dbT_PRINCIPAL_DUE - CloseBalance).toFixed(2));
                let requestPaid = {
                    "prcpl_Pay_Amt": Principal,
                    "close_acct_note": CloseNote,
                    "dbtNumber": props.dbtNumber,
                    "close_acct_reason": closeAcctReason,
                    "close_acct_bal": CloseBalance,
                    "user_Id": UserName,
                    "isCloseRemove": false,
                    "isClosed": true
                };
                await usePost<IPlacedAccountContract[]>('Account/ClosedPlacedAccounts', requestPaid).finally(() => {
                    setButtonLoading(false);
                });
                setPrincipalAmount(Principal);
                AccountClosed();
                setRemoved(true);
                props.onSubmit();
            }
            else {
                await usePost<IPlacedAccountContract[]>('Account/EmptyPlacedAccounts', request).finally(() => {
                    setButtonLoading(false);
                });
                AccountClosed();
                setRemoved(true);
            }
        })()
    }

    const AccountClosed = () => {
        (async () => {
            props.onUpdate();
            setRemoved(true);
            setshowUndoButton(true);

            if (closeAcctReason == 'Paid') {
                let feedOperation = [];
                let operations: IInsertTransactionLog = {
                    operationName: "Payment", operationText: `Payment to Principal: $${(props.dbT_PRINCIPAL_DUE - closeAcctBal).toFixed(2)}, Closing Note: ${CloseNote}, Closing Balance: $${closeAcctBal}, Closing Reason: ${closeAcctReason}`
                };
                feedOperation.push(operations);
                InsertTransactionLog(state?.userAccessContext?.id, props?.dbtNumber, feedOperation, false);
            } else {
                let feedOperation = [];
                let operations: IInsertTransactionLog = {
                    operationName: "Close Account", operationText: `Closing Note: ${CloseNote}, Closing Balance: $${closeAcctBal}, Closing Reason: ${closeAcctReason}`
                };
                feedOperation.push(operations);
                InsertTransactionLog(state?.userAccessContext?.id, props?.dbtNumber, feedOperation, false);
            }
        })()
    }

    const undoCloseandRemove = () => {
        (async () => {
            let request = {
                "DBTNumber": props.dbtNumber,
                "isCloseRemove": false,
                "isClosed": true
            }
            await usePost("Account/PlacedAccounts", request);
            props.onUpdate();
            setshowUndoButton(false);
            setRemoved(false);
            setDisable(false);
            if (props?.closeReason === "Paid") {
                undoAdjustment(principalAmount);
            }

            let feedOperation = [];
            let operations: IInsertTransactionLog = {
                operationName: "Undo Closed Account", operationText: "Close Account: Cancelled"
            };
            feedOperation.push(operations);
            InsertTransactionLog(state?.userAccessContext?.id, props?.dbtNumber, feedOperation, false);
        })().finally(() => {
            props.onUpdate();
        })
    }

    const undoAdjustment = (adjustmentAmount: number) => {
        (async () => {
            let request = {
                "Dbt_No": props.dbtNumber,
                "AdjustmentAmount": adjustmentAmount,
                "AdjustmentType": "Principal Payment"
            }
            await usePost<any>("Account/UndoAdjustment", request);
            props.onSubmit();
        })().finally(() => {
            props.onSubmit();
        })
    }

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setCloseAcc(false);
        setCloseAccUndo(false);
    };

    return (
        <React.Fragment>
            <Button id="Roll_CloseAccount" variant="contained" onClick={() => { setOpen(true); }} className={classes.button}
                startIcon={<AccountBalanceIcon />} size="large"
                disabled={!canCloseAccount || Removed || props.BlockAwaitAccess || props.closedDetails} color="primary">
                {Removed || props.closedDetails ? "Account Closed" : "Close Account"}
                {buttonLoading === true ? <CircularProgress size="small" className={classes.circleStyle} /> : null}
            </Button>
            {(Removed || props.closedDetails) && (
                <IconButton
                    id="Roll_Undo_CA"
                    color="primary"
                    aria-label="add to shopping cart"
                    size="small"
                    onClick={() => {
                        setClicked(!clicked);
                        undoCloseandRemove();
                        setCloseAccUndo(true);
                    }}
                    disabled={props.BlockAwaitAccess}>
                    <ReplayIcon />
                </IconButton>
            )}
            <Dialog TransitionComponent={Transition} open={open} keepMounted onClose={handleClose}
                classes={{ paper: classes.dialogePaper }}
                PaperProps={{ style: { borderRadius: 15 } }}
                aria-labelledby="alert-dialog-slide-title" aria-describedby="alert-dialog-slide-description">
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                    <Typography variant="h6" gutterBottom className={classes.titleheader}>
                        CLOSE ACCOUNT
                    </Typography>
                </DialogTitleHeader>
                <DialogTitle>
                    {!isValid ?
                        <Alert severity="error">Please enter a payment amount that is less than the amount due.</Alert> : ""}
                </DialogTitle>
                <Grid container spacing={0}>
                    <Grid item xs={7}>
                        <TextField
                            id="Rol_CA_Description"
                            placeholder="Why is the account being Closed?"
                            size="medium"
                            multiline
                            rows={6}
                            value={CloseNote} onChange={handleReasonNote}
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            className={classes.description}
                        />
                    </Grid>
                    <Grid item xs={5} >
                        <form >
                            <CurrencyInput
                                className={classes.inputField}
                                id="Roll_CAR_Balance"
                                placeholder="$ Current Balance Amount in Your System"
                                value={closeAcctBal}
                                prefix='$'
                                decimalsLimit={2}
                                onValueChange={handleOnValueChange}
                                intlConfig={{ locale: 'en-US', currency: 'USD' }}
                                onKeyPress={preventMinus}
                                decimalScale={2}
                                maxLength={13}
                            />
                        </form>
                        <FormControl component="fieldset" className={classes.formcontrol}>
                            <Grid container spacing={0}>
                                <Grid item xs={6}>
                                    <RadioGroup id="Roll_CAR_Reason" aria-label="reason" name="reason" value={closeAcctReason} onChange={handleReasonChange} className={classes.formcontrol1} >
                                        <FormControlLabel value="Paid" control={<Radio size="small" />} label="Paid" />
                                        <FormControlLabel value="Bankruptcy" control={<Radio size="small" />} label="Bankruptcy" />
                                    </RadioGroup>
                                </Grid>
                                <Grid item xs={6}>
                                    <RadioGroup id="Roll_CAR_Reason" aria-label="reason" name="reason" value={closeAcctReason} onChange={handleReasonChange} className={classes.formcontrol2} >
                                        <FormControlLabel value="Disputing" control={<Radio size="small" />} label="Disputing" />
                                        <FormControlLabel value="Other" control={<Radio size="small" />} label="Other" />
                                    </RadioGroup>
                                </Grid>
                            </Grid>
                        </FormControl>
                    </Grid>
                </Grid>
                <DialogActions >
                    <Button id="Roll_CA_Confirm_btn" variant="contained" className={classes.confirmButton} startIcon={<CheckCircleIcon />}
                        style={{ background: disable || !inputVal || !isReasonValid ? "#e0e0e0" : "green", right: "24px", marginTop: "7px" }}
                        size="small" onClick={() => { setCloseAcc(true); setOpen(false); setClicked(!clicked); setshowUndoButton(!showUndoButton); CloseAccount(); emptyCheck(); setValue('') }}
                        disabled={disable || !inputVal || !isReasonValid} >
                        Confirm
                    </Button>
                    <Button id="Roll_CA_Close_btn" size="small" variant="outlined" className={classes.cancelButton} startIcon={<CancelIcon />}
                        onClick={handleClose}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar className="snackBarStyle" open={closeAcc} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={closeSnackbar}>
                <Alert onClose={closeSnackbar} severity="error" className="alertStyle">
                    Account has been Closed Successfully.
                </Alert>
            </Snackbar>
            <Snackbar className="snackBarStyle" open={closeAccUndo} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={closeSnackbar}>
                <Alert onClose={closeSnackbar} severity="success" className="alertStyle">
                    Closed Account has been undone successfully.
                </Alert>
            </Snackbar>
        </React.Fragment >
    )
}

export default CloseAccount