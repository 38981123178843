import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    tableLayout: {
        tableLayout: 'auto',
        marginBottom: "10px"
    },
    container: {
        maxWidth: "100vw",
        maxHeight: 'calc( 100vh - 130px)',
        minHeight: 'calc( 100vh - 130px)',
        alignItems: "center",
        border: '2px solid gray',
        borderRadius: 10,
        backgroundColor: 'white',
        boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
        padding: '5px'
    },
    actions: {
        width: "100%",
        marginRight: theme.spacing(2)
    },
    noPadding: {
        padding: '0px 0px 0px 0px'
    },
    buttons: {
        borderRadius: 20,
        fontSize: 12,
        fontWeight: 'bold',
        background: "#007FFF",
        color: "white",
        marginTop: "10px",
        // marginLeft: '15px',
        width: '85px'
    },
    Nextbtn: {
        borderRadius: 20,
        fontSize: 12,
        fontWeight: 'bold',
        background: "#007FFF",
        color: "white",
        marginTop: "10px",
        // marginLeft: '10px',
        width: '85px'
    },
    Datebtn: {
        borderRadius: 20,
        fontSize: 12,
        fontWeight: 'bold',
        background: "#007FFF",
        color: "white",
        marginTop: "10px",
    },
    inputField: {
        width: '95%',
        height: '40px',
        fontSize: 17,
        borderRadius: '4px',
        transition: '0.3s',
    },
    inputField1: {
        height: '30px',
        fontSize: 14,
        borderRadius: '4px',
        transition: '0.3s',
        width: "60px",
        marginTop: "12px"
    },
    inputField2: {
        width: '62%',
        fontSize: 17,
        borderRadius: '4px',
        transition: '0.3s',
        marginTop: "5px",
    },
    error: {
        color: 'red',
    },
    div: {
        flexGrow: 1,
        margin: "0px 10px",
        paddingTop: '4px',
    },
    grid: {
        padding: '15px'
    },
    smallFont: {
        fontSize: 13,
        textOverflow: 'ellipsis',
        display: "inline-block",
        whiteSpace: "nowrap",
        overflow: 'hidden',
        '&:hover': {
            overflow: 'visible',
            transition: '0.10s',
        }
    },
    smallFont1: {
        fontSize: 14,
        textOverflow: 'ellipsis'
    },
    ignoredRow: {
        textDecoration: 'line-through',
        color: 'red'
    },
    ignoredRowPassive: {
        textDecoration: 'line-through'
    },
    excelCell: {
        fontSize: 12,
    },
    solidBorder: {
        border: '2px outset grey',
        borderRadius: 5,
        padding: 1
    },
    divider: {
        marginTop: '5px',
        marginBottom: '5px'
    },
    container1: {
        maxHeight: 700,
        [theme.breakpoints.up('lg')]: {
            maxHeight: 'calc( 100vh - 318px)',
        },
    },
    container2: {
        maxHeight: 600,
        [theme.breakpoints.up('lg')]: {
            maxHeight: 'calc( 100vh - 345px)',
        },
    },
    container3: {
        maxHeight: 500,
        [theme.breakpoints.up('lg')]: {
            maxHeight: 'calc( 100vh - 440px)',
        },
    },
    titleheader: {
        fontWeight: 'bold',
        marginTop: '5px',
        color: 'white',
        fontSize: 18
    },
    deletetemp: {
        marginTop: "15px",
        color: "black",
        textAlign: "center"
    },
    futureimage: {
        textAlign: "center"
    },
    yesbutton: {
        backgroundColor: 'green',
        borderRadius: '20px',
        color: 'white',
        marginRight: '15px',
        '&:hover': {
            backgroundColor: 'green',
            color: 'white',
        }
    },
    nobutton: {
        background: "red",
        borderRadius: "20px",
        color: 'white',
        marginRight: '15px',
        '&:hover': {
            backgroundColor: 'red',
            color: 'white',
        }
    },
    nobutton1: {
        background: "#f65655",
        borderRadius: "20px",
        width: '120px',
        display: 'flex',
        textAlign: 'center',
        justifyContent: 'center',
        color: 'white',
        '&:hover': {
            backgroundColor: '#f65655',
            color: 'white',
        }
    },
    title: {
        alignContent: "center",
        marginTop: '10px',
        fontSize: 14,
        textAlign: 'center'
    },
    selectfield: {
        width: 260,
        fontSize: 12,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        cursor: 'pointer'
        // textIndent: '5px'
    },
    selectdropdown: {
        backgroundColor: 'white',
        cursor: 'pointer',
        // marginTop: '-3px',
        borderRadius: 6,
    },
    selectdropdownError: {
        backgroundColor: '#ffcccb',
        // marginTop: '-3px',
        borderRadius: 6,
    },
    selectdropdown1: {
        border: '1px solid gray',
        boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
        backgroundColor: 'white',
        overflow: 'hidden',
        borderRadius: 5,
    },
    dropdown1: {
        fontSize: 12,
        marginTop: '5px'
    },
    infoicon: {
        fontSize: 25,
        color: 'gray',
        marginTop: '15px',
        marginLeft: '8px'
    },
    ignoretop: {
        marginTop: "7px",
        marginLeft: '15px'
    },
    tablehead: {
        position: 'sticky',
        top: 0,
        background: "#364F6B",
        boxShadow: '2px 1px 8px 1px rgba(103, 128, 159, 1)',
    },
    tableLayout1: {
        marginBottom: '1cm'
    },
    buttonbox: {
        float: 'right'
    },
    tableBody: {
        border: '2px solid #364F6B',
    },
    tableBody1: {
        border: '2px solid #364F6B',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff'
    },
    scrollbar: {
        overflowY: 'scroll',
        maxHeight: 67
    },
    footer: {
        marginTop: '-3px'
    },
    button: {
        borderRadius: 20,
    },
    notValid: {
        height: '40px',
        fontSize: 17,
        borderRadius: '4px',
        transition: '0.3s',
        width: "80px",
        marginTop: "10px",
        color: 'red'
    },
    ValTextfieldColor: {
        height: '30px',
        fontSize: 14,
        borderRadius: '4px',
        transition: '0.3s',
        width: "60px",
        marginTop: "12px",
        border: '1px solid red'
    },
    PhaseValTextfieldColor: {
        height: '30px',
        width: '180px',
        fontSize: 14,
        borderRadius: '4px',
        transition: '0.3s',
        border: '1px solid red'
    },
    RequiredStyle: {
        textOverflow: 'ellipsis',
        overflow: "hidden",
        whiteSpace: "nowrap",
        color: "green"
    },
    NonRequiredStyle: {
        textOverflow: 'ellipsis',
        overflow: "hidden",
        whiteSpace: "nowrap",
    },
    DialogBottom: {
        marginRight: '10px'
    },
    phaseInputField: {
        height: '30px',
        width: '95%',
        fontSize: 13,
        borderRadius: '4px',
        transition: '0.3s',
    },
    PhaseValTextfieldColor2: {
        height: '30px',
        width: '95%',
        fontSize: 13,
        borderRadius: '4px',
        transition: '0.3s',
        border: '1px solid red'
    },
    phaseGrid: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    ListWrap: {
        display: "flex",
        flexFlow: "column wrap",
        overflow: 'auto',
        gap: "0px 5px",
        height: 300
    },
    item: {
        display: 'flex',
        width: 'auto',
        textOverflow: "ellipsis",
        paddingTop: '0px',
        paddingBottom: '0px'
    },
    DividerStyles: {
        height: '1px',
        marginTop: '-7px'
    },
    paper: {
        minHeight: '128px',
        height: '128px',
        overflow: 'auto',
        overflowX: 'hidden',
        border: '1px solid gray',
        borderRadius: '0px 0px 5px 5px',
    },
    scrollBarStyle: {
        width: "100%",
        border: '1px solid black',
        borderRadius: '10px'
    },
    paper1: {
        border: "2px solid black",
        fontSize: 13
    },
    TableCellStyle: {
        color: "white",
        padding: '3px 3px 3px 3px'
    },
    textWrap: {
        marginTop: '12px',
        fontSize: 13,
    },
    yesButton: {
        background: "red",
        color: 'white',
        borderRadius: 20,
        right: '15px',
        fontSize: 12,
        '&:hover': {
            background: "red",
        }
    },
    MessageStyle: {
        color: "black",
        fontSize: 17
    },
    clientcode: {
        fontSize: 14,
        backgroundColor: '#007fff',
        color: 'white',
        borderRadius: '10px 10px 0px 0px'
    },
    countMsg: {
        alignContent: "center",
        color: '#945a56',
        fontWeight: 800,
        fontSize: 15
    },
    error_message: {
        color: "green",
        fontSize: 13
    },
    error_message1: {
        color: "red",
        fontSize: 12.5
    },
    uwfStyle: {
        color: 'green',
        fontSize: 14,
        padding: '5px',
        fontWeight: 'bold'
    },
    InfoStyle: {
        fontSize: 14
    },
    ClientBoxStyle: {
        textAlign: 'left',
        marginTop: '5px',
        marginLeft: '10px'
    },
    comboOptions: {
        fontSize: '12px',
    },
    phase: {
        marginLeft: '2px'
    },
    colorStyle: {
        color: '#364f6b',
        fontWeight: 'bold',
        fontSize: 13
    },
    accountItemization: {
        marginLeft: "5px",
        flexDirection: 'row',
        marginTop: '3px',
        marginBottom: '5px'
    },
    templateStyle: {
        display: 'flex',
        flexDirection: 'row'
    },
    starIcon: {
        color: '#ff9800',
        marginTop: '1px'
    },
    paperDate: {
        width: '100%',
        // overflow: 'auto',
        // overflowX: 'hidden',
        // borderTop: '2px #215176 solid',
        // minHeight: '300px',
        // maxHeight: '300px',
        borderRadius: '5px',
        marginTop: '15px',
        padding: '3px',
        boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px'
    },
    datefiledName: {
        color: 'blue',
        marginTop: '10px',
        // textAlign: 'center'
    },
    listItem: {
        '&:hover': {
            background: "#BAD7E9",
        }
    },
    permittedChecked: {
        color: 'black',
        marginTop: '5px',
        marginLeft: '5px',
        fontSize: 15,
        width: '180px'
    },
    modifiedStyle: {
        fontSize: '13px',
        color: 'gray',
        marginTop: '7px',
        marginLeft: '20px',
    },
    flexDisplay: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    dateTextStyle: {
        marginTop: "10px",
        color: "black",
        fontSize: '17px'
    },
    dateTextStyle1: {
        backgroundColor: 'rgba(118, 53, 220, 0.08)',
        color: 'rgb(32,10,105)',
        borderRadius: '5px',
        padding: '5px',
        margin: '10px 0px 10px',
        fontSize: '14px'
    },
    dateList: {
        marginTop: '10px',
        marginLeft: '5px',
        color: 'black'
    },
    textColorStyle:
    {
        color:'blue'
    }
}))

export { useStyles };