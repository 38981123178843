import {
  Backdrop, Button, Dialog, DialogActions, DialogContent, IconButton,
  Snackbar, Typography, makeStyles, useMediaQuery, useTheme
} from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import FileDownload from '@mui/icons-material/FileDownload';
import React, { useState } from 'react';
import { DialogTitleHeader } from '../../../components/GlobalStyles/DialogStyle';
import { IClientCodeByFilesResult } from '../../../models/NotificationCenter/TrustStatementClientCodeResult';
import { useGlobalState } from '../../../store/GlobalStore';
import { usePost } from '../../../utils/apiHelper';
import TooltipSmall from '../../GlobalStyles/TooltipSmall';
import { trustStatementAction } from '../../../models/accesscontrol/ClientUserAccessContext';
import { PuffLoader } from 'react-spinners';

const useStyles = makeStyles((theme) => ({
  fileDownloadLink: {
    fontSize: 13,
    color: "blue",
  },
  yesbutton: {
    backgroundColor: 'green',
    fontSize: 12,
    borderRadius: '20px',
    color: 'white',
    '&:hover': {
      backgroundColor: 'green',
      color: 'white',
    }
  },
  nobutton: {
    background: "red",
    fontSize: 12,
    borderRadius: "20px",
    color: 'white',
    '&:hover': {
      backgroundColor: 'red',
      color: 'white',
    }
  },
  titleheader: {
    fontWeight: 'bold',
    fontSize: 18,
    marginTop: '5px',
    color: 'white'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  }
}))

const DownloadDocumentFile: React.FC<{ row: IClientCodeByFilesResult }> = (props) => {
  const classes = useStyles();
  const { row } = props;
  const { state } = useGlobalState();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = useState(false);
  const [fileExists, setFileExists] = useState(false);
  const [ProgressBar, setshowProgressBar] = useState(false);

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setFileExists(false);
  };


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose1 = () => {
    setOpen(false);
  };

  function handleClose(isDownload: boolean) {
    setOpen(false);
    if (isDownload) {
      DownloadPdfFileAsync();
    }
  };

  async function DownloadPdfFileAsync() {
    setshowProgressBar(true);
    (async () => {
      let request = {
        "blobUri": row?.blobUri
      }

      let requestDownload = {
        "fileId": row?.fileId,
        "actionId": trustStatementAction?.FileDownloaded,
        "userId": state?.userAccessContext?.id
      }
      await usePost<any>("File/TrustStatementPDFFileDownload", request).then(async (r) => {
        const arrayBuffer = base64ToArrayBuffer(r.data);
        createAndDownloadBlobFile(arrayBuffer, row?.fileName);
        await usePost<any>("TrustStatementAuditHistory", requestDownload);
      }).catch(() => {
        setFileExists(true);
      }).finally(() => {
        setshowProgressBar(false);
      });
    })();
  }

  function base64ToArrayBuffer(base64: string) {
    const binaryString = window.atob(base64);
    const bytes = new Uint8Array(binaryString.length);
    return bytes.map((byte, i) => binaryString.charCodeAt(i));
  }

  function createAndDownloadBlobFile(body, filename) {
    const blob = new Blob([body]);
    const fileName = `${filename}`;
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', fileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  return (
    <React.Fragment>
      <IconButton size='small' style={{ padding: 0 }} onClick={handleClickOpen}>
        <TooltipSmall title="File Download">
          <FileDownload fontSize='small' color='success' />
        </TooltipSmall>
      </IconButton>

      <Backdrop className={classes.backdrop} open={ProgressBar}>
        <PuffLoader size={80} color={"white"} speedMultiplier={1} />
      </Backdrop>

      <Dialog maxWidth={'sm'} PaperProps={{ style: { borderRadius: 15 } }}
        fullScreen={fullScreen} open={open}
        onClose={(_) => { handleClose(false); }}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose1}>
          <Typography variant="h6" gutterBottom className={classes.titleheader}>
            DOWNLOAD FILE
          </Typography>
        </DialogTitleHeader>
        <DialogContent>
          <Typography variant="h6" style={{ color: "black", fontSize: 18 }} gutterBottom>
            Are you sure, you want to Download this file ?
          </Typography>
        </DialogContent>
        <DialogActions >
          <Button variant="contained" size="small" onClick={(_) => { handleClose(true); }} className={classes.yesbutton} color="primary" autoFocus>
            Yes
          </Button>
          <Button variant="contained" size="small" autoFocus className={classes.nobutton} onClick={(_) => { handleClose(false); }} color="primary">
            No
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={fileExists} className="snackBarStyle" anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }} autoHideDuration={4000} onClose={closeSnackbar}>
        <Alert onClose={() => { setFileExists(false) }} severity="error" className="alertStyle">
          File does not exists!
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}

export default DownloadDocumentFile