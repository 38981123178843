import { css } from "@emotion/react";
import { AppBar, Box, Grid, IconButton, Tab, Tabs, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import Autorenew from '@mui/icons-material/Autorenew';
import React, { useEffect, useState } from 'react';
import PuffLoader from "react-spinners/PuffLoader";
import { rollVerbiage } from "../../../constants/Constant";
import lock from "../../../images/inquiry/lock.svg";
import Activelock from "../../../images/inquiry/unlocked.svg";
import { IAccStatus } from "../../../models/Inquiry/IAccStatus";
import { IGetRolledPlacement } from '../../../models/Inquiry/IGetRolledPlacement';
import { IClosedLetterDetails } from '../../../models/Inquiry/LetterDetails/IClosedLetterDetails';
import { ILetterDetails } from '../../../models/Inquiry/LetterDetails/ILetterDetails';
import { ITransactionHistory } from '../../../models/Inquiry/TransactionHistory/ITransactionHistory';
import { IDebtor } from '../../../models/NotificationCenter/IDebtor';
import { useFetch, usePost } from '../../../utils/apiHelper';
import LightTooltipLeft from "../../GlobalStyles/LightTooltipLeft";
import useStyles from '../NCCcss/NotiCenterCss';
import AccountDetails from './PvAccountDetails';
import AccountHistory from './PvAccountHistory';
import TransactionAuditHistory from './PvTransactionAuditHistory';
import { INotificationCenter } from "../../../models/NotificationCenter/INotificationCenter";

interface TabPanelProps {
    children?: React.ReactNode,
    index: any,
    value: any
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}  >
            {value === index && (
                <Box p={5}>
                    <Typography >{children}</Typography>
                </Box>
            )}
        </div>
    )
}

const InquiryDetail: React.FC<{ pv: INotificationCenter, inquiryDetail: IDebtor, disable?: IGetRolledPlacement, isBlocked: boolean, onRefresh: () => void }> = (props) => {
    const { inquiryDetail, isBlocked, pv } = props
    const [value, setValue] = React.useState(0);
    const classes = useStyles();
    const [GetLetterDetails, setGetLetterDetails] = useState<ILetterDetails[]>([]);
    const [visibleLetter, setVisibleLetter] = useState<IClosedLetterDetails[]>([]);
    const [Transaction, setTransaction] = useState<ITransactionHistory[]>([]);
    const [isClosed, setisClosed] = useState<IGetRolledPlacement>();
    const [history, setHistory] = useState<ITransactionHistory[]>([]);
    const [AccDetails, setAccDetails] = useState<IAccStatus>();
    const [checkData, setCheckData] = React.useState(false);
    const [mapped, setMapped] = useState(false);
    const [ProgressBar, setshowProgressBar] = useState(false);

    /* Spinner */
    const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;


    useEffect(() => {
        (async () => {
            await useFetch<ITransactionHistory[]>(`GetTransactionHistory?DBTNumber=${inquiryDetail.dbT_NO}`).then((GetTransactionHistory) => {
                setTransaction(GetTransactionHistory.data);
                setCheckData(GetTransactionHistory.data.length > 0 ? true : false);
            });
        })()
    }, [])

    useEffect(() => {
        (async () => {
            await useFetch<ITransactionHistory[]>(`DebtorHistory?DBTNumber=${inquiryDetail.dbT_NO}`).then((GetDebtorHistory) => {
                setHistory(GetDebtorHistory.data);
                setMapped(GetDebtorHistory.data.length > 0 ? true : false);
            });
        })()
    }, [])

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue)
    }

    useEffect(() => {
        if (visibleLetter.length <= 0) {
            getClosedLetterDetails();
        }
    }, []);

    async function getClosedLetterDetails() {
        await useFetch<IClosedLetterDetails[]>(`AccountLetterDetailsGet?DBTNumber=${inquiryDetail.dbT_NO.toString()}`).then((GetClosedLetterDetails) => {
            setVisibleLetter(GetClosedLetterDetails.data);
        });
    }

    useEffect(() => {
        (async () => {
            setshowProgressBar(true);
            await useFetch<IGetRolledPlacement[]>(`RolledPlacement?DBTNumber=${inquiryDetail.dbT_NO}`).then((data) => {
                setisClosed(data?.data[0]);
            }).finally(() => {
                setshowProgressBar(false);
            });
        })()
    }, []);

    useEffect(() => {
        (async () => {
            setshowProgressBar(true);
            await useFetch<IAccStatus>(`Account/GetAccStatus?dbt_no=${inquiryDetail.dbT_NO}`).then((r) => {
                setAccDetails(r?.data);
            }).finally(() => {
                setshowProgressBar(false);
            });
        })()
    }, [])

    const GetAccStatus = () => {
        (async () => {
            await useFetch<IAccStatus>(`Account/GetAccStatus?dbt_no=${inquiryDetail.dbT_NO}`).then((r) => {
                setAccDetails(r?.data);
            });
        })()
    }

    const DisableButton = () => {
        (async () => {
            await useFetch<IGetRolledPlacement[]>(`RolledPlacement?DBTNumber=${inquiryDetail.dbT_NO}`).then((data) => {
                setisClosed(data?.data[0]);
            });
        })()
    }

    useEffect(() => {
        (async () => {
            let request = { "debtorId": inquiryDetail.dbT_NO };
            await usePost<{ letterDetails: ILetterDetails[] }>("GetLetterDetails", request).then((GetLetterDetails) => {
                setGetLetterDetails(GetLetterDetails.data["letterDetails"]);
            });
        })()
    }, [])

    const hidenotificationForAllUsers = () => {
        props.onRefresh();
    }

    return (
        <Box>
            <AppBar position="static" color="transparent" className={classes.appbarStyle}>
                <Tabs id="I_Tab" className={classes.tabIdIT}
                    value={value} onChange={handleChange}
                    classes={{ indicator: classes.indicator }} aria-label="simple tabs example">
                    <Tab id="I_AccountDetails" className={`${classes.tabs} ${classes.iconLabelWrapper}`} label="Account Details" />
                    <Tab id="I_AccountHistory" className={classes.tabs} label="Account History" />
                    <Tab id="I_TransactionAuditHistory" className={classes.tabs} label="Transaction Audit History" />
                    <Grid item xs={7} className={classes.gridStyle}>
                        {isClosed?.rejectedRoll === true ?
                            <IconButton id="Active_Status" className="close-account-info" >
                                <LightTooltipLeft title={rollVerbiage}>
                                    <InfoIcon className={classes.image1} />
                                </LightTooltipLeft>
                            </IconButton>
                            : null
                        }
                    </Grid>
                </Tabs>
                <div className={classes.inquiryDetails}>
                    <TabPanel value={value} index={0}>
                        {ProgressBar ? <PuffLoader css={override} size={100} color={"blue"} speedMultiplier={1.5} /> :
                            <React.Fragment>
                                <AccountDetails pv={pv} debtors={inquiryDetail} letterDetails={visibleLetter} isEnable={isClosed} onEroll={DisableButton} onHideAll={hidenotificationForAllUsers} BlockAwaitUsers={isBlocked} GetDebtorLetterDetails={GetLetterDetails} GetAccStatus={AccDetails} OnGetDetails={GetAccStatus} />
                            </React.Fragment>
                        }
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        < AccountHistory debtors={inquiryDetail} histories={history} mapped={mapped} />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <TransactionAuditHistory debtors={inquiryDetail} transactions={Transaction} mapped={checkData} />
                    </TabPanel>
                </div>
            </AppBar >
        </Box >
    )
}

export default InquiryDetail