import {
    Backdrop,
    Button, Checkbox, Dialog, DialogActions, DialogContent, Grid, IconButton, Paper,
     Table, TableBody, TableCell, TableHead, TableRow, Typography
} from '@mui/material';
import React, { useState } from 'react';
import { Transition } from '../../../../GlobalStyles/DialogBoxTransition';
import { DialogTitleHeader } from '../../../../GlobalStyles/DialogStyle';
import { useStyles } from '../../ManagementCSS';
import { TableContainer } from '@material-ui/core';
import TooltipSmall from '../../../../GlobalStyles/TooltipSmall';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { IClientRecordTypes, IGetClientAPIKeyRecordType, IGetRecordType, IManageClientAPIKeyRecordType } from '../../../../../models/accesscontrol/PlacementTemplateRule';
import { useFetch, usePost } from '../../../../../utils/apiHelper';
import { PuffLoader } from 'react-spinners';

const ManageClientAPIRecordType: React.FC<{ apiKeyId: number }> = (props) => {
    const { apiKeyId } = props;
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [ProgressBar, setShowProgressBar] = useState(false);
    const [recordType, setRecordType] = useState<IGetRecordType[]>([]);
    const [selectedRecordTypes, setSelectedRecordTypes] = useState<number[]>([]);

    const handleClose = () => {
        setOpen(false);
    }

    const handleOpen = () => {
        setOpen(true);
        handleGetClientKeyRecordType();
        getRecordTypeDetail();
    }

    const handleCheckboxChange = (recordTypeId: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedRecordTypes(prevSelected => {
            if (event.target.checked) {
                return [...prevSelected, recordTypeId];
            } else {
                return prevSelected.filter(id => id !== recordTypeId);
            }
        });
    };

    const getRecordTypeDetail = () => {
        setShowProgressBar(true);
        (async () => {
            await useFetch<IGetRecordType[]>("GlobalSettings/GetRecordTypeDetail").then((result) => {
                setRecordType(result.data);
            }).finally(() => {
                setShowProgressBar(false);
            })
        })()
    }

    const handleSubmit = () => {
        setShowProgressBar(true);
        (async () => {
            const formattedRecordTypes: IClientRecordTypes[] = selectedRecordTypes?.map(id => ({ recordTypeId: id }));
            let request: IManageClientAPIKeyRecordType = {
                aPIKeyId: apiKeyId,
                clientRecordTypes: formattedRecordTypes
            }
            await usePost("GlobalSettings/InsertClientAPIKeyRecordType", request).then((result) => {
                setShowProgressBar(false);
            }).finally(() => {
                handleClose();
                handleGetClientKeyRecordType();
            })
        })()
    }

    const handleGetClientKeyRecordType = () => {
        (async () => {
            await useFetch<IGetClientAPIKeyRecordType[]>("GlobalSettings/GetClientAPIKeyRecordType?APIKeyId=" + apiKeyId).then((result) => {
                const selectedIds = result?.data?.map(record => record.recordTypeId);
                setSelectedRecordTypes(selectedIds);
            })
        })()
    }

    return (
        <React.Fragment>
            <IconButton onClick={handleOpen}>
                <TooltipSmall title="File Rules">
                    <DescriptionOutlinedIcon color='primary' />
                </TooltipSmall>
            </IconButton>
            <Dialog open={open} PaperProps={{ style: { borderRadius: 15 } }} maxWidth={'md'} TransitionComponent={Transition}
                onClose={handleClose} aria-labelledby="responsive-dialog-title" >
                <Backdrop className={classes.backdrop} open={ProgressBar}>
                    <PuffLoader size={80} color={"white"} speedMultiplier={1} />
                </Backdrop>
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                    <Typography variant="h6" gutterBottom className={classes.titleheader} fontWeight={600}>
                        MAP RECORD TYPE
                    </Typography>
                </DialogTitleHeader>
                <DialogContent >
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <TableContainer component={Paper} sx={{ mt: 2 }}>
                                <Table size="small" aria-label="dense table" stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className={classes.TableCellStyle}></TableCell>
                                            <TableCell align="left" className={classes.TableCellStyle}>RECORD TYPE</TableCell>
                                            <TableCell align="left" className={classes.TableCellStyle}>RECORD DESCRIPTION</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {recordType?.map((row) => (
                                            <TableRow key={row.recordTypeId}>
                                                <TableCell align="left" className={classes.TableCellStyle1}>
                                                    <Checkbox
                                                        size='small'
                                                        checked={selectedRecordTypes.includes(row.recordTypeId)}
                                                        onChange={handleCheckboxChange(row.recordTypeId)}
                                                    />
                                                </TableCell>
                                                <TableCell align="left" className={classes.TableCellStyle1}>{row.recordType}</TableCell>
                                                <TableCell align="left" className={classes.TableCellStyle1}>{row.description}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' size='small' color='primary' sx={{ mr: 1 }} onClick={handleSubmit} disabled={!selectedRecordTypes.length}>
                        Submit
                    </Button>
                    <Button variant='contained' size='small' color='error' sx={{ mr: 2 }} onClick={handleClose}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

export default ManageClientAPIRecordType