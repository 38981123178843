import React, { useState } from 'react';
import { PuffLoader } from 'react-spinners';
import { useStyles } from '../../ManagementCSS';
import { Backdrop, Button, Dialog, DialogActions, DialogContent, Grid, IconButton, TextField, Typography } from '@mui/material';
import { DialogTitleHeader } from '../../../../GlobalStyles/DialogStyle';
import { Transition } from '../../../../GlobalStyles/DialogBoxTransition';
import { IGetRecordType, IInsertRecordTypeDetail } from '../../../../../models/accesscontrol/PlacementTemplateRule';
import EditIcon from '@mui/icons-material/Edit';
import LockIcon from '@mui/icons-material/Lock';
import { usePost } from '../../../../../utils/apiHelper';
import * as constant from '../../../../../constants/Constant';
import { Snackbar } from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

const EditRecordType: React.FC<{ onEdit: () => void, recordType: IGetRecordType }> = (props) => {
    const classes = useStyles();
    const { onEdit, recordType } = props;
    const [open, setOpen] = useState(false);
    const [ProgressBar, setShowProgressBar] = useState(false);
    const [updateRecordType, setUpdateRecordType] = useState(false);
    const [recordTypeError, setRecordTypeError] = useState("");
    const [recordTypeDetail, setRecordTypeDetail] = useState<IInsertRecordTypeDetail>(recordType);

    const handleClose = () => {
        setOpen(false);
    }

    const handleOpen = () => {
        setOpen(true);
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setRecordTypeDetail(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = () => {
        setShowProgressBar(false);
        (async () => {
            let request: IInsertRecordTypeDetail = {
                recordTypeId: recordTypeDetail.recordTypeId,
                recordType: recordTypeDetail.recordType,
                description: recordTypeDetail.description
            }
            await usePost("GlobalSettings/ManageRecordTypeDetail", request).then((r) => {
                onEdit();
                setUpdateRecordType(true);
            }).finally(() => {
                setShowProgressBar(false);
                handleClose();
                setRecordTypeDetail(null);
            });
        })()
    }

    const validateRecordTypeName = (e) => {
        const recordType = e.target.value;
        if (constant.NameRegex.test(recordType)) {
            setRecordTypeError("");
        } else {
            setRecordTypeError("Please enter valid Record Type");
        }
    };

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setUpdateRecordType(false);
    };

    return (
        <React.Fragment>
            {!recordType.isDefault ?
                <IconButton id="CPO_Delete_btn" aria-label="delete" onClick={handleOpen} style={{ padding: '2px' }}>
                    <EditIcon fontSize='small' color='success' />
                </IconButton>
                :
                <IconButton id="CPO_Delete_btn" aria-label="delete" style={{ padding: '2px' }} disabled>
                    <LockIcon fontSize='small' style={{ color: 'black' }} />
                </IconButton>
            }
            <Dialog open={open} PaperProps={{ style: { borderRadius: 15 } }} maxWidth={'sm'} TransitionComponent={Transition}
                onClose={handleClose} aria-labelledby="responsive-dialog-title" >
                <Backdrop className={classes.backdrop} open={ProgressBar}>
                    <PuffLoader size={80} color={"white"} speedMultiplier={1} />
                </Backdrop>
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                    <Typography variant="h6" gutterBottom className={classes.titleheader} fontWeight={600}>
                        EDIT RECORD TYPE
                    </Typography>
                </DialogTitleHeader>
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12}>
                            <Typography variant='subtitle1' className={classes.headerName}>
                                <b>Record Type</b>
                            </Typography>
                            <TextField
                                name="recordType"
                                value={recordTypeDetail?.recordType}
                                onChange={(e) => { handleInputChange(e); validateRecordTypeName(e); }}
                                label="Record Type"
                                autoComplete="off"
                                size="small"
                                className={classes.keyTextFiled}
                                fullWidth
                                variant="outlined"
                                inputProps={{ maxLength: 100 }}
                                sx={{ mt: 1 }}
                                required helperText={recordTypeError} error={!!recordTypeError}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography variant='subtitle1' className={classes.headerName}>
                                <b>Description</b>
                            </Typography>
                            <TextField
                                name="description"
                                value={recordTypeDetail?.description}
                                onChange={(e) => { handleInputChange(e); }}
                                label="Description"
                                autoComplete="off"
                                size="small"
                                className={classes.keyTextFiled}
                                fullWidth
                                variant="outlined"
                                multiline
                                rows={3}
                                inputProps={{ maxLength: 100 }}
                                sx={{ mt: 1 }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' size='small' color='primary' sx={{ mr: 1 }} onClick={handleSubmit} disabled={ProgressBar}>
                        Submit
                    </Button>
                    <Button variant='contained' size='small' color='error' sx={{ mr: 2 }} onClick={handleClose}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                className="snackBarStyle"
                open={updateRecordType}
                autoHideDuration={4000}
                onClose={closeSnackbar}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Alert onClose={closeSnackbar} severity="success" className="alertStyle">
                    Record Type Updated Successfully!
                </Alert>
            </Snackbar>
        </React.Fragment>
    )
}

export default EditRecordType