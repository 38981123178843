import DateFnsUtils from '@date-io/date-fns';
import {
    Backdrop, Box, Button, Checkbox, Chip, CircularProgress, ClickAwayListener, Dialog, DialogContent,
    FormLabel, Grid, IconButton, InputAdornment, Paper, Snackbar, TextField, Typography
} from '@material-ui/core';
import React, { useRef, useState } from 'react';
// Icons
import CancelIcon from '@material-ui/icons/Cancel';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
// Models
import SearchIcon from "@material-ui/icons/Search";
import { Alert, Autocomplete, AutocompleteCloseReason } from '@material-ui/lab';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { DialogActions, FormControl } from '@mui/material';
import { Scrollbars } from 'react-custom-scrollbars';
import { PuffLoader } from 'react-spinners';
import * as constant from '../../../constants/Constant';
import { IClientCodeContract } from '../../../models/GLobalSettings/IGetKeyValueDetails';
import { IAckClientInfo } from '../../../models/IAckReport/IAckClientInfo';
import { useGlobalState } from '../../../store/GlobalStore';
import { usePost } from '../../../utils/apiHelper';
import { Transition } from '../../GlobalStyles/DialogBoxTransition';
import { DialogTitleHeader } from '../../GlobalStyles/DialogStyle';
import { useStyles } from './ManagementCSS';

const CreateKeyManagement: React.FC<{ clientList: IClientCodeContract[], onSave: () => void }> = (props) => {
    const classes = useStyles();
    type guid = string;
    const [open, setOpen] = useState<boolean>(false);
    const textInputRef = useRef(null);
    const [isCopy, setIsCopy] = useState<boolean>(false);
    const { state } = useGlobalState();
    const [StartDate, setStartDate] = useState(new Date());
    const [ExpiryDate, setExpiryDate] = useState(new Date());
    const [ProgressBar, setShowProgressBar] = useState(false);
    const [EmailAddress, setEmailAddress] = useState<string>(""); // To Get the Email address
    const UserId = state.userAccessContext?.id;
    const [APIKeyName, setAPIKeyName] = useState<guid>(''); // Get the API key
    const [APIKeyId, setAPIKeyId] = useState<number>(0); // Get the API key
    const [Emailerror, setEmailError] = useState(""); // Display error message on email address
    const [ClientName, setClientName] = useState<string>("");
    const [clientNameError, setClientNameError] = useState<string>("");

    const [selectedClients, setSelectedClients] = useState([]);
    const [searchClientCode, setSearchClientCode] = useState("");
    const [ClientCodeOpen, setClientCodeOpen] = useState(false);
    const [confirmedClients, setConfirmedClients] = useState([]);
    const [loading, setLoading] = useState(false);
    const [checkAll, setCheckAll] = useState(false);
    const [deselectClient, setDeselectClient] = useState(false);

    const DateFormat = state.GlobalUtils?.settingValue === "DD/MM/YYYY" ? "dd/MM/yyyy" : state.GlobalUtils?.settingValue === "MM/DD/YYYY" ? "MM/dd/yyyy" : state.GlobalUtils?.settingValue === "YYYY/MM/DD" ? "yyyy/MM/dd" : "MM/dd/yyyy";

    const handleCopyClick = () => {
        textInputRef.current.select();
        document.execCommand('copy');
        setIsCopy(true);
    };

    const handleClickOpen = () => {
        (async () => {
            setOpen(true);
            setConfirmedClients([]);
            setLoading(true);
            let request = {
                "userId": state?.userAccessContext?.id,
                "client_code": searchClientCode?.trim().toLocaleUpperCase(),
                "isAdmin": state?.userAccessContext.role
            }
            await usePost<IAckClientInfo[]>("Client/searchAckClientCode", request).then((clients) => {
                setConfirmedClients(clients.data);
            }).finally(() => {
                setLoading(false);
            });
            setOpen(true);
        })();
    };

    const handleClose = () => {
        setOpen(false);
        setIsCopy(false);
        props.onSave();
        setEmailError("");
        setAPIKeyName(null);
        setStartDate(new Date());
        setExpiryDate(new Date());
        setEmailAddress("");
        setCheckAll(false);
        setSearchClientCode("");
        setConfirmedClients([]);
        setSelectedClients([]);
        setClientCodeOpen(false);
    };

    const UpdateMessage = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setIsCopy(false);
    };

    const handleFirstDateChange = (date: Date | null) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date: Date | null) => {
        setExpiryDate(date);
    };

    const handleClickGenerateKeySubmit = () => {
        setShowProgressBar(true);
        let newClientCodeList = [];
        selectedClients?.map(result => {
            let newList: IClientCodeContract = {
                clientCode: result.client_code
            }
            newClientCodeList.push(newList);
        });
        (async () => {
            let request = {
                "apiKeyId": 0,
                "clientName": ClientName,
                "emailSentTo": EmailAddress,
                "userId": UserId,
                "startDate": StartDate,
                "expiryDate": ExpiryDate,
                "clientCodeDetails": newClientCodeList,
                "clientCodeList": selectedClients
            };
            await usePost<{ apiKeyName: guid }>("GlobalSettings/InsertApiManagementKey", request).then((result) => {
                setAPIKeyId(result?.data["apiKeyId"]);
                setAPIKeyName(result?.data["apiKeyName"]);
            });
            setShowProgressBar(false);
        })()
    }

    const validateEmail = (e) => {
        const email = e.target.value;
        if (constant.emailRegex.test(email)) {
            setEmailError("");
        } else {
            setEmailError("Please enter valid Email address");
        }
    };

    const validateClientName = (e) => {
        const clientName = e.target.value;
        if (constant.NameRegex.test(clientName)) {
            setClientNameError("");
        } else {
            setClientNameError("Please enter valid Client Name");
        }
    };

    const handleSearchClient = (e) => {
        setSearchClientCode(e.target.value);
    }

    const handleClickAway = (e) => {
        (async () => {
            setClientCodeOpen(false);
            let request = {
                "userId": state?.userAccessContext?.id,
                "client_code": "",
                "isAdmin": true
            }
            await usePost<IAckClientInfo[]>("Client/searchAckClientCode", request).then((r) => {
                setConfirmedClients(r.data);
            }).finally(() => {
                setShowProgressBar(false);
            });
        })()
    };

    const onDelete = (clientcode) => () => {
        setSelectedClients((value) => value.filter((v) => v.client_code !== clientcode));
    };

    const handleClientSelect = (event, valueone) => {
        const newClientList = valueone.filter((c) => (
            !props?.clientList.find((y) => y.clientCode === c.client_code)
        ));

        setSelectedClients(newClientList);
        if (valueone?.length <= 0) {
            setCheckAll(false);
        }
    }

    const handleSearch = () => {
        (async () => {
            setConfirmedClients([]);
            setLoading(true);
            setShowProgressBar(true);
            setOpen(true);
            let request = {
                "userId": state?.userAccessContext?.id,
                "client_code": searchClientCode?.trim().toLocaleUpperCase(),
                "isAdmin": true
            }
            await usePost<IAckClientInfo[]>("Client/searchAckClientCode", request).then((r) => {
                setConfirmedClients(r.data);
                setLoading(false);
            }).finally(() => {
                setShowProgressBar(false);
                setCheckAll(false);
            });
        })()
    };

    const checkAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckAll(event.target.checked);
        if (event.target.checked) {
            // Filter out items in clientList from confirmedClients
            const newClientList = confirmedClients.filter((c) => (
                !props?.clientList.find((y) => y.clientCode === c.client_code)
            ));

            // Filter out duplicates when adding new clients
            const filteredNewClientList = newClientList.filter((c) => (
                !selectedClients.find((r) => c.client_code === r.client_code)
            ));

            setSelectedClients([...new Set(selectedClients?.concat(filteredNewClientList?.map((r) => r)))]);
            setClientCodeOpen(false);
        }
        setCheckAll(false);
        handleClear();
        setSearchClientCode("");
    };

    const DeselectAllChange = (event) => {
        setDeselectClient(event.target.checked);
        if (event.target.checked) {
            var NewClientList = selectedClients.filter((c) => {
                return !confirmedClients.find((r) => {
                    return c.client_code == r.client_code
                })
            });
            setSelectedClients(NewClientList);
            setClientCodeOpen(false);
        }
        setDeselectClient(false);
        handleClear();
        setSearchClientCode("");
    };

    const handleClear = () => {
        (async () => {
            setOpen(true);
            let request = {
                "userId": state?.userAccessContext?.id,
                "client_code": "",
                "isAdmin": state?.userAccessContext?.role
            }
            await usePost<IAckClientInfo[]>("Client/searchAckClientCode", request).then((r) => {
                setConfirmedClients(r.data);
            }).finally(() => {
                setShowProgressBar(false);
            });
        })()
    };

    return (
        <React.Fragment>
            <Button id="CreateTemplate_btn" size="small" startIcon={<NoteAddIcon />} variant="contained" color="primary" onClick={handleClickOpen} className={classes.btnManTemp}>
                GENERATE API KEY
            </Button>

            <Dialog open={open} PaperProps={{ style: { borderRadius: 15 } }} maxWidth={'md'} TransitionComponent={Transition}
                onClose={handleClose} aria-labelledby="responsive-dialog-title" >
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                    <Typography variant="h6" gutterBottom className={classes.titleheader}>
                        ISSUE A.R.M.DEV API KEY
                    </Typography>
                </DialogTitleHeader>
                <DialogContent >
                    <Backdrop className={classes.backdrop} open={ProgressBar}>
                        <PuffLoader size={80} color={"white"} speedMultiplier={1} />
                    </Backdrop>
                    <Grid container spacing={1}>
                        <Grid item xs={6} sm={6}>
                            <Typography variant='subtitle1' className={classes.headerName}>
                                <b>Client Name</b>
                            </Typography>

                            <TextField id="clientName" label="Client Name"
                                autoComplete="off"
                                size="small"
                                className={classes.keyTextFiled}
                                onChange={(e) => { setClientName(e.target.value); validateClientName(e); }}
                                fullWidth
                                variant="outlined"
                                required helperText={clientNameError} error={!!clientNameError}
                                inputProps={{ maxlength: 100 }}
                            />
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <Typography variant='subtitle1' className={classes.headerName}>
                                <b>API Key to be sent to</b>
                            </Typography>

                            <TextField id="Email" label="Email Id"
                                autoComplete="off"
                                size="small"
                                className={classes.keyTextFiled}
                                onChange={(e) => { setEmailAddress(e.target.value); validateEmail(e); }}
                                fullWidth
                                variant="outlined"
                                required helperText={Emailerror} error={!!Emailerror}
                                inputProps={{ maxlength: 100 }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} style={{ marginTop: '10px' }}>
                            <FormLabel className={classes.labelStyle}> Selected Client Code : {selectedClients.length}</FormLabel>
                            <Paper className={classes.boxBorder}>
                                <Scrollbars autoHide
                                    autoHideTimeout={1000}
                                    autoHideDuration={10}
                                    autoHeight
                                    className={`${classes.textBoxStyle2} ${classes.scrollMargin}`}
                                    autoHeightMin={80}
                                    autoHeightMax={80}>
                                    {!selectedClients.length ?
                                        <div className={classes.clientStyle}>
                                            <GroupAddIcon className={classes.groupIconStype} />
                                            <Typography variant="h6" style={{ fontSize: 15, textAlign: 'center' }}>
                                                <b>Kindly Select Client Codes</b>
                                            </Typography>
                                        </div>
                                        :
                                        <div className={`${classes.valueContainer}`} >
                                            {selectedClients.map((v) => (
                                                <Chip key={v.client_code} color="secondary" size="small" label={v.client_code} onDelete={onDelete(v.client_code)} />
                                            ))}
                                        </div>
                                    }
                                </Scrollbars>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box>
                                <Typography variant='subtitle1' className={classes.headerName}>
                                    <b>Choose Client Code</b>
                                </Typography>
                                <div >
                                    <ClickAwayListener onClickAway={handleClickAway}>
                                        <Box>
                                            <Autocomplete
                                                multiple size="small"
                                                loading={loading}
                                                filterOptions={(options, state) => options}
                                                className={classes.clientCodeBox}
                                                autoComplete={false}
                                                id="checkboxes-tags-demo"
                                                options={confirmedClients}
                                                classes={{ paper: classes.paper2 }}
                                                disableClearable
                                                value={selectedClients}
                                                open={ClientCodeOpen}
                                                renderTags={() => null}
                                                onChange={handleClientSelect}
                                                onOpen={() => { setClientCodeOpen(true); }}
                                                onClose={(e: any, reason: AutocompleteCloseReason) => {
                                                    if (reason === "toggleInput") {
                                                        setClientCodeOpen(false);
                                                    }
                                                }}
                                                getOptionDisabled={(option) => option?.client_code}
                                                getOptionLabel={(option) => option.client_code}
                                                getOptionSelected={(option, value) => option.client_code === value.client_code}
                                                renderOption={(option, { selected }) => {
                                                    const isDisabled = props?.clientList.some((selectedClient) => selectedClient.clientCode === option.client_code);
                                                    return (
                                                        <span className={`${selected ? classes.GreenColor : ''} ${isDisabled ? classes.DisabledOption : ''}`} style={{ fontSize: 13, marginLeft: '5px' }}>
                                                            {option?.client_code + " -- " + option?.client_name}
                                                        </span>
                                                    );
                                                }}
                                                ListboxProps={{ style: { maxHeight: '160px' } }}
                                                renderInput={(params) => (
                                                    <TextField {...params} fullWidth size="small"
                                                        value={searchClientCode} variant="outlined"
                                                        type='text' placeholder="Search Clients"
                                                        onChange={(e) => { handleSearchClient(e) }}
                                                        onKeyDown={event => {
                                                            if (event.key === 'Enter') { handleSearchClient(event); handleSearch(); setClientCodeOpen(true); }
                                                        }}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <React.Fragment>
                                                                    {loading ? <CircularProgress color="primary" size={20} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                    <InputAdornment position="end">
                                                                        <IconButton onClick={(_) => { handleSearch(); setClientCodeOpen(true); }}>
                                                                            <SearchIcon className={classes.IconSizeStyle} />
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                    <Box display="flex" width="100%" className={classes.CheckBoxBorder}>
                                                                        <Box className={classes.checkBoxStyle} width="60%">
                                                                            <Checkbox size="small" disabled={loading || !confirmedClients.length}
                                                                                checked={checkAll}
                                                                                onChange={checkAllChange}
                                                                                id="check-all"
                                                                            />
                                                                            <span style={{ fontSize: 12 }}>SELECT ALL</span>
                                                                        </Box>
                                                                        <Box className={classes.checkBoxStyle} width="40%">
                                                                            <Checkbox size="small" disabled={loading || !confirmedClients.length}
                                                                                checked={deselectClient}
                                                                                onChange={(e) => { DeselectAllChange(e); }}
                                                                                id="check-all"
                                                                            />
                                                                            <span style={{ fontSize: 12 }}>DESELECT ALL</span>
                                                                        </Box>
                                                                    </Box>
                                                                </React.Fragment>
                                                            ),
                                                        }}
                                                        className={classes.textBoxStyle1}
                                                    />
                                                )}
                                            />
                                        </Box>
                                    </ClickAwayListener>
                                </div>
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Typography variant='subtitle1' className={classes.headerName}>
                                <b>API Key Usage Start Date</b>
                            </Typography>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <FormControl fullWidth>
                                    <KeyboardDatePicker
                                        inputVariant="outlined"
                                        variant="inline"
                                        size="small"
                                        format={DateFormat}
                                        className={classes.keyTextFiled}
                                        disableToolbar
                                        autoOk={true}
                                        fullWidth
                                        margin="normal"
                                        id="DocUpLog_StartDate"
                                        value={StartDate}
                                        onChange={handleFirstDateChange}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        disablePast={true}
                                    />
                                </FormControl>
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant='subtitle1' className={classes.headerName}>
                                <b>API Key Expiry Date</b>
                            </Typography>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <FormControl fullWidth>
                                    <KeyboardDatePicker
                                        size="small"
                                        disableToolbar
                                        autoOk={true}
                                        inputVariant="outlined"
                                        variant="inline"
                                        fullWidth
                                        format={DateFormat}
                                        className={classes.keyTextFiled}
                                        margin="normal"
                                        id="DocUpLog_EndDate"
                                        value={ExpiryDate}
                                        onChange={handleEndDateChange}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        disablePast={true}
                                    />
                                </FormControl>
                            </MuiPickersUtilsProvider>
                            {StartDate > ExpiryDate ?
                                <Typography variant='h6' className={classes.clientCodeError}>Expiry date should be grater than start date</Typography>
                                :
                                null
                            }
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <Typography variant='subtitle1' className={classes.headerName}>
                                <b>API Key </b>
                            </Typography>
                            <TextField
                                inputRef={textInputRef}
                                variant="outlined"
                                value={APIKeyName}
                                fullWidth
                                className={classes.copyTextField}
                                size='small'
                                InputProps={{
                                    classes: {
                                        root: classes.outlinedInput,
                                        notchedOutline: classes.notchedOutline,
                                    },
                                    endAdornment: (
                                        APIKeyName?.length ?
                                            <IconButton onClick={handleCopyClick} style={{ padding: '3px' }}>
                                                {isCopy ? <DoneAllIcon style={{ color: 'green' }} /> : <FileCopyIcon color='primary' />}
                                            </IconButton>
                                            : null
                                    )
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions style={{ padding: '10px 25px' }}>
                    <Button variant='contained' size="small" color='primary' onClick={handleClickGenerateKeySubmit} className={classes.createGroup}
                        startIcon={<VpnKeyIcon />}
                        disabled={!selectedClients?.length || !ClientName.length || !!clientNameError?.length || !EmailAddress?.length || !StartDate || !ExpiryDate || (StartDate > ExpiryDate) ||
                            !!Emailerror.length || !!APIKeyName?.length}>
                        Generate Key
                    </Button>
                    <Button variant='contained' size="small" onClick={handleClose} className={classes.cancelGroup} startIcon={<CancelIcon />}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar className="snackBarStyle" open={isCopy} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={UpdateMessage}>
                <Alert onClose={UpdateMessage} severity="success" className="alertStyle">
                    Copied
                </Alert>
            </Snackbar>
        </React.Fragment >
    );
}

export default CreateKeyManagement