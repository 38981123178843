import { Collapse, IconButton, TableCell, TableRow } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Columns } from '../../../models/Inquiry/config';
import { IDebtor } from '../../../models/NotificationCenter/IDebtor';
import { INotificationCenter } from '../../../models/NotificationCenter/INotificationCenter';
import { useGlobalState } from '../../../store/GlobalStore';
import { useFetch } from '../../../utils/apiHelper';
import LightTooltip from '../../GlobalStyles/LightTooltip';
import useStyles from '../AgentNCCss/AgentNotificationCenterCss';
import InquiryDetail from './AgentPVInquiryDetail';

function usePrevious(data) {
    const ref = React.useRef();
    React.useEffect(() => {
        ref.current = data
    }, [data])
    return ref.current
}

const AgentPvExpandableRow: React.FC<{ pv: INotificationCenter, selected: boolean, onClick: (accountId: string) => void, onRefresh: () => void, selectedDBTNumber: string }> = (props) => {
    const { pv } = props
    const { state } = useGlobalState();
    const classes = useStyles()
    const [debtors, setDebtors] = useState<IDebtor[]>([]);
    const [isActiveStatus, setGetActivestatus] = useState(false);
    const [GetDebtorNo, setGetDebtorNo] = useState<string>();
    const componentRef = useRef();
    const prevCount = usePrevious(props.selectedDBTNumber);

    const GetAccountDetails = () => {
        (async () => {
            await useFetch<IDebtor[]>("GetNotificationDetails?DBTNumber=" + pv?.extraData_1?.slice(0, 9)).then((r) => {
                setDebtors(r?.data);
            });
        })()
    }

    const hidenotificationForAllUsers = () => {
        props.onRefresh();
    }

    useEffect(() => {
        if (prevCount === undefined) {
            setGetDebtorNo("");
        } else if (prevCount === null || prevCount === "") {
            setGetDebtorNo(pv.extraData_1.slice(0, 9));
        } else {
            setGetDebtorNo(prevCount);
        }
    }, [pv.extraData_1.slice(0, 9), prevCount, props.selectedDBTNumber])

    return (
        <React.Fragment>
            <TableRow>
                <TableCell className={classes.tablecellPadding}>
                    <span className={classes.paymentText}>{pv.extraData_1.slice(0, 9)}</span>
                </TableCell>
                <TableCell className={classes.tablecellPadding}>
                    <span className={classes.paymentText}>{pv.extraData_1.slice(10)}</span>
                </TableCell>
                <TableCell className={classes.tablecellPadding}>
                    <span className={classes.paymentText}>{pv.clT_Code}</span>
                </TableCell>
                <TableCell className={classes.tablecellPadding}>
                    <span className={classes.paymentText}>{pv.clT_Name}</span>
                </TableCell>
                <TableCell className={classes.tablecellPadding}>
                    <span className={classes.paymentText}>{pv?.dbT_NAME}</span>
                </TableCell>
                <TableCell className={classes.tablecellPadding} align="center">
                    <span className={classes.paymentText}>{pv?.last_4_of_CC_or_Check}</span>
                </TableCell>
                <TableCell className={classes.tablecellPadding} align="center">
                    <span className={classes.paymentText}>{pv?.payment_Amount}</span>
                </TableCell>
                <TableCell className={classes.tablecellPadding}>
                    <span className={classes.paymentText}>{pv?.payment_Date}</span>
                </TableCell>
                <TableCell className={classes.tablecellPadding}>
                    <span className={classes.paymentText}>{moment(pv.createdDate).format(state.GlobalUtils?.settingValue)}</span>
                </TableCell>
                <TableCell className={classes.tablecellPadding}>
                    <IconButton style={{ padding: '5px' }} onClick={(_) => {
                        props.onClick(pv.extraData_1.slice(0, 9)); GetAccountDetails(); (pv.extraData_1.slice(0, 9) !== props.selectedDBTNumber ? null : setGetDebtorNo(""));
                    }}>
                        {props.selected === true && debtors.length != 0 ?
                            <LightTooltip title=" Verify Balance / Report Payment">
                                <ArrowForwardIosIcon className={classes.forwardArrowMinus} />
                            </LightTooltip>
                            : <LightTooltip title=" Verify Balance / Report Payment">
                                <ArrowForwardIosIcon className={classes.forwardArrowPlus} />
                            </LightTooltip>
                        }
                    </IconButton>
                </TableCell>
            </TableRow>
            {
                debtors.map((sr) => {
                    return (
                        <TableRow className={props.selected ? classes.root : classes.rootHidden} ref={componentRef}>
                            <TableCell scope="row" colSpan={Columns.length} classes={{ root: classes.customTable }}>
                                <Collapse in={props.selected} timeout="auto" unmountOnExit >
                                    <InquiryDetail pv={pv} inquiryDetail={sr} onRefresh={hidenotificationForAllUsers} isBlocked={isActiveStatus} />
                                </Collapse>
                            </TableCell>
                        </TableRow>
                    );
                })
            }
        </React.Fragment >
    )
}

export default AgentPvExpandableRow