import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    // Create adn Edit Template CSS
    root: {
        '& > *': {
            borderBottom: 'unset',
            marginBottom: "0%",
            margin: theme.spacing(2),
            width: '25ch',
            flexGrow: 1,
        },
    },
    formControl: {
        justifyContent: "center",
        display: 'flex',
        width: '100%'
    },
    editbutton: {
        borderRadius: 20,
        fontSize: 12,
        backgroundColor: 'green',
        '&:hover': {
            background: "green",
        }
    },
    updatebutton: {
        borderRadius: 20,
        marginRight: '20px',
        fontSize: 12,
        marginBottom: '0.2cm',
        backgroundColor: 'green',
        '&:hover': {
            background: "green",
        }
    },
    errorMessage: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'red',
        fontSize: 14,
        textAlign: 'center'
    },
    displayFlex: {
        display: 'flex',
        padding: '0px 5px',
        '& .MuiTypography-body1': {
            fontSize: '13px',
        },
        '& .MuiInputLabel-root': {
            fontSize: '13px',
        },
        '& .MuiOutlinedInput-input': {
            fontSize: '13px',
        }
    },
    textAlginEnd: {
        textAlign: 'end',
        fontSize: 12
    },
    floatRight: {
        float: 'right',
        marginTop: '-20px',
    },
    floatLeft: {
        float: 'left',
        marginTop: '-20px'
    },
    cntNameBox: {
        boxShadow: '0px 1px 4px  rgba(0, 0, 0, 0.4)',
        borderRadius: '3px',
        fontSize: 12,
        width: '100%',
    },
    cntNameBox1: {
        boxShadow: '0px 1px 4px  rgba(0, 0, 0, 0.4)',
        borderRadius: '3px',
        fontSize: 12,
        width: '100%',
        marginTop: '8px'
    },
    title: {
        fontWeight: 'bold',
        marginTop: '5px',
        color: 'white',
        fontSize: 18
    },
    createtemplate: {
        borderRadius: 20,
        marginRight: '20px',
        fontSize: 12,
        background: "green",
        padding: '4px 10px',
        color: 'white',
        '&:hover': {
            background: "green",
            color: 'white'
        }
    },
    canceltemplate: {
        borderRadius: 20,
        marginRight: '20px',
        fontSize: 12,
        padding: '4px 10px',
        backgroundColor: 'red',
        color: 'white',
        '&:hover': {
            backgroundColor: "red",
            color: 'white'
        }
    },
    textEnd: {
        textAlign: 'end',
        '& .MuiFormControlLabel-label': {
            fontSize: 13,
        }
    },
    cntButton: {
        borderRadius: 20,
        float: 'right',
        fontSize: 12
    },
    // Template Css
    formTemplateControl: {
        minWidth: 200,
    },
    selecttemplate: {
        width: 550,
        height: 40,
        fontSize: 12,
        marginTop: '-5px',
        '& .MuiFormControl-marginNormal': {
            marginTop: '3px',
        }
    },
    paper1: {
        fontSize: 12,
        border: "2px solid black",
    },
    //Delete Template Css
    yesButton: {
        background: "green",
        color: 'white',
        bottom: '0.2cm',
        fontSize: 12,
        borderRadius: 20,
        '&:hover': {
            background: "green",
        }
    },
    noButton: {
        background: "red",
        display: 'flex',
        bottom: '0.2cm',
        color: 'white',
        fontSize: 12,
        borderRadius: 20,
        '&:hover': {
            background: "red",
        }
    },
    deleteBtn: {
        padding: '10px',
    },
    deleteDisable: {
        color: "gray",
        fontSize: '25px'
    },
    deleteEnable: {
        color: "red",
        fontSize: '25px'
    },
    titleConfirm: {
        color: "black",
        fontSize: 17
    },
    titleMessage: {
        color: "black",
        fontSize: 17,
        marginTop: '5px'
    },
    // GA edit template Css
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    grid: {
        padding: '10px 5px',
        backgroundColor: 'whitesmoke',
    },
    progressBar: {
        height: '6px'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    tablebody: {
        maxHeight: 505,
        minHeight: 505,
        [theme.breakpoints.up('lg')]: {
            maxHeight: 'calc( 100vh - 210px)',
            minHeight: 'calc( 100vh - 210px)',
        },
    },
    heading: {
        color: "#007FFF",
        textAlign: 'left',
        paddingBottom: '5px',
    },
    tableHead: {
        backgroundColor: "#007FFF",
        color: "white",
        fontSize: 12,
        padding: '3px 15px',
        '&:last-child': {
            paddingRight: '30px',
        },
    },
    subHeading: {
        fontSize: '14px',
    },
    tableData: {
        padding: '0px 10px',
        fontSize: 12,
        '&:last-child': {
            paddingRight: '30px',
        }
    },
    clientTemp: {
        flexGrow: 1,
    },
    footer: {
        marginTop: '15px',
        marginBottom: '5px',
        float: 'right',
        marginRight: '70px',
        '& .MuiTypography-body1': {
            fontSize: 12,
        }
    },
    smallfont: {
        fontSize: 13,
        margin: 0
    },
    outlinedInput1: {
        fontSize: 12,
        color: "black",
    },
    dialogePaper: {
        minWidth: "800px"
    },
    tableHeaderStyle: {
        background: "#007FFF",
        color: "white",
        fontSize: "14px"
    },
    tableCellColor: {
        color: "white",
        background: "#007FFF",
        padding: '3px 10px',
        fontSize: '12px'
    },
    btnManTemp: {
        borderRadius: '20px',
        float: 'right',
        fontSize: 12
    },
    userboxviewStyle: {
        display: 'flex',
        borderRadius: '5px',
        borderColor: 'black',
        padding: '8px',
        marginTop: '15px',
        boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
        alignItems: 'floatRight',
        justifyContent: 'floatRight',
        flexDirection: 'row',
        marginLeft: '10px',
        borderTop: '4px solid #3bb78f !important'
    },
    userboxviewStyle1: {
        display: 'flex',
        borderRadius: '5px',
        borderColor: 'black',
        padding: '8px',
        marginTop: '-23px',
        boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
        alignItems: 'floatRight',
        justifyContent: 'floatRight',
        flexDirection: 'row',
        marginLeft: '10px',
        borderTop: '4px solid #3bb78f !important'
    },
    userStyle: {
        fontSize: '15px',
        fontWeight: 'bold',
        backgroundColor: '#e5f5f4',
        borderRadius: '5px',
        padding: '5px',
        textAlign: 'center',
        color: '#03797a'
    },
    userStyleTextBox: {
        fontSize: '16px',
        textAlign: 'right',
        fontWeight: 'bold',
        backgroundColor: '#e5f5f4',
        borderRadius: '5px',
        color: '#03797a',
        padding: '4px',
    },
    userStyleTextBox1: {
        fontSize: '16px',
        textAlign: 'right',
        marginLeft: '4px',
        fontWeight: 'bold',
        backgroundColor: '#e5f5f4',
        borderRadius: '5px',
        color: '#03797a',
        padding: '4px',
    },
    checkboxPermission: {
        fontSize: '12px'
    },
    requiredFiled: {
        backgroundColor: 'white',
        color: '#2164cd',
        borderRadius: '5px',
        marginTop: '5px',
        marginLeft: '0px',
    },
    requiredChecked: {
        backgroundColor: '#ebf2ff',
        color: '#2164cd',
        borderRadius: '5px',
        marginTop: '5px',
        marginLeft: '0px',
    },
    permittedFiled: {
        backgroundColor: 'white',
        color: 'black',
        borderRadius: '5px',
        marginTop: '5px',
        marginLeft: '8px',
    },
    permittedChecked: {
        backgroundColor: '#ebf2ff',
        color: '#2164cd',
        borderRadius: '5px',
        marginTop: '5px',
        marginLeft: '6px',
    },
    logoboxviewStyle: {
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '3px',
        borderColor: 'black',
        padding: '8px',
        marginTop: '15px',
        width: '100%',
        boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
        alignItems: 'center',
        marginLeft: '10px',
        height: '90px',
        borderTop: '4px solid #3bb78f !important'
    },
    browsefile: {
        marginTop: '10px',
        fontSize: 12
    },
    previewfile: {
        marginTop: '5px',
        color: 'black',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        maxWidth: '330px',
        fontSize: '12px',
        textAlign: 'center',
        textOverflow: 'ellipsis'
    },
    previewNoFile: {
        marginTop: '5px',
        color: 'red'
    },
    previewNoFile1: {
        marginTop: '18px',
        color: 'red'
    },
    gridDisplay: {
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex'
    },
    messageDisplay: {
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column'
    },
    removeIcon: {
        padding: '2px',
        marginLeft: '5px'
    },
    gridPadding: {
        padding: '0px 0px 0px 10px'
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1
      }
}));
export { useStyles }