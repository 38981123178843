import axios from 'axios';

// const baseURL = 'https://webviewrpapi-dev.azurewebsites.net/api/';    // DEV URL
const baseURL = 'https://webviewuatapi.armsolutions.com/api/';       // UAT URL

const baseURLDev = 'https://localhost:44316/api/';

export const instance = axios.create({
  baseURL: baseURL,
  headers: {
    'Access-Control-Allow-Origin': '*',
  },
  timeout: 600000,
})
