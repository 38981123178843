import CloseIcon from '@material-ui/icons/Close'
import { Fullscreen } from '@mui/icons-material'
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit'
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import {
    AppBar, Box, Button, Dialog, DialogContent, Grid, IconButton, Paper, Table,
    TableBody, TableContainer, TableHead, Toolbar, Typography
} from '@mui/material'
import moment from 'moment'
import React, { useState } from 'react'
import { ARMLogo } from '../../../constants/Constant'
import { IAgentInquiryHistoryNotesResult } from '../../../models/Inquiry/TransactionHistory/ITransactionHistory'
import { useStyles } from '../../../pages/NotificationCenter/NotificationCenterCss'
import { useGlobalState } from '../../../store/GlobalStore'
import Footer from '../../Footer/Footer'
import { Transition } from '../../GlobalStyles/DialogBoxTransition'
import LightTooltip from '../../GlobalStyles/LightTooltip'
import { StyledTableCellAgentInquiry, StyledTableRowAgentInquiry } from '../../GlobalStyles/TableStyledCell'

const AccountHistoryFullScreen: React.FC<{ accountNumber: string, clientName1: string, AgentInquiryHistoryNotesResult: IAgentInquiryHistoryNotesResult[], Campaign: string }> = (props) => {
    const { accountNumber, clientName1, AgentInquiryHistoryNotesResult, Campaign } = props;
    const { state } = useGlobalState();
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <IconButton onClick={handleOpen} sx={{ mt: "5px" }}>
                <LightTooltip title="FullScreen">
                    <Fullscreen color='primary' />
                </LightTooltip>
            </IconButton>

            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition} className={classes.dialog}>
                <AppBar position="fixed" className={classes.appBar}>
                    <Toolbar className={classes.toolbarStyle}>
                        <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                            <img alt="ARM_Solutions" className={classes.menuButton1} src={ARMLogo} />
                        </IconButton>
                        <Typography variant="body1" className={classes.title}>
                            <b>ACCOUNT HISTORY</b>
                        </Typography>
                        <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close" style={{ padding: '5px' }}>
                            <CloseIcon className={classes.closeIcon} />
                        </IconButton>
                    </Toolbar>
                </AppBar>

                <DialogContent sx={{ mt: 6, padding: "5px 15px" }}>
                    <Grid container spacing={0} mt={0.5} mb={1} >
                        <Grid item xs={4}>
                            <Typography variant='body2' fontWeight={600} color={'#007FFF'} mt={"12px"} ml={"5px"}>
                                <b>ACCOUNT HISTORY - {accountNumber} </b>
                            </Typography>
                        </Grid>
                        <Grid item xs={7.6} display={'flex'} justifyContent={'flex-end'} gap={'10px'}>
                            <Button variant='text' size='small' startIcon={<InfoOutlinedIcon />} sx={{ fontSize: '13px', mt: '10px' }}>
                                <b>Client note to Collector : {clientName1}</b>
                            </Button>

                            <Button variant='text' size='small' startIcon={<HeadsetMicIcon />} sx={{ fontSize: '13px', mt: '10px' }}>
                                <b>{Campaign}</b>
                            </Button>
                        </Grid>
                        <Grid item xs={0.4} display={'flex'} justifyContent={'center'} alignContent={'center'}>
                            <IconButton onClick={handleClose} sx={{ mt: "5px" }}>
                                <LightTooltip title="Exit FullScreen">
                                    <FullscreenExitIcon color='primary' />
                                </LightTooltip>
                            </IconButton>
                        </Grid>
                        <Grid item xs={12}>
                            <TableContainer component={Paper} className={`${classes.accountHistoryContainer} ${"scrollbox"} ${"on-scrollbar"}`} sx={{ mt: "5px", borderRadius: '5px' }}>
                                <Table aria-label="caption table" stickyHeader>
                                    <TableHead className="sticky-header-agent">
                                        <StyledTableRowAgentInquiry className="sticky-row-agent">
                                            <StyledTableCellAgentInquiry align='center' rowSpan={2} >Account</StyledTableCellAgentInquiry>
                                            <StyledTableCellAgentInquiry align="center" rowSpan={2} >Date</StyledTableCellAgentInquiry>
                                            <StyledTableCellAgentInquiry align="center" rowSpan={2} >Time</StyledTableCellAgentInquiry>
                                            <StyledTableCellAgentInquiry align="center" rowSpan={2} >Day of Week</StyledTableCellAgentInquiry>
                                            <StyledTableCellAgentInquiry align="center" rowSpan={2} >User</StyledTableCellAgentInquiry>
                                            <StyledTableCellAgentInquiry align="center" rowSpan={2} >Account History Notes</StyledTableCellAgentInquiry>
                                            <StyledTableCellAgentInquiry align="center" rowSpan={2} >ACCOUNT FOLLOW UP NEXT WORK DATE</StyledTableCellAgentInquiry>
                                            <StyledTableCellAgentInquiry align="center" colSpan={3} >Letters</StyledTableCellAgentInquiry>
                                            <StyledTableCellAgentInquiry align="center" colSpan={3} >Dispositions</StyledTableCellAgentInquiry>
                                        </StyledTableRowAgentInquiry>
                                        <StyledTableRowAgentInquiry className="sticky-row-agent">
                                            <StyledTableCellAgentInquiry align="center" >Series</StyledTableCellAgentInquiry>
                                            <StyledTableCellAgentInquiry align="center" >Next</StyledTableCellAgentInquiry>
                                            <StyledTableCellAgentInquiry align="center" >Letter #</StyledTableCellAgentInquiry>
                                            <StyledTableCellAgentInquiry align="center" >Desk</StyledTableCellAgentInquiry>
                                            <StyledTableCellAgentInquiry align="center" >Status</StyledTableCellAgentInquiry>
                                            <StyledTableCellAgentInquiry align="center" >History Type</StyledTableCellAgentInquiry>
                                        </StyledTableRowAgentInquiry>
                                    </TableHead>
                                    <TableBody>
                                        {AgentInquiryHistoryNotesResult?.map((r) => (
                                            <StyledTableRowAgentInquiry className="styled-table-row-agent">
                                                <StyledTableCellAgentInquiry align='center'>{r?.debtorId}</StyledTableCellAgentInquiry>
                                                <StyledTableCellAgentInquiry align='center'>{r?.hst_date ? moment(r.hst_date).format(state.GlobalUtils?.settingValue) : '-'}</StyledTableCellAgentInquiry>
                                                <StyledTableCellAgentInquiry align='center'> {r?.hsT_TIME.length === 4 ? `${r.hsT_TIME.slice(0, 2)}:${r.hsT_TIME.slice(2)}` : r?.hsT_TIME}</StyledTableCellAgentInquiry>
                                                <StyledTableCellAgentInquiry align='center'>{r?.dayOfTheWeek ? r?.dayOfTheWeek : '-'}</StyledTableCellAgentInquiry>
                                                <StyledTableCellAgentInquiry align='center'>{r?.hsT_USR_CODE ? r?.hsT_USR_CODE : '-'}</StyledTableCellAgentInquiry>
                                                <StyledTableCellAgentInquiry align='left'>{r?.hsT_NOTE ? r?.hsT_NOTE : <Typography variant='body2' textAlign={'center'}>-</Typography>}</StyledTableCellAgentInquiry>
                                                <StyledTableCellAgentInquiry align='center'>{r?.hsT_NEXT_WORK_DATE ? moment(r.hsT_NEXT_WORK_DATE).format(state.GlobalUtils?.settingValue) : '-'}</StyledTableCellAgentInquiry>
                                                <StyledTableCellAgentInquiry align='center'>{r?.hsT_LSE_CODE ? r?.hsT_LSE_CODE : '-'}</StyledTableCellAgentInquiry>
                                                <StyledTableCellAgentInquiry align='center'>{r?.hsT_NEXT_LTR_DATE ? moment(r.hsT_NEXT_LTR_DATE).format(state.GlobalUtils?.settingValue) : '-'}</StyledTableCellAgentInquiry>
                                                <StyledTableCellAgentInquiry align='center'>{r?.hsT_LSE_NO ? r?.hsT_LSE_NO : '-'}</StyledTableCellAgentInquiry>
                                                <StyledTableCellAgentInquiry align='center'>{r?.hsT_DSK_CODE ? r?.hsT_DSK_CODE : '-'}</StyledTableCellAgentInquiry>
                                                <StyledTableCellAgentInquiry align='center'>{r?.dbT_STATUS ? r?.dbT_STATUS : '-'}</StyledTableCellAgentInquiry>
                                                <StyledTableCellAgentInquiry align='left'>{r?.histType ? r?.histType : '-'}</StyledTableCellAgentInquiry>
                                            </StyledTableRowAgentInquiry>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item xs={12}>
                            <Box mt={1.5}>
                                <Footer />
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}

export default AccountHistoryFullScreen