import { TablePagination } from '@material-ui/core';
import CloseIcon from "@material-ui/icons/Close";
import HistoryIcon from '@mui/icons-material/History';
import {
    AppBar, Backdrop, Dialog, DialogContent, Grid, IconButton, Paper, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow, TableSortLabel, Toolbar, Typography
} from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react';
import { PuffLoader } from 'react-spinners';
import { Transition } from '../../components/GlobalStyles/DialogBoxTransition';
import useStyles from '../../components/GlobalStyles/DialogFullViewBoxCss';
import { ARMLogo } from "../../constants/Constant";
import { ITrustStatementHistory, ITrustStatementReportFile } from '../../models/Reports/ITrustStatementReportFile';
import { useGlobalState } from '../../store/GlobalStore';
import { usePost } from '../../utils/apiHelper';
import { useStylesTrustStatementReport } from './TrustStatementReportCss';

const TrustStatementHistory: React.FC<{ row: ITrustStatementReportFile }> = (props) => {
    const { row } = props;
    const classes = useStyles();
    const { state } = useGlobalState();
    const classes1 = useStylesTrustStatementReport()
    const [open, setOpen] = useState(false);
    const [ProgressBar, setShowProgressBar] = useState(false);
    const [trustStatementHistory, setTrustStatementHistory] = useState<ITrustStatementHistory[]>([]);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [page, setPage] = useState(0);

    const handleClickOpen = () => {
        setOpen(true);
        getProspectHistory();
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
    };

    const getProspectHistory = () => {
        setShowProgressBar(true);
        (async () => {
            let request = {
                "fileId": row?.fileId,
            };
            await usePost<{ trustStatementFileAuditReportResult: ITrustStatementHistory[], totalCount: number }>("GetTrustStatementFileAuditReport", request).then((result) => {
                setTrustStatementHistory(result?.data["trustStatementFileAuditReportResult"]);
                setTotalCount(result?.data["totalCount"]);
            }).finally(() => {
                setShowProgressBar(false);
            });
        })();
    };

    const headCells = [
        { id: "actionName", numeric: false, disablePadding: false, label: "ACTION TYPE", sortable: true, fontSize: '12px' },
        { id: "actionText", numeric: false, disablePadding: false, label: "ACTION DESCRIPTION", sortable: true, fontSize: '12px' },
        { id: "errorName", numeric: false, disablePadding: false, label: "ERROR DESCRIPTION", sortable: true, fontSize: '12px' },
        { id: "modifiedDate", numeric: false, disablePadding: false, label: "MODIFIED DATE", sortable: true, fontSize: '12px' },
    ];

    function EnhancedTableHead(props) {
        const {
            classes,
            order,
            orderBy,
            onRequestSort,
        } = props;
        const createSortHandler = property => event => {
            onRequestSort(event, property);
        };

        return (
            <TableHead>
                <TableRow>
                    {headCells?.map((headCell) => (
                        <TableCell
                            key={headCell?.id}
                            sortDirection={orderBy === headCell?.id ? order : false}
                            className={classes1.TableCellHeadStyle}
                        >
                            <TableSortLabel
                                active={orderBy === headCell?.id}
                                direction={orderBy === headCell?.id ? order : "asc"}
                                onClick={createSortHandler(headCell?.id)}
                            >
                                {headCell?.label || headCell?.sortable}
                                {orderBy === headCell?.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === "desc"
                                            ? "sorted descending"
                                            : "sorted ascending"}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    return (
        <React.Fragment>
            <IconButton aria-label="history-icon" size='small' onClick={handleClickOpen}>
                <HistoryIcon color='secondary' fontSize='small' />
            </IconButton>

            <Dialog
                fullScreen
                className={classes.dialog}
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar position="fixed" className={classes.appBar}>
                    <Toolbar className={classes.toolbarStyle}>
                        <IconButton
                            edge="start"
                            className={classes.menuButton}
                            color="inherit"
                            aria-label="menu"
                        >
                            <img
                                alt="ARM_Solutions"
                                className={classes.menuButton1}
                                src={ARMLogo}
                            />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            <b>{row?.fileName} History</b>
                        </Typography>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={() => setOpen(false)}
                            aria-label="close"
                            style={{ padding: "5px" }}
                        >
                            <CloseIcon className={classes1.closeIconButton} />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Backdrop className={classes.backdrop} open={ProgressBar}>
                    <PuffLoader size={80} color={"white"} speedMultiplier={1} />
                </Backdrop>
                <DialogContent>
                    <Grid container spacing={0} >
                        <Grid item xs={12}>
                            <TableContainer component={Paper} className={`${classes1.tablebody1} ${"scrollbox"} ${"on-scrollbar"}`}                    >
                                <Table size="small" aria-label="customized table" stickyHeader>
                                    <EnhancedTableHead classes={classes} />
                                    {trustStatementHistory?.map((row) => (
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>
                                                    <Typography variant='body2' className={classes1.textStyle} >{row?.actionName}</Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant='body2' className={classes1.textStyle} >{row?.actionText}</Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant='body2' className={classes1.textStyle} >{row?.errorName}</Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant='body2' className={classes1.textStyle} >
                                                        {moment(row?.modifiedDate).format(state.GlobalUtils?.settingValue)}  {moment(row?.modifiedDate).format('HH:mm:ss')}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    ))}
                                </Table>
                                {!trustStatementHistory?.length ? (
                                    <Typography
                                        variant="h6"
                                        align="center"
                                        gutterBottom
                                        className={classes1.ErrorMessageStyle1}
                                    >
                                        No history to display..
                                    </Typography>
                                ) : null}
                            </TableContainer>
                        </Grid>
                        <Grid item xs={12} component={Paper} borderTop={'1px solid lightgray'} borderRadius={"0px"}>
                            <TablePagination
                                id="History_TablePagination"
                                rowsPerPageOptions={[20, 50, 100]}
                                component="div"
                                count={totalCount === undefined ? 0 : totalCount}
                                rowsPerPage={rowsPerPage}
                                style={{ marginRight: "40px" }}
                                page={page}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}

export default TrustStatementHistory