import {
    Button, CircularProgress, Dialog, DialogActions, Grid,
    IconButton, TextField, Typography
} from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Snackbar from '@material-ui/core/Snackbar';
import ReplayIcon from "@material-ui/icons/Replay";
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import React, { useEffect, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { IGetRolledPlacement } from '../../../models/Inquiry/IGetRolledPlacement';
import { IPlacedAccount } from '../../../models/Inquiry/IPlacedAccount';
import { IPlacedAccountContract } from "../../../models/Inquiry/IPlacedAccountContract";
import { GlobalStateAction, useGlobalState } from '../../../store/GlobalStore';
import { useDelete, useFetch, usePost } from '../../../utils/apiHelper';
import { DialogTitleHeader } from '../../GlobalStyles/DialogStyle';
import useStyles from '../NCCcss/NotiCenterCss';
import { Transition } from '../../GlobalStyles/DialogBoxTransition';
import { IInsertTransactionLog } from '../../../models/UpdateHistory/GetTransactionOptions';
import { InsertTransactionLog } from '../../TransactionLogs/InsertTransactionLog';

const CloseAccount: React.FC<{ dbtNumber: string, dbT_PRINCIPAL_DUE: number, Disable: IGetRolledPlacement, clientId: string, onEroll: () => void, onSubmit: () => void, closeSts: boolean, BlockAwaitAccess: boolean, closeReason: string, closedDetails: boolean, undoStatus: number }> = (props) => {
    const { closedDetails, undoStatus } = props;
    const classes = useStyles();
    const { state, dispatch } = useGlobalState();
    const [clicked, setClicked] = useState(false);
    const [showUndoButton, setshowUndoButton] = useState(false);
    const [inputVal, setinputVal] = useState<any>('');
    const [CloseNote, setCloseNote] = useState<any>('');
    const [closeAcctBal, setcloseAcctBal] = useState<any>();
    const [closeAcctReason, setValue] = useState('');
    const canCloseAccount = state.userAccessContext?.closingAccounts;
    const [Removed, setRemoved] = useState(closedDetails);
    const UserName = state.userAccessContext?.id;
    const [open, setOpen] = useState(false);
    const [principalPaymentAmount, setPrincipalPaymentAmount] = useState<any>(0);
    const [updatedPrincipalAmount, setUpdatedPrincipalAmount] = useState(props.dbT_PRINCIPAL_DUE);
    const [principalPaymentDue, setPrincipalPaymentDue] = useState<number>(props.dbT_PRINCIPAL_DUE);
    const [placedAccounts, setPlacedAccounts] = useState<IPlacedAccount[]>([]);
    const [isValid, setIsValid] = useState(true);
    const [isReasonValid, setReasonValid] = useState('');
    const [close, setClose] = useState(false);
    const [undoClose, setUndoClose] = useState(false);
    const [principalAmount, setPrincipalAmount] = useState<any>(0);
    const [buttonLoading, setButtonLoading] = useState(false);

    const preventMinus = (e) => {
        if (e.code === 'Minus' || e.code === 'NumpadSubtract') {
            e.preventDefault();
        }
    };

    const handleOnValueChange = (value: string): void => {
        setPrincipalPaymentAmount(value === '0' || '' ? setIsValid(true) : value || '');
        setcloseAcctBal(value === '0' || '' ? setIsValid(true) : value || '');
        setinputVal(value === '0' || '' ? setIsValid(true) : value || '');
    };

    const handleReasonNote = (event) => {
        setCloseNote(event.target.value);
    };

    const handleReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
        setReasonValid(event.target.value);
    };

    const handleClose = () => {
        setOpen(false);
        setCloseNote('');
        setinputVal('');
        setValue('');
        setReasonValid('');
        setcloseAcctBal('');
    };

    function emptyCheck() {
        setCloseNote('');
        setinputVal('');
        setValue('');
        setReasonValid('');
        setcloseAcctBal('');
        setClose(false);
    }

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setClose(false);
        setUndoClose(false);
    };

    useEffect(() => {
        setUpdatedPrincipalAmount(Number((principalPaymentDue - closeAcctBal).toFixed(2)));
    }, [principalPaymentDue, closeAcctBal, principalPaymentAmount])

    useEffect(() => {
        closeAcctReason == 'Paid' ? ((closeAcctBal > props.dbT_PRINCIPAL_DUE) ? setIsValid(false) : setIsValid(true)) : setIsValid(true)
    }, [updatedPrincipalAmount, closeAcctReason])

    useEffect(() => {
        let principal_amount_due = props.dbT_PRINCIPAL_DUE;
        for (let account of placedAccounts) {
            principal_amount_due = (principal_amount_due - account.prcpl_Adj_Amt - account.prpcl_Pay_Amt);
        }
        setPrincipalPaymentDue(Number(principal_amount_due.toFixed(2)));
    }, [placedAccounts])

    const PaymentAmountValidation = () => {
        (async () => {
            await useFetch<IPlacedAccount[]>(`Account/PlacedAccounts?DBTNumber=${props.dbtNumber}`).then((PlacedAccounts) => {
                setPlacedAccounts(PlacedAccounts.data);
            });
        })()
    }

    const CloseAccount = () => {
        setButtonLoading(true);
        (async () => {
            let CloseBalance = parseFloat(closeAcctBal);
            let request = {
                "dbtNumber": props.dbtNumber,
                "close_acct_note": CloseNote,
                "close_acct_bal": CloseBalance,
                "close_acct_reason": closeAcctReason,
                "user_Id": UserName,
                "isCloseRemove": false,
                "isClosed": true
            };

            if (closeAcctReason == 'Paid') {
                let Principal = ((principalPaymentDue - CloseBalance).toFixed(2));
                let requestPaid = {
                    "prcpl_Pay_Amt": Principal,
                    "close_acct_note": CloseNote,
                    "dbtNumber": props.dbtNumber,
                    "close_acct_reason": closeAcctReason,
                    "close_acct_bal": CloseBalance,
                    "user_Id": UserName,
                    "isCloseRemove": false,
                    "isClosed": true
                };
                await usePost<IPlacedAccountContract[]>('Account/ClosedPlacedAccounts', requestPaid).finally(() => {
                    setButtonLoading(false);
                });
                AccountClosed();
                setRemoved(true);
                props.onSubmit();
                setPrincipalAmount(Principal);
                setClose(true);
            }
            else {
                await usePost<IPlacedAccountContract[]>('Account/EmptyPlacedAccounts', request).finally(() => {
                    setButtonLoading(false);
                });
                AccountClosed();
                setRemoved(true);
                setClose(true);
            }
        })()
    }

    const AccountClosed = () => {
        (async () => {
            props.onEroll();
            setRemoved(true);
            setshowUndoButton(!showUndoButton);

            if (closeAcctReason == 'Paid') {
                let feedOperation = [];
                let operations: IInsertTransactionLog = {
                    operationName: "Payment", operationText: `Payment to Principal: $${(props.dbT_PRINCIPAL_DUE - closeAcctBal).toFixed(2)}, Closing Note: ${CloseNote}, Closing Balance: $${closeAcctBal}, Closing Reason: ${closeAcctReason}`
                };
                feedOperation.push(operations);
                InsertTransactionLog(state?.userAccessContext?.id, props?.dbtNumber, feedOperation, false);
            } else {
                let feedOperation = [];
                let operations: IInsertTransactionLog = {
                    operationName: "Close Account", operationText: `Closing Note: ${CloseNote}, Closing Balance: $${closeAcctBal}, Closing Reason: ${closeAcctReason}`
                };
                feedOperation.push(operations);
                InsertTransactionLog(state?.userAccessContext?.id, props?.dbtNumber, feedOperation, false);
            }
        })()
    }

    const undoCloseandRemove = () => {
        (async () => {
            let request = {
                "DBTNumber": props.dbtNumber,
                "isCloseRemove": false,
                "isClosed": true
            }
            await usePost("Account/PlacedAccounts", request).then((r) => {
                if (r.status == 400) {
                    dispatch({ type: GlobalStateAction.Error, error: r.statusText });
                }
            });
            await useDelete(`RollUpdateCloseUndo?DBTNumber=${props.dbtNumber}`);
            props.onEroll();
            setshowUndoButton(false);
            setUndoClose(true);
            if (props?.closeReason === "Paid") {
                undoAdjustment(principalAmount);
            }

            let feedOperation = [];
            let operations: IInsertTransactionLog = {
                operationName: "Undo Closed Account", operationText: "Close Account: Cancelled"
            };
            feedOperation.push(operations);
            InsertTransactionLog(state?.userAccessContext?.id, props?.dbtNumber, feedOperation, false);
        })().finally(() => {
            props.onEroll();
        })
    }

    const undoAdjustment = (adjustmentAmount: number) => {
        (async () => {
            let request = {
                "Dbt_No": props.dbtNumber,
                "AdjustmentAmount": adjustmentAmount,
                "AdjustmentType": "Principal Payment"
            }
            await usePost<any>("Account/UndoAdjustment", request);
            props.onSubmit();
        })().finally(() => {
            props.onSubmit();
        })
    }

    return (
        <React.Fragment>
            <Button id="Inquiry_closeAccount" size="small" variant="contained" onClick={() => { setOpen(true); PaymentAmountValidation(); }}
                className={`${classes.button} ${classes.buttoncloseSpacing}`}
                startIcon={<AccountBalanceIcon />}
                disabled={!canCloseAccount || Removed || props.closeSts || props.BlockAwaitAccess || closedDetails} color="primary">
                {Removed || closedDetails ? "Account Closed" : "Close Account"}
                {buttonLoading === true ? <CircularProgress size="small" style={{ color: 'white' }} className={classes.circleStyle} /> : null}
            </Button>
            {(Removed || closedDetails) && (
                undoStatus != 1 && !closedDetails ? null :
                    <IconButton
                        id="Inquiry_UndoCloseAccount"
                        color="primary"
                        aria-label="add to shopping cart"
                        onClick={() => {
                            setshowUndoButton(!showUndoButton);
                            setRemoved(false);
                            undoCloseandRemove();
                            setUndoClose(true);
                        }}
                        disabled={props.BlockAwaitAccess}
                        className={classes.iconSpacingTop}>
                        <ReplayIcon />
                    </IconButton>
            )}

            <Dialog TransitionComponent={Transition} open={open} keepMounted onClose={handleClose}
                classes={{ paper: classes.dialogePaper }}
                PaperProps={{ style: { borderRadius: 15 } }}
                aria-labelledby="alert-dialog-slide-title" aria-describedby="alert-dialog-slide-description">
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                    <Typography variant="h5" className={classes.titleheader}>
                        CLOSE ACCOUNT
                    </Typography>
                </DialogTitleHeader>
                <DialogTitle>
                    {!isValid ?
                        <Alert severity="error" className="snackBarStyle">Please enter a payment amount that is less than the amount due.</Alert> : null}
                </DialogTitle>
                <Grid container spacing={0}>
                    <Grid item xs={7}>
                        <TextField
                            id="I_CA_Description"
                            placeholder="Why is the account being Closed?"
                            size="medium"
                            multiline
                            rows={7}
                            value={CloseNote} onChange={handleReasonNote}
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            className={classes.description}
                        />
                    </Grid>
                    <Grid item xs={5} >
                        <form >
                            <CurrencyInput
                                className={classes.inputField}
                                id="I_CA_Balance"
                                placeholder="$ Current Balance Amount in Your System"
                                value={closeAcctBal}
                                prefix='$'
                                decimalsLimit={2}
                                onValueChange={handleOnValueChange}
                                intlConfig={{ locale: 'en-US', currency: 'USD' }}
                                onKeyPress={preventMinus}
                                decimalScale={2}
                                maxLength={9}
                            />
                        </form>
                        <FormControl component="fieldset" className={classes.formcontrol}>
                            <Grid container spacing={0}>
                                <Grid item xs={6}>
                                    <RadioGroup id="I_CA_Reason" aria-label="reason" name="reason" value={closeAcctReason} onChange={handleReasonChange} className={classes.formcontrol1}>
                                        <FormControlLabel value="Paid" control={<Radio />} label="Paid" />
                                        <FormControlLabel value="Bankruptcy" control={<Radio />} label="Bankruptcy" />
                                    </RadioGroup>
                                </Grid>
                                <Grid item xs={6}>
                                    <RadioGroup id="I_CA_Reason" aria-label="reason" name="reason" value={closeAcctReason} onChange={handleReasonChange} className={classes.formcontrol2}>
                                        <FormControlLabel value="Disputing" control={<Radio />} label="Disputing" />
                                        <FormControlLabel value="Other" control={<Radio />} label="Other" />
                                    </RadioGroup>
                                </Grid>
                            </Grid>
                        </FormControl>
                    </Grid>
                </Grid>
                <DialogActions className={classes.dialogSpacingLeft}>
                    <Button id="I_CA_Confirm" variant="contained" size="small" className={classes.confirmButton}
                        style={{ backgroundColor: !inputVal || !isValid || !isReasonValid ? "#e0e0e0" : "green" }} startIcon={<CheckCircleIcon />}
                        onClick={() => { setOpen(false); setClicked(!clicked); setshowUndoButton(!showUndoButton); CloseAccount(); emptyCheck(); }}
                        disabled={!inputVal || !isValid || !isReasonValid}>
                        Confirm
                    </Button>
                    <Button id="I_CA_Close" variant="outlined" size="small" className={classes.cancelButton} startIcon={<CancelIcon />}
                        onClick={() => { handleClose(); setcloseAcctBal(''); }}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar className="snackBarStyle" open={close} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={closeSnackbar}>
                <Alert onClose={closeSnackbar} severity="error" className="alertStyle">
                    Account Closed Successfully!
                </Alert>
            </Snackbar>
            <Snackbar className="snackBarStyle" open={undoClose} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={closeSnackbar}>
                <Alert onClose={closeSnackbar} severity="success" className="alertStyle">
                    Undo Close Account Successfully!
                </Alert>
            </Snackbar>
        </React.Fragment >
    )
}

export default CloseAccount