import { createStyles, makeStyles, TableCell, TableRow, Theme } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { IAckBuilder } from '../../models/IAckReport/IAckBuilder';
import { useGlobalState } from '../../store/GlobalStore';

const useRowStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            fontSize: 12,
        },
        rootHidden: {
            display: "none"
        },
        collapsePadding: {
            padding: '8px'
        },
        tablefont: {
            fontSize: 12
        }
    })
);

const AckBuilderExpandableRow: React.FC<{ row: IAckBuilder }> = (props) => {
    const classes = useRowStyles();
    const { row } = props;
    const { state } = useGlobalState();

    return (
        <React.Fragment>
                        <TableRow key={row?.dbT_CLNT_ACNT1}>
                            <TableCell className={classes.tablefont}>{row?.dbT_COMPANY}</TableCell>
                            <TableCell className={classes.tablefont}>{row?.placemenT_DATE !== null ? moment(row?.placemenT_DATE).format(state.GlobalUtils?.settingValue) : row?.placemenT_DATE}</TableCell>
                            <TableCell className={classes.tablefont}>{row?.dbT_CLIENT}</TableCell>
                            <TableCell className={classes.tablefont}>{row?.dbT_NO}</TableCell>
                            <TableCell className={classes.tablefont}>{row?.dbT_CLNT_ACNT1}</TableCell>
                            <TableCell className={classes.tablefont}>{row?.dbT_NAME}</TableCell>
                            <TableCell className={classes.tablefont}>{row?.dbT_LAST_CHG_DATE !== null ? moment(row?.dbT_LAST_CHG_DATE).format(state.GlobalUtils?.settingValue) : row?.dbT_LAST_CHG_DATE}</TableCell>
                            <TableCell className={classes.tablefont}>${row?.amounT_PLACED?.toFixed(2)}</TableCell>
                            <TableCell className={classes.tablefont}>{moment(row?.dbT_REFERRAL_DATE).format(state.GlobalUtils?.settingValue)}</TableCell>
                            <TableCell className={classes.tablefont}>{moment(row?.dbT_PC_REFERRAL_DATE).format(state.GlobalUtils?.settingValue)}</TableCell>
                            <TableCell className={classes.tablefont}>{row?.status}</TableCell>
                            <TableCell className={classes.tablefont}>{row?.statuS_DESCRIPTION}</TableCell>
                            <TableCell className={classes.tablefont}>{moment(row?.statuS_CHANGE_DATE).format(state.GlobalUtils?.settingValue)}</TableCell>
                            <TableCell className={classes.tablefont}>{row?.desk}</TableCell>
                        </TableRow>
        </React.Fragment >
    );
}

export default AckBuilderExpandableRow;
