import { makeStyles } from '@material-ui/core/styles';
const MapRuleCss = makeStyles((theme) => ({
    listItemStyle: {
        backgroundColor: 'white !important',
        '&:hover': {
            background: "white !important",
        }
    },
    TableCellStyle: {
        background: "#007FFF !important",
        color: "white!important",
        fontSize: "13px !important",
        padding: '5px 15px !important',
        TextAlign: 'left !important'
    },
    TableCellStyle1: {
        fontSize: "13px !important",
        padding: '2px 15px !important',
    },
    TableCellBody: {
        fontSize: "13px !important",
        padding: '4px 15px !important',
        borderBottom: 'none !important'
    },
    tablebody: {
        maxHeight: 500,
        minHeight: 500,
        [theme.breakpoints.up('lg')]: {
            maxHeight: 'calc( 100vh - 210px)',
            minHeight: 'calc( 100vh - 125px)',
        },
    },
    isDataValid: {
        backgroundColor: '#c5eedd',
        color: "#007a5c",
        borderRadius: '20px',
        padding: '3px 10px',
        fontWeight: 'bold',
        minWidth: '100px !important'
    },
    isDataInvalid: {
        backgroundColor: '#fbc5bc',
        color: '#c5280c',
        borderRadius: '20px',
        padding: '3px 10px',
        fontWeight: 'bold',
        minWidth: '100px !important'
    },
    noRecordStyle: {
        color: "red",
        marginTop: "10px",
        textAlign: 'center'
    },
    NoAccess: {
        color: 'red'
    },
    dropdownClient: {
        float: 'left',
        fontSize: 13,
        backgroundColor: 'white',
        overflow: 'hidden',
        borderRadius: 5,
    },
    paper1: {
        border: "1px solid black",
        fontSize: '13px !important'
    },
    smallFont1: {
        fontSize: 13,
        textOverflow: 'ellipsis'
    },
    outlinedInput1: {
        border: '1px solid blue',
        '&$focused $notchedOutline': {
            border: 'none',
        },
    },
}));
export { MapRuleCss };