import {
  Backdrop, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText,
  FormControl, Grid, makeStyles, Select, TextField, useMediaQuery, useTheme
} from '@material-ui/core';
import * as _ from 'lodash';
import momentTz from "moment-timezone";
import React, { useEffect, useState } from 'react';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
// Icons
import DvrIcon from '@mui/icons-material/Dvr';
import InfoIcon from '@material-ui/icons/Info';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
// Models
import { IClientPhase } from '../models/Placements/IClientPhase';
import { IUserTemplate } from '../models/Placements/IUserTemplate';
import { IAdminGetClients } from '../models/UploadLog/AdminGetClients';
import { IClientList } from '../models/Admin/ClientPlacements/IClientList';
import { IClientTemplate } from '../models/Admin/ClientPlacements/IClientTemplate';
import { ISearchDuplicateFileName } from "../models/Placements/ISearchDuplicateFileName";
import { IManageTemplateLogo } from '../models/Admin/ClientPlacements/IManageTemplateLogo';
// Componants
import { PuffLoader } from 'react-spinners';
import { useHistory } from 'react-router-dom';
import Footer from '../components/Footer/Footer';
import { baseImage } from '../constants/Constant';
import { videoFile } from '../constants/Constant';
import { base64toBlob } from '../utils/base64toBlob';
import { useFetch, usePost, usePostProgress } from '../utils/apiHelper';
import { videoPlacementTitle } from '../constants/Constant';
import CSVFileReader from '../components/common/CSVFileReader';
import VideoPlayer from '../components/YouTubePlayer/VideoPlayer';
import LightTooltip from "../components/GlobalStyles/LightTooltip";
import DocumentPDFViewer from './Placements/DocumentPreview/DocmentPDFViewer';
import { GlobalStateAction, useGlobalState } from '../store/GlobalStore';
import { Transition } from '../components/GlobalStyles/DialogBoxTransition';
import { DialogTitleDelete, DialogTitleHeader } from '../components/GlobalStyles/DialogStyle';
import RadialProgress from './FileProgressBar';
import CircularProgressWithLabel from './ButtonProgressBar';

const useStyles = makeStyles((theme) => ({
  divStyle: {
    padding: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxHeight: 800,
    minHeight: 800,
    [theme.breakpoints.up('lg')]: {
      maxHeight: 'calc( 100vh - 125px)',
      minHeight: 'calc( 100vh - 125px)',
    }
  },
  secondGrid: {
    backgroundColor: '#ffffff',
    padding: '8px',
    marginTop: '5px',
    borderTopLeftRadius: 40,
    borderBottomRightRadius: 40,
    border: '2px solid gray',
    boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
    width: '65%',
    minHeight: 450
  },
  formControl: {
    minWidth: 420,
    marginRight: theme.spacing(1),
    alignItems: "left"
  },
  paper: {
    borderRadius: 5,
    paddingBottom: "8px",
    paddingTop: "8px",
    fontWeight: 'bold',
    fontSize: 12
  },
  enterManually: {
    borderRadius: 5,
    paddingBottom: "8px",
    paddingTop: "8px",
    fontWeight: 'bold',
    float: 'left',
    marginLeft: '60px',
    fontSize: 12
  },
  titleheader: {
    fontWeight: 'bold',
    marginTop: '5px',
    color: 'white'
  },
  title: {
    textAlign: 'left',
    marginTop: '10px',
    marginLeft: '5px'
  },
  video: {
    float: 'right',
    marginTop: '45px'
  },
  videoPadding: {
    float: 'right',
    marginTop: '55px'
  },
  displaymessage: {
    color: 'red',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '60px',
    fontSize: 13
  },
  dropdownClient: {
    width: 315,
    float: 'left',
    fontSize: 13,
    marginLeft: '2.1cm',
    border: '2px solid blue',
    backgroundColor: 'white',
    overflow: 'hidden',
    borderRadius: 5,
  },
  placementTitle: {
    float: 'left',
    color: 'blue',
    marginTop: '8px',
    marginLeft: '50px',
    fontWeight: 'bold'
  },
  NoAccess: {
    color: 'red'
  },
  paper1: {
    border: "2px solid black",
    fontSize: 13
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  backdrop1: {
    zIndex: theme.zIndex.drawer + 2,
    color: '#fff',
  },
  MessageStyle: {
    marginTop: "15px",
    color: "black",
  },
  yesButton: {
    background: "red",
    color: 'white',
    borderRadius: 20,
    right: '15px',
    '&:hover': {
      background: "red",
    }
  },
  outlinedInput1: {
    border: '1px solid blue',
    '&$focused $notchedOutline': {
      border: 'none',
    },
  },
  smallFont1: {
    fontSize: 13,
    textOverflow: 'ellipsis'
  },
  pdfPreview: {
    marginLeft: '-25px',
  },
  circleStyle: {
    color: 'white',
    height: "20px",
    width: "20px",
    marginLeft: '10px'
  },
  infoicon: {
    fontSize: 20,
    float: 'right',
  },
  gridDisplay: {
    display: 'flex',
    justifyContent: 'flex-start'
  },
  imageLogo: {
    marginLeft: '50px'
  },

}))

const Placements = () => {
  const theme = useTheme();
  let history = useHistory();
  const classes = useStyles();
  const { state, dispatch } = useGlobalState();

  const [manageTemplateLogo, setManageTemplateLogo] = useState<IManageTemplateLogo>();
  const [placementClientList, setPlacementClientList] = useState<IClientList[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<IUserTemplate>();
  const [userTemplate, setUserTemplate] = useState<IUserTemplate[]>([]);
  const [selectedClient, setSelectedClient] = useState<IClientList>();
  const [clientPhase, setClientPhase] = useState<IClientPhase[]>([]);
  const [clientList, setClientList] = useState<IClientList[]>([]);
  const [selectedPhase, setSelectedPhase] = useState<number>(-1);
  const [imgUrl, setImgUrl] = useState<any>();

  const [disableEntermanually, setDisableEnterManually] = useState(false);
  const [FileUploadSnackBar, setFileUploadSnackBar] = useState(false);
  const [selectedMultiCode, setSelectedMultiCode] = useState(false);
  const [disabledUploadCSV, setDisableUploadCSV] = useState(false);
  const [displayMessage, setdisplayMessage] = useState(false);
  const [disableManual, setDisableManual] = useState(false);
  const [selectedCode, setSelectedCode] = useState(false);
  const [openCloud, setOpenCloud] = React.useState(false);
  const [selectPhase, setSelectPhase] = useState(false);
  const [disableAll, setDisableAll] = useState(false);
  const [showPhase, setShowPhase] = useState(true);
  const [isActive, setIsActive] = useState(false);

  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const placeAccount = state.userAccessContext?.placeAccounts;
  const [uploadedFile, setUploadedFile] = useState<any>();
  const [fileName, setFileName] = useState<string>('');
  const [phase, setPhase] = React.useState<string>();
  const clientsLoading = clientList.length === 0;
  const [getStates, setGetStates] = useState([]);
  const UserName = state.userAccessContext?.id;
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [subFId, setSubFId] = useState(0);
  const [clientName, setClientName] = useState<string>("");
  const [allianceName, setAllianceName] = useState<any>("");
  const [progress, setProgress] = useState(0);
  const [progressLoad, setProgressLoad] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setFileUploadSnackBar(false);
  };

  useEffect(() => {
    if (clientList.length <= 0) {
      LoadClientCodeList();
      LoadPlacementClientCodeList();
    }
  }, [clientList]);

  useEffect(() => {
    if (selectedPhase === 1) {
      setPhase('1STP');
      setSubFId(1);
    } else if (selectedPhase === 2) {
      setPhase('PREC');
      setSubFId(2);
    } else if (selectedPhase === 3) {
      setPhase('CONT');
      setSubFId(3);
    }
    else if (selectedPhase === 4) {
      setPhase('MULP');
      setSubFId(29);
    }
  }, [selectedPhase]);

  useEffect(() => {
    (async () => {
      try {
        dispatch({ type: GlobalStateAction.Busy });
        await useFetch<any[]>("Placements/GetStateCodes").then((state) => {
          let stateCodes = [];
          state.data.forEach((stateCode) => { stateCodes.push(stateCode?.state_code); });
          setGetStates(stateCodes);
        });
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Idle });
        dispatch({ type: GlobalStateAction.Error, error: ex });
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle });
      }
    })()
  }, [])

  useEffect(() => {
    (async () => {
      dispatch({ type: GlobalStateAction.Busy })
      try {
        await useFetch<IUserTemplate[]>(`Placements/TemplatebyUserID?user_id=${UserName}`).then((template) => {
          setUserTemplate(template.data);
        });
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex });
        dispatch({ type: GlobalStateAction.Idle });
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle });
      }
    })()
  }, []);

  async function LoadPlacementClientCodeList() {
    try {
      dispatch({ type: GlobalStateAction.Busy });
      await useFetch<IAdminGetClients[]>(`UploadLog/ClientGetPlacement?userId=${UserName}`).then((mappingList) => {
        let currentClientList: IClientList[] = [];
        mappingList.data.forEach(x => {
          let client: IClientList = { clT_CODE: x.client_code, clT_NAME_1: x.client_name };
          currentClientList.push(client);
        });
        { state.userAccessContext?.multiClientCodePlacementAccess ? currentClientList.unshift(getMultiClientOption()) : null };
        setPlacementClientList(currentClientList);
      });
    }
    catch (ex) {
      dispatch({ type: GlobalStateAction.Idle });
      dispatch({ type: GlobalStateAction.Error, error: ex });
    }
    finally {
      dispatch({ type: GlobalStateAction.Idle });
    }
  }

  async function LoadClientCodeList() {
    try {
      dispatch({ type: GlobalStateAction.Busy });
      setLoading(true);
      if (state.userAccessContext.role === 2 || state.userAccessContext?.role === 3) {
        let request = {
          "query": null,
          "last": 9999,
          "recordNumber": 1,
          "orderBy": "CLT_CODE",
          "orderDirection": 0
        }
        await usePost<{ clientDetails: IClientTemplate[] }>("ClientPlacement/ClientCodeList", request).then((clients) => {
          let clientsList = clients.data["clientDetails"];
          { state.userAccessContext?.multiClientCodePlacementAccess ? clientsList.unshift(getMultiClientOptionAsync()) : null }
          setClientList(clientsList);
        }).finally(() => {
          setLoading(false);
        });
      }
      else if (state.userAccessContext.role === 1 || state.userAccessContext.role === 0) {
        setLoading(true);
        await useFetch<IAdminGetClients[]>(`UploadLog/ClientGetPlacement?userId=${UserName}`).then((mappingList) => {
          let currentClientList: IClientList[] = [];
          mappingList.data.forEach(x => {
            let client: IClientList = { clT_CODE: x.client_code, clT_NAME_1: x.client_name };
            currentClientList.push(client);
          });
          { state.userAccessContext?.multiClientCodePlacementAccess ? currentClientList.unshift(getMultiClientOption()) : null };
          setClientList(currentClientList);
        }).finally(() => {
          setLoading(false);
        });
      }
    }
    catch (ex) {
      dispatch({ type: GlobalStateAction.Idle });
      dispatch({ type: GlobalStateAction.Error, error: ex });
    }
    finally {
      dispatch({ type: GlobalStateAction.Idle });
    }
  }

  const getMultiClientOption = () => {
    let multiClientOption: IClientList = { clT_CODE: "-2", clT_NAME_1: "Multi Client" }
    setDisableEnterManually(true);
    return multiClientOption;
  }

  const getMultiClientOptionAsync = () => {
    let multiClientOption: IClientTemplate = {
      clT_CODE: "-2", clT_NAME_1: "Multi Client", template_id: 1, isActive: true,
      allianceName: ''
    }
    setDisableEnterManually(true);
    return multiClientOption;
  }

  const handleGetAllainceName = async (clientCode: string) => {
    (async () => {
      await useFetch('ClientPlacement/GetAllianceNameByClientCode?clientName=' + clientCode).then((result) => {
        setAllianceName(result?.data);
      });
    })()
  }

  const handleClientChange = (event, selected) => {
    setClientName(selected?.clT_CODE);
    handleGetAllainceName(selected?.clT_CODE);
    { !selected?.clT_CODE ? setManageTemplateLogo(null) : null }
    (async () => {
      if (selected) {
        setSelectedPhase(-1);
        setLoading(true);
        await useFetch<{ placementPhaseResults: IClientPhase[], templateId: number }>(`Placements/GetPlacementPhase?clt_code=${selected?.clT_CODE}`).then((clientPhase) => {
          setClientPhase(clientPhase?.data?.['placementPhaseResults']);
          getPlacementLogoList(clientPhase?.data?.templateId);
        }).finally(() => {
          setLoading(false);
        });
        let selectedValue = clientList.find(x => x.clT_CODE === selected.clT_CODE);
        setDisableEnterManually(selectedValue.clT_CODE === "-2" ? true : false);
        setSelectedClient(selectedValue);
        setSelectedCode(true);
        setDisableAll(false);
        setShowPhase(false);
      }
      else {
        setSelectedClient({ clT_NAME_1: "", clT_CODE: "" });
        setShowPhase(true);
        setDisableAll(true);
      }
    })()
  };

  const getPlacementLogoList = (templateId: number) => {
    (async () => {
      await useFetch<IManageTemplateLogo>(`File/GetMultipleFile?PlacementTemplateId=${templateId}`).then((r) => {
        setManageTemplateLogo(r?.data[0]);
        getImgDisplay(r?.data[0]?.imageUri);
      })
    })()
  }

  const getImgDisplay = async (blobUri: string) => {
    let request = {
      "pdfUri": blobUri
    }
    await usePost<any>("File/PDFDocumentFileDownload", request).then((r) => {
      let url = URL.createObjectURL(base64toBlob(baseImage + r?.data));
      setImgUrl(url);
    })
  };

  const handleTemplateChange = (event, selected) => {
    if (selected) {
      const selectedValue = userTemplate.find(x => x.placement_template_id === selected.placement_template_id);
      setSelectedTemplate(selectedValue);
      setSelectedCode(true);
      setSelectedMultiCode(true);
      setDisableEnterManually(false);
    }
    else if (selected === null) {
      setDisableEnterManually(true);
    }
    else {
      setSelectedTemplate({ placement_template_id: 0, name: "" });
      setSelectedMultiCode(false);
    }
  };

  function formInputCheck(): boolean {
    if (!selectedCode || !selectedPhase) {
      setAlertMessage("Please select client to proceed.");
      setAlertOpen(true);
      return false;
    }
    if (!selectedPhase || !selectedPhase || +selectedPhase == -1) {
      setAlertMessage("Please select client phase to proceed.");
      setAlertOpen(true);
      return false;
    }
    return true;
  }

  // Upload CSV file from Local
  const handleCSVUpload = async (csvTable) => {
    if (fileName?.split(".")?.pop() === "csv" || fileName?.split(".")?.pop() === "txt") {
      const fileNameList = await useFetch<ISearchDuplicateFileName[]>(`Placements/SearchDuplicateFileName?Name=${fileName}&ClientId=${selectedClient.clT_CODE}`);
      const CheckFile = fileNameList?.data?.filter(x => x.fileName.includes(fileName));
      if (CheckFile.length === 0) {
        if (!formInputCheck()) {
          return;
        }
        let placementAccountCSVData: string[][] = [];
        for (let i: number = 0; i < csvTable.length; i++) {
          placementAccountCSVData[i] = [];
          if (undefined == csvTable[i].data) {
            let flattendArray = csvTable[i]?.toString()?.replace('\t', '')?.split(',');
            for (let j: number = 0; j < flattendArray.length; j++) {
              placementAccountCSVData[i][j] = flattendArray[j]?.toString()?.replace('\t', '');
            }
          }
          else {
            for (let j: number = 0; j < csvTable[i].data.length; j++) {
              placementAccountCSVData[i][j] = csvTable[i].data[j].toString()?.replace('\t', '');
            }
          }
        }
        let filteredClientList = _.cloneDeep(placementClientList);
        filteredClientList = filteredClientList.filter(x => x.clT_CODE != "-2");
        history.push('/csvfieldnames',
          {
            placementAccountCSVData: placementAccountCSVData, clientCode: selectedClient?.clT_CODE,
            phaseNumber: selectedPhase, stateCodes: getStates, clientList: filteredClientList, uploadedFile: uploadedFile
          });
        setLoading(false);
      } else {
        setFileName(null);
        setdisplayMessage(true);
        setLoading(false);
      }
    }
    else {
      setAlertMessage("Please Upload a valid CSV file");
      setAlertOpen(true);
      setLoading(false);
    }
  }

  // Upload CSV file from Cloud
  const handleCloudCSVFileUpload = (csvTable, fileExist) => {
    setLoading(true);
    if (!fileExist) {
      if (fileName?.split(".")?.pop() === "csv" || fileName?.split(".")?.pop() === "txt") {
        if (!formInputCheck()) {
          return;
        }
        let placementAccountCSVData: string[][] = [];
        for (let i: number = 0; i < csvTable.length; i++) {
          placementAccountCSVData[i] = [];
          if (undefined == csvTable[i].data && i == 0) {
            let flattendArray = csvTable[i]?.toString()?.replace('\t', '')?.split(',');
            for (let j: number = 0; j < flattendArray.length; j++) {
              placementAccountCSVData[i][j] = flattendArray[j]?.toString()?.replace('\t', '');
            }
          }
          else {
            let newFileValue = csvTable[i];
            for (let j: number = 0; j < newFileValue.length; j++) {
              placementAccountCSVData[i][j] = newFileValue[j].toString()?.replace('\t', '');
            }
          }
        }
        let filteredClientList = _.cloneDeep(placementClientList);
        filteredClientList = filteredClientList.filter(x => x.clT_CODE != "-2");
        history.push('/csvfieldnames',
          {
            placementAccountCSVData: placementAccountCSVData, clientCode: selectedClient?.clT_CODE,
            phaseNumber: selectedPhase, stateCodes: getStates, clientList: filteredClientList, uploadedFile: uploadedFile
          });
        setLoading(false);
      }
      else {
        setAlertMessage("Please Upload a valid CSV file");
        setAlertOpen(true);
        setLoading(false);
      }
    } else {
      setdisplayMessage(true);
      setLoading(false);
    }
  }

  const handleFileUpload = (file) => {
    setFileName(file && file?.name);
    if (file !== null && fileName !== file?.name) {
      setFileName(file && file?.name);
      setUploadedFile(file);
    }
  }

  const handleCloudFileUpload = (file) => {
    if (fileName === '' || fileName === null) {
      return setFileName(file);
    }
  }

  const handlePhaseChange = (event) => {
    setSelectedPhase(parseInt(event.target.value));
    setSelectPhase(true);
    if (parseInt(event.target.value) !== -1) {
      (async () => {
        setLoading(true);
        let request = {
          "clientCode": selectedClient?.clT_CODE,
          "phaseCode": parseInt(event.target.value) === 1 ? "1STP" : parseInt(event.target.value) === 2 ? "PREC" : parseInt(event.target.value) === 3 ? "CONT" : null
        }
        await usePost<{ clientDetails: IClientTemplate }>('ClientPlacement/GetPlacementTemplateByClient', request).then(async (clients) => {
          selectedClient?.clT_CODE === "-2" ? setIsActive(true) : setIsActive(clients?.data[0]?.isActive === undefined ? true : clients?.data[0]?.isActive)
          setDisableManual(selectedClient?.clT_CODE === "-2" ? false : clients?.data[0]?.template_id === 0 ? true : false)

        }).finally(() => {
          setLoading(false);
        });
      })()
    }
  };

  const handleSubmit = () => {
    if (state.userAccessContext?.role === 2 || state.userAccessContext?.role === 3) {
      history.push('/fieldnames',
        {
          clientCode: selectedClient?.clT_CODE, phaseNumber: selectedPhase, templateId: selectedTemplate?.placement_template_id,
          multiCode: selectedMultiCode, stateCodes: getStates
        });
    }
    else if (state.userAccessContext.role === 1 || state.userAccessContext.role === 0) {
      history.push('/fieldnames',
        {
          clientCode: selectedClient?.clT_CODE, phaseNumber: selectedPhase, templateId: selectedTemplate?.placement_template_id,
          multiCode: selectedMultiCode, stateCodes: getStates
        });
    }
  }

  const handleDrop = (acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      onUploadFile(acceptedFiles[0]);
    }
  };

  const onUploadFile = (selectedFile) => {
    handleClose();
    setButtonLoading(true);
    if (selectedFile) {
      (async () => {
        let filename = (selectedClient.clT_CODE === "-2" ? `MULC${momentTz().tz('America/Los_Angeles').format("YYYYMMDDhhmmss")}${selectedFile.file.name}` : `${selectedClient.clT_CODE}${momentTz().tz('America/Los_Angeles').format("YYYYMMDDhhmmss")}${selectedFile.file.name}`)
        const formData = new FormData();
        formData.append("FormFile", selectedFile.file);
        formData.append("file_name", filename);
        formData.append("fileSize", selectedFile.file.size.toString());
        formData.append("ContainerName", "placement-large-file");
        formData.append("ClientId", (selectedClient.clT_CODE == "-2" ? "MULC" : selectedClient.clT_CODE));
        formData.append("UploadMethod", '2');
        formData.append("Phase", phase);
        formData.append("userid", UserName.toString());
        formData.append("subFolderId", subFId?.toString());
        formData.append("userName", state.userAccessContext.userName);

        const GetResponse = await useFetch<ISearchDuplicateFileName[]>(`Placements/SearchDuplicateFileName?Name=${filename.slice(20)}&ClientId=${selectedClient.clT_CODE}`);
        const CheckFile = GetResponse.data.filter(x => x.fileName.includes(selectedFile.file.name));
        if (CheckFile.length === 0) {
          setUploadLoading(true);
          await usePostProgress<any>("File/UploadUnmappedFile", formData, '', (progressEvent) => {
            if (progressEvent.lengthComputable) {
              const total = progressEvent.total;
              const current = progressEvent.loaded;
              const progress = Math.round((current / total) * 100);
              setProgress(progress);
            }
          });
          setUploadLoading(false);
          setFileUploadSnackBar(true);
          setdisplayMessage(false);
        } else {
          const IsDuplicate = CheckFile[0].fileName.length > 0 ? true : false;
          if (IsDuplicate === false) {
            setUploadLoading(true);
            await usePostProgress<any>("File/UploadUnmappedFile", formData, '', (progressEvent) => {
              setButtonLoading(true);
              if (progressEvent.lengthComputable) {
                const total = progressEvent.total;
                const current = progressEvent.loaded;
                const progress = Math.round((current / total) * 100);
                setProgress(progress);
              }
              setButtonLoading(false);
            });
            setFileUploadSnackBar(true);
            setdisplayMessage(false);
            setButtonLoading(false);
            setUploadLoading(false);
          } else {
            setdisplayMessage(true);
            setButtonLoading(false);
          }
        }
      })().finally(() => {
        setButtonLoading(false);
        setUploadLoading(false);
      });
    }
  }

  const handleClickOpenCloud = () => {
    setOpenCloud(true);
  };

  const handleClose = () => {
    setOpenCloud(false);
    setButtonLoading(false);
  };

  useEffect(() => {
    if (!selectedCode || !selectPhase || !placeAccount || disableManual || selectedPhase === -1 || disableAll) {
      setDisableUploadCSV(true);
    } else {
      setDisableUploadCSV(false);
    }
  }, [selectedCode, selectPhase, placeAccount, disableManual, selectedPhase, disableAll])

  return (
    <React.Fragment>
      <div >
        <div className={classes.divStyle}>
          <Grid container spacing={0} className={`${classes.secondGrid}`}>
            <Grid item xs={12} sm={4} className={classes.gridDisplay}>
              {manageTemplateLogo?.imageUri?.length ?
                <img src={imgUrl} width="150px" height="55px" className={classes.imageLogo} />
                : null}
            </Grid >
            <Grid item xs={12} sm={6} style={{ height: '20%' }}>
              <Typography variant="h5" gutterBottom className={`${classes.title} ${"headertitle"}`}>
                <b>1. CHOOSE DATA ENTRY METHOD</b>
              </Typography>
              {progressLoad === true ?
                <CircularProgressWithLabel style={{ color: 'white' }} value={progress} />
                : null}
            </Grid>
            <Grid item xs={12} sm={2}>
              <Box className={classes.pdfPreview}>
              </Box>
              <Box style={{ marginBottom: '10px' }} className={classes.video}>
                <VideoPlayer video={videoFile} title={videoPlacementTitle} />
              </Box>
            </Grid>
            {manageTemplateLogo?.pdfUri?.length ?
              <Grid item xs={12} sm={12}>
                <DocumentPDFViewer row={manageTemplateLogo} clientName={clientName} allianceName={allianceName} />
              </Grid>
              : null}
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" gutterBottom className={classes.placementTitle}>
                <b> CHOOSE CLIENT CODE </b>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl className={classes.formControl}>
                <Autocomplete
                  id="clientCode-select"
                  options={clientList}
                  size="small"
                  classes={{ paper: classes.paper1, input: classes.smallFont1 }}
                  autoHighlight
                  loading={clientsLoading}
                  style={{ border: 'none' }}
                  onChange={handleClientChange}
                  getOptionLabel={(option) => option.clT_CODE == "-2" ? option.clT_NAME_1 : option.clT_CODE + "--" + option.clT_NAME_1}
                  getOptionSelected={(option, value) => option.clT_CODE === value.clT_CODE}
                  renderOption={(option) => (
                    <React.Fragment>
                      {option.clT_CODE == "-2" ? option.clT_NAME_1 : option.clT_CODE + "--" + option.clT_NAME_1}
                    </React.Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select client"
                      variant="outlined"
                      className={classes.dropdownClient}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                        classes: {
                          root: classes.outlinedInput1
                        }
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" gutterBottom className={classes.placementTitle}>
                <b> CHOOSE PHASE</b>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl id="Select_Phase" variant="outlined" size="small" className={classes.formControl} disabled={showPhase} >
                <Select native value={isNaN(selectedPhase) ? -1 : selectedPhase} onChange={handlePhaseChange} className={classes.dropdownClient}>
                  <option value={-1}>Select Phase</option>
                  {selectedClient?.clT_CODE === "-2" ?
                    <>
                      <option value={1}>1st Party</option>
                      <option value={2}>Flat Fee</option>
                      <option value={3}>Contingency</option>
                    </>
                    : clientPhase?.map((phase) => {
                      return (
                        <>
                          {phase?.istParty ? <option value={1}>1st Party</option> : <option value={-1} className={classes.NoAccess}>1st Party</option>}
                          {phase?.isFlatFee ? <option value={2}>Flat Fee</option> : <option value={-1} className={classes.NoAccess}>Flat Fee</option>}
                          {phase?.isContingency ? <option value={3}>Contingency</option> : <option value={-1} className={classes.NoAccess}>Contingency</option>}
                        </>
                      )
                    })}
                  {state.userAccessContext?.multiplePlacementAccess ? <option value={4}>Multi Phase</option> : null}
                </Select>
              </FormControl>
            </Grid>
            {selectedPhase !== 4 && selectedClient?.clT_CODE == "-2" ?
              <React.Fragment>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1" gutterBottom className={classes.placementTitle}>
                    <b>CHOOSE TEMPLATE FOR MANUAL UPLOAD</b>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl className={classes.formControl}>
                    <Autocomplete
                      id="template-select"
                      size="small"
                      classes={{ paper: classes.paper1, input: classes.smallFont1 }}
                      options={userTemplate}
                      autoHighlight
                      onChange={handleTemplateChange}
                      getOptionLabel={(option) => option.name}
                      getOptionSelected={(option, value) => option.placement_template_id === value.placement_template_id}
                      renderOption={(option) => (
                        <React.Fragment>
                          {option.name}
                        </React.Fragment>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select Template"
                          variant="outlined"
                          className={classes.dropdownClient}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // disable autocomplete and autofill
                          }} />
                      )}
                    />
                  </FormControl>
                </Grid>
              </React.Fragment>
              : null}
            {state?.userAccessContext?.placeAccounts ?
              <Grid item xs={12} sm={4}>
                <CSVFileReader csvUpload={handleCSVUpload} cloudUpload={handleCloudCSVFileUpload} disabled={disabledUploadCSV} userId={UserName} getphase={phase} CloudfileUpload={handleCloudFileUpload}
                  getClientCode={selectedClient?.clT_CODE} fileUpload={handleFileUpload} disableIsActive={!isActive && selectedClient?.clT_CODE !== "-2"} />
              </Grid>
              : null}
            <Grid item xs={12} sm={4} >
              <Button className={classes.enterManually} size="small" id="EnterManually_Button" disabled={!selectedCode || !selectPhase || !placeAccount || selectedPhase === 4 || selectedPhase === -1 || disableEntermanually || disableManual || disableAll || !isActive}
                onClick={handleSubmit} type='submit' variant="contained" startIcon={<DvrIcon style={{ fontSize: 23 }} />} color="primary">
                Enter Manually
              </Button>
            </Grid>
            {state.userAccessContext?.uploadUnmappedFile ?
              <Grid item xs={12} sm={4}>
                <Button className={classes.paper} size="small" disabled={!selectedCode || !selectPhase || selectedPhase === -1 || disableAll || (!isActive && selectedClient?.clT_CODE !== "-2")}
                  variant="contained" color="primary" startIcon={<DriveFolderUploadIcon style={{ fontSize: 23 }} />} onClick={handleClickOpenCloud}>
                  Upload Loader File
                  {progressLoad === true ?
                    <CircularProgressWithLabel style={{ color: 'white' }} value={progress} />
                    : null}
                </Button>
              </Grid>
              : null
            }
            {displayMessage ?
              <Grid item xs={12} sm={12}>
                <Typography variant="h6" className={classes.displaymessage}>
                  The file you are trying to upload is named identically to a previous file. Please rename your file and try your upload again.
                </Typography>
              </Grid>
              : null}
          </Grid>
        </div>
        <Footer />
      </div >

      <Dialog TransitionComponent={Transition}
        fullScreen={fullScreen} open={alertOpen} onClose={handleAlertClose} maxWidth={'sm'}
        aria-labelledby="responsive-dialog-title" PaperProps={{ style: { borderRadius: 10 } }} >
        <DialogTitleDelete id="responsive-dialog-title" onClose={handleAlertClose}>
          <Typography variant="h5" gutterBottom className={classes.titleheader}>
            MESSAGE
          </Typography>
        </DialogTitleDelete>
        <DialogContent>
          <DialogContentText>
            <Typography variant="h5" className={classes.MessageStyle} gutterBottom>
              {alertMessage}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions >
          <Button id="DU_ok_btn" variant="contained" onClick={(e) => { setAlertOpen(false); }} className={classes.yesButton}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>

      <Backdrop className={classes.backdrop} open={loading}>
        <PuffLoader size={80} color={"white"} speedMultiplier={1} />
      </Backdrop>

      <Backdrop className={classes.backdrop} open={uploadLoading}>
        <RadialProgress value={progress} />
      </Backdrop>

      <Dialog TransitionComponent={Transition} fullScreen={fullScreen} open={openCloud} aria-labelledby="responsive-dialog-title"
        maxWidth={'sm'} PaperProps={{ style: { borderRadius: 10 } }}  >
        <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
          <Typography variant="h6" gutterBottom className={classes.titleheader}>
            UPLOAD UNMAPPED FILE
          </Typography>
        </DialogTitleHeader>
        <DialogContent >
          <LightTooltip title="When you drag and drop a file here, It will save automatically.">
            <InfoIcon className={classes.infoicon} />
          </LightTooltip>
          <Grid container spacing={1} style={{ marginTop: '5px' }}>
            <Grid item xs={12} >
              <Dropzone
                onSubmit={handleDrop}
                multiple={false}
                maxFiles={1}
                styles={{
                  dropzone: { height: '250px', overflow: 'hidden', border: '3px dashed blue', marginBottom: '10px', boxShadow: 'rgba(0, 0, 0, 0.35) 3px 0px 3px 0px', borderRadius: '10px', backgroundColor: '#f1f5ff' },
                  inputLabel: { color: 'black', fontSize: '16px' },
                  dropzoneActive: { borderColor: 'green' }
                }}
                inputContent="Drag and drop a file here or click"
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <Snackbar open={FileUploadSnackBar} className="snackBarStyle" anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }} autoHideDuration={4000} onClose={closeSnackbar}>
        <Alert onClose={handleClose} severity="success" className="alertStyle">
          File Uploaded Successfully !
        </Alert>
      </Snackbar>
    </React.Fragment >

  )
}

export default Placements