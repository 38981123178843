import { Box, Chip, Grid, IconButton, Paper, Snackbar, Table, TableCell, TableContainer, TableRow, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AlertMessage from '@mui/material/Alert';
import moment from 'moment';
import React, { useRef, useState } from 'react';
import { clientBackup } from '../../constants/Constant';
import { IHideForAllUsers } from '../../models/NotificationCenter/IHideForAllUsers';
import { INotificationCenter } from "../../models/NotificationCenter/INotificationCenter";
import { useGlobalState } from '../../store/GlobalStore';
import { usePost } from '../../utils/apiHelper';
import { Transition } from '../GlobalStyles/DialogBoxTransition';
import { DialogTitleHeader } from '../GlobalStyles/DialogStyle';
import LightTooltip from '../GlobalStyles/LightTooltip';
import _ from 'lodash';
import { Scrollbars } from 'react-custom-scrollbars';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const useStyles = makeStyles((theme) => ({
    button: {
        borderRadius: 20,
        marginLeft: '1cm',
        fontSize: 12
    },
    titleheader: {
        fontWeight: 'bold',
        marginTop: '5px',
        color: 'white'
    },
    button1: {
        borderRadius: 20,
        background: 'red',
        marginRight: '10px',
        fontSize: 12,
        "&:hover": {
            backgroundColor: 'red',
        }
    },
    choosebutton: {
        borderRadius: 20,
        fontSize: 12
    },
    choosename: {
        color: 'black',
        textAlign: 'left',
        wordWrap: 'break-word',
        fontWeight: 'bold',
        fontSize: 13
    },
    boxDirection: {
        flexDirection: 'row'
    },
    typoStyle1: {
        textAlign: "left",
        color: 'black',
        fontSize: 15
    },
    typoStyle2: {
        color: "red"
    },
    noteIconStyle: {
        fontSize: 25
    },
    dialogeConformation: {
        minWidth: "750px"
    },
    tablebodycell: {
        backgroundColor: '#03396c',
        color: 'white',
        fontSize: 13,
        fontWeight: 'bold',
        borderBottom: '2px solid white',
        width: "30%",
        padding: '10px 15px'
    },
    notificationName: {
        fontSize: 13
    },
    browseFileDiv: {
        display: 'flex',
        marginTop: '5px'
    },
    TextColorGreen: {
        color: 'green',
        fontWeight: "bold"
    },
    TextColorRed: {
        color: '#fb6767',
        fontWeight: "bold"
    },
    rejBoxPadding: {
        marginTop: '10px'
    },
    fileChip: {
        display: 'flex',
        borderRadius: '5px',
        marginTop: '5px',
        justifyContent: 'left',
        flexWrap: 'wrap',
        border: '1px solid gray',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
    invalidFileChip: {
        display: 'flex',
        borderRadius: '5px',
        marginTop: '5px',
        justifyContent: 'left',
        flexWrap: 'wrap',
        border: '1px solid gray',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
    accFiles: {
        backgroundColor: 'rgba(73,204,144,.1)',
        border: '1px solid #49cc90',
        color: 'black',
        fontWeight: 'bold',
        margin: theme.spacing(0.5)
    },
    regFiles: {
        backgroundColor: 'rgba(249,62,62,.1)',
        border: '1px solid #f93e3e',
        color: 'black',
        fontWeight: 'bold',
        margin: theme.spacing(0.5)
    },
}))

const BackupDocumentDialog: React.FC<{ BackUpInfo: INotificationCenter, onHideAll: () => void, IsInvited: boolean }> = (props) => {
    const { BackUpInfo } = props;
    const theme = useTheme();
    const classes = useStyles();
    const fileUpload = useRef(null);
    const { state } = useGlobalState();

    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [snackbarOpen, setSnackBarOpen] = useState(false);
    const [open, setOpen] = useState(false);

    const [acceptedFiles, setAcceptedFiles] = useState<File[]>([]);
    const [rejectedFiles, setRejectedFiles] = useState<File[]>([]);

    const handleCloseSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackBarOpen(false);
    };

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const onSubmit = () => {
        (async () => {
            const formData = new FormData();
            const currentDate = moment(new Date(Date.now())).format('MMDDYY');
            const fileExtension = acceptedFiles[0]?.name?.split('.')?.pop();
            const fileName = `${props?.BackUpInfo?.extraData_1}-${currentDate}${props?.BackUpInfo?.clT_Code}-BU.${fileExtension}`;
            acceptedFiles.map((f) => {
                formData.append('multiFiles', f);
                formData.append("CategoryId", "3");
                formData.append("Userid", state?.userAccessContext?.id.toString());
                formData.append("extraData", props?.BackUpInfo?.extraData_1);
                formData.append("currentDate", currentDate);
                formData.append("clientCode", props?.BackUpInfo?.clT_Code);
            });
            await usePost<any>("WebViewFiles/InsertMulipleBackUpFiles", formData);
            setSnackBarOpen(true);
            UploadBackUpFile(acceptedFiles);
            if ((props?.IsInvited) && (state?.userAccessContext?.role >= 2)) {
                let sendEmail = {
                    "debtorNumber": BackUpInfo?.extraData_1,
                    "clientId": BackUpInfo?.clT_Code,
                    "clientNo": BackUpInfo?.extraData_3?.split('+')[0],
                    "userName": state.userAccessContext?.userName,
                    "fileName": fileName
                }
                await usePost<any>("SendDocumentEmail", sendEmail);
            }
            hidenotificationForAllUsers();
        })().finally(() => {
            setAcceptedFiles([]);
        })
    }

    const UploadBackUpFile = (selectedFile: File[]) => {
        if (selectedFile) {
            (async () => {
                const formData = new FormData();
                let dateTime = new Date(Date.now());
                const currentDate = moment(dateTime).format('MMDDYY');
                acceptedFiles?.map((file) => {
                    formData.append("multiFiles", file);
                    formData.append("clientCode", BackUpInfo?.clT_Code);
                    formData.append("Userid", state?.userAccessContext?.id.toString());
                    formData.append("extraData", BackUpInfo?.extraData_1)
                    formData.append("currentDate", currentDate.toString());
                    formData.append("ContainerName", clientBackup);
                })
                await usePost<any>("File/MultiFileInsert", formData);
            })()
        }
    }

    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        let arrayFiles = Array.from(event.currentTarget.files)
        let ValidFiles: File[] = (!acceptedFiles?.length) ? [] : _.cloneDeep(acceptedFiles);
        let RejectedFile: File[] = (!rejectedFiles?.length) ? [] : _.cloneDeep(rejectedFiles);
        arrayFiles.map((r) => {
            if ((event.target.files[0]?.type === 'image/jpeg' || event.target.files[0]?.type === 'application/pdf') && event.target.files[0]?.size > 0) {
                if (ValidFiles?.findIndex((v) => (v.name === r.name)) <= -1) {
                    ValidFiles.push(r);
                }
            } else {
                if (RejectedFile?.findIndex((v) => (v.name === r.name)) <= -1) {
                    RejectedFile.push(r);
                }
            }
        })
        setAcceptedFiles(ValidFiles);
        setRejectedFiles(RejectedFile);
        event.currentTarget.value = null;
    }

    const hidenotificationForAllUsers = () => {
        (async () => {
            let request = {
                "usernotificationid": BackUpInfo?.notificationid,
                "userid": state.userAccessContext?.id,
                "userRole": 1
            }
            await usePost<IHideForAllUsers>("HideForAllUsers", request);
            props.onHideAll();
        })()
    }

    return (
        <React.Fragment>
            <LightTooltip title="Attachment Document">
                <IconButton color='primary' size="medium" onClick={handleClickOpen} style={{ padding: '2px' }}>
                    <NoteAddIcon className={classes.noteIconStyle} />
                </IconButton>
            </LightTooltip>
            <Dialog TransitionComponent={Transition}
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                classes={{ paper: classes.dialogeConformation }}
                PaperProps={{ style: { borderRadius: 15 } }}
            >
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                    <Typography variant="h6" gutterBottom className={classes.titleheader}>
                        BACKUP DOCUMENTATION
                    </Typography>
                </DialogTitleHeader>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12} >
                            <AlertMessage severity="warning" className="snackBarStyle1"><b>Please Upload .jpg/.pdf files only. </b></AlertMessage>
                        </Grid>
                        <TableContainer component={Paper} style={{ marginTop: '10px' }}>
                            <Table aria-label="customized table" size="small" stickyHeader>
                                <TableRow>
                                    <TableCell className={classes.tablebodycell} >
                                        Created Date
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="subtitle1" gutterBottom className={classes.notificationName} >
                                            {moment(BackUpInfo?.createdDate).format(state.GlobalUtils?.settingValue)}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.tablebodycell}>
                                        BackUp Documentation
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="subtitle1" gutterBottom className={classes.notificationName} >
                                            Client Acct #{BackUpInfo?.extraData_3?.substring(0, BackUpInfo?.extraData_3?.lastIndexOf("+"))}
                                            / Agency Acct #{BackUpInfo?.extraData_1}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.tablebodycell}>
                                        Client Name
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="subtitle1" gutterBottom className={classes.notificationName} >
                                            {BackUpInfo?.clT_Name} - {BackUpInfo?.clT_Name_2}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.tablebodycell}>
                                        Type
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="subtitle1" gutterBottom className={classes.notificationName} >
                                            {BackUpInfo?.extraData_2}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <form encType="multipart/form-data" onSubmit={(e) => e.preventDefault()} >
                                            <Button component="label" color="primary" className={classes.choosebutton}
                                                variant="contained" size="small" startIcon={<CloudUploadIcon />}>
                                                Choose File
                                                <input
                                                    id="NF_SelectFile"
                                                    onChange={changeHandler}
                                                    name="file"
                                                    ref={fileUpload}
                                                    hidden
                                                    multiple type="file"
                                                    accept="image/jpeg,image/jpg,application/pdf"
                                                />
                                            </Button>
                                        </form>
                                    </TableCell>
                                    <TableCell>
                                    </TableCell>
                                </TableRow>
                            </Table>
                        </TableContainer>
                        <div className={classes.browseFileDiv}>
                            <Box width="100%" style={{ padding: '10px' }}>
                                {acceptedFiles?.length > 0 ?
                                    <React.Fragment>
                                        <span className={classes.TextColorGreen}>Accepted Files : {acceptedFiles?.length}</span>
                                        <Paper className={classes.fileChip}>
                                            <Scrollbars autoHide
                                                autoHideTimeout={1000}
                                                autoHideDuration={10}
                                                autoHeight
                                                autoHeightMax={100}
                                                style={{ width: "100%" }}>
                                                {acceptedFiles.map((r) => {
                                                    return (
                                                        <Chip
                                                            size="small"
                                                            label={r?.name}
                                                            clickable={false}
                                                            className={classes.accFiles}
                                                            deleteIcon={<HighlightOffIcon />}
                                                        />
                                                    )
                                                })}
                                            </Scrollbars>
                                        </Paper>
                                    </React.Fragment>
                                    : null
                                }

                                {rejectedFiles?.length > 0 ?
                                    <React.Fragment>
                                        <div className={classes.rejBoxPadding}>
                                            <span className={classes.TextColorRed}>Rejected Files : {rejectedFiles?.length}</span>
                                            <Paper className={classes.invalidFileChip}>
                                                <Scrollbars autoHide
                                                    autoHideTimeout={1000}
                                                    autoHideDuration={10}
                                                    autoHeight
                                                    autoHeightMax={100}
                                                    style={{ width: "100%" }}>
                                                    {rejectedFiles.map((r) => {
                                                        return (
                                                            <Chip
                                                                size="small"
                                                                label={r?.name}
                                                                clickable={false}
                                                                className={classes.regFiles}
                                                            />
                                                        )
                                                    })}
                                                </Scrollbars>
                                            </Paper>
                                        </div>
                                    </React.Fragment>
                                    :
                                    null
                                }
                            </Box>
                        </div>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary" size="small" className={classes.button} onClick={(_) => { onSubmit(); setOpen(false); }}
                        disabled={!acceptedFiles?.length} startIcon={<CheckCircleIcon />} style={{ backgroundColor: !acceptedFiles?.length ? 'gray' : 'green' }}>
                        Submit
                    </Button>
                    <Button onClick={handleClose} variant="contained" size="small" color="primary" startIcon={<CancelIcon />} autoFocus className={classes.button1}>
                        CANCEL
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar open={snackbarOpen} className="snackBarStyle" anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="success" className="alertStyle">
                    File Submitted Successfully!
                </Alert>
            </Snackbar>
        </React.Fragment>
    );
}

export default BackupDocumentDialog
