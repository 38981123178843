import CloseIcon from '@material-ui/icons/Close';
import HistoryIcon from "@mui/icons-material/History";
import {
    AppBar, Autocomplete, Backdrop, Button, Checkbox, Container, Dialog, DialogContent, Grid, IconButton, Paper,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Toolbar, Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { PuffLoader } from 'react-spinners';
import { ARMLogo, EntityRule, fileTypeList } from '../../../../../constants/Constant';
import apiRule from '../../../../../images/APIPlacementRule/apiRule.svg';
import {
    EntityFieldName, ICheckedFields, IManageEntityFields, IPlacementFieldsNameResponse,
    ISelectedFileType
} from '../../../../../models/accesscontrol/PlacementTemplateRule';
import { useStyles } from '../../../../../pages/NotificationCenter/NotificationCenterCss';
import { usePost } from '../../../../../utils/apiHelper';
import { Transition } from '../../../../GlobalStyles/DialogBoxTransition';
import TooltipSmall from '../../../../GlobalStyles/TooltipSmall';
import { MapRuleCss } from '../ManageRuleCss';
import FileEntityRules from './FileEntityRules';
import MapPlacementRules from './MapPlacementRules';
import { Snackbar } from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

const ManagePlacementFields = (props) => {
    const { apiKeyId } = props;
    const classes = useStyles();
    const classes1 = MapRuleCss();
    const [open, setOpen] = useState(false);
    const [progressBar, setProgressBar] = useState(false);
    const [fieldIds, setFieldIds] = useState<number[]>([]);
    const [selectAll, setSelectAll] = useState<boolean>(false);
    const [checkedFields, setCheckedFields] = useState<ICheckedFields>();
    const [selectedFileType, setSelectedFileType] = useState<ISelectedFileType>(null);
    const [placementFieldName, setPlacementFieldname] = useState<EntityFieldName[]>([]);
    const [isEntitySaved, setIsEntitySaved] = useState<boolean>(false);
    const [isFieldSaved, setIsFieldSaved] = useState<boolean>(false);
    const [saveBtn, setSaveBtn] = useState<boolean>(true);
    const [fieldBtn, setFieldBtn] = useState<boolean>(true);
    const [entityBtn, setEntityBtn] = useState<boolean>(false);

    const handleOpen = () => {
        handleGetEntityFields();
        getPlacementFieldName(0);
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
        setLocalState(initialEntityFields);
        setSelectedFileType(null);
    };

    useEffect(() => {
        const initialCheckedFields = placementFieldName.reduce<ICheckedFields>((acc, field) => {
            acc[field.fieldId] = fieldIds.includes(field.fieldId);
            return acc;
        }, {});
        const isAnyFieldChecked = Object.values(initialCheckedFields).some(checked => checked);

        if (isAnyFieldChecked) {
            setFieldBtn(false);
        }
        setCheckedFields(initialCheckedFields);
    }, [placementFieldName, fieldIds]);

    const initialEntityFields: IManageEntityFields = {
        entityId: 0,
        apiKeyId: apiKeyId,
        entityName: "",
        fileType: ""
    };

    const [localState, setLocalState] = useState<IManageEntityFields>(initialEntityFields);

    const getPlacementFieldName = (entityId: number) => {
        setProgressBar(true);
        (async () => {
            await usePost<IPlacementFieldsNameResponse>(`GlobalSettings/GetPlacementFieldsName?EntityId=${entityId}`, entityId).then((result) => {
                setPlacementFieldname(result?.data[0]?.entityFieldNames);
                const allFieldIds = result?.data[0]?.placementTemplates.flatMap(template =>
                    template?.fieldIdMap.split('|').map(Number)
                );
                setFieldIds(allFieldIds);
            }).finally(() => {
                setProgressBar(false);
            });
        })();
    };

    const handleGetEntityFields = () => {
        setProgressBar(true);
        (async () => {
            let request = {
                apiKeyId: apiKeyId
            }
            await usePost<IManageEntityFields>("GlobalSettings/GetPlacementRuleEntityFields", request).then((response) => {
                setLocalState(response?.data);
                getPlacementFieldName(response?.data?.entityId)

                if (response?.data?.fileType) {
                    const defaultFileType = fileTypeList.find(fileType => fileType.title === response.data.fileType);
                    setSelectedFileType(defaultFileType);
                    setSaveBtn(false);
                }

            }).finally(() => {
                setProgressBar(false);
            });
        })();
    }

    const handleCheckboxChange = (fieldId: number) => {
        setEntityBtn(true);
        setCheckedFields(prevState => {
            const newCheckedFields = { ...prevState };
            const isChecked = !!newCheckedFields[fieldId];

            if (isChecked) {
                delete newCheckedFields[fieldId];
                setFieldIds(prevIds => prevIds.filter(id => id !== fieldId));
            } else {
                newCheckedFields[fieldId] = true;
                setFieldIds(prevIds => [...prevIds, fieldId]);
            }
            return newCheckedFields;
        });
    };

    const handleSelectAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEntityBtn(true);
        const isChecked = event.target.checked;
        const updatedFieldIds = isChecked
            ? placementFieldName.map(field => field.fieldId)
            : [];

        setSelectAll(isChecked);
        setCheckedFields(placementFieldName.reduce<ICheckedFields>((acc, field) => {
            acc[field.fieldId] = isChecked;
            return acc;
        }, {}));
        setFieldIds(updatedFieldIds);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setLocalState(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const handleSubmit = () => {
        setProgressBar(true);
        (async () => {
            let request: IManageEntityFields = {
                entityId: localState?.entityId,
                apiKeyId: apiKeyId,
                entityName: localState?.entityName,
                fileType: localState?.fileType
            };
            await usePost("GlobalSettings/ManageEntityFields", request).then((r) => {
                handleGetEntityFields();
                setIsEntitySaved(true);
            }).finally(() => {
                setProgressBar(false);
                setLocalState(initialEntityFields);
            });
        })();
    }

    const HandleFieldMappingSave = () => {
        const fieldIdsString = Object.entries(checkedFields)
            .filter(([_, isChecked]) => isChecked)
            .map(([key]) => key)
            .join('|');
        setProgressBar(true);

        (async () => {
            let request = {
                "entityId": localState?.entityId,
                "fieldIdMap": `${EntityRule}${fieldIdsString}`
            };
            await usePost("GlobalSettings/ManageEnityFieldIdMapping", request).then(() => {
                handleGetEntityFields();
                setIsFieldSaved(true);
            }).finally(() => {
                setProgressBar(false);
                setLocalState(initialEntityFields);
            });
        })();
    }

    function AlertMsg(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setIsEntitySaved(false);
        setIsFieldSaved(false);
    };

    return (
        <React.Fragment>
            <IconButton onClick={handleOpen}>
                <TooltipSmall title="File Field Rules">
                    <img src={apiRule} width="30px" height="30px" alt="api-config" />
                </TooltipSmall>
            </IconButton>
            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition} className={classes.dialog}>
                <AppBar position="fixed" className={classes.appBar}>
                    <Toolbar className={classes.toolbarStyle}>
                        <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                            <img alt="ARM_Solutions" className={classes.menuButton1} src={ARMLogo} />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            <b>FILE FIELD RULES</b>
                        </Typography>
                        <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close" style={{ padding: '5px' }}>
                            <CloseIcon className={classes.closeIcon} />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Backdrop className={classes.backdrop} open={progressBar}>
                    <PuffLoader size={80} color={"white"} speedMultiplier={1} />
                </Backdrop>
                <DialogContent sx={{ mt: 5 }}>
                    <Container maxWidth="lg">
                        <Grid container spacing={1}>
                            <Grid item xs={3}>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={fileTypeList}
                                    fullWidth
                                    size="small"
                                    getOptionLabel={(option) => option.title}
                                    value={selectedFileType}
                                    onChange={(event, newValue) => {
                                        setSelectedFileType(newValue);
                                        setLocalState((prevState) => ({
                                            ...prevState,
                                            fileType: newValue?.title
                                        }));
                                    }}
                                    renderInput={(params) => <TextField {...params} label="File Type" />}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField variant='outlined'
                                    size='small' label="Entity Name"
                                    name='entityName' fullWidth
                                    value={localState?.entityName}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Button variant='contained' size='medium' fullWidth onClick={handleSubmit} disabled={!localState.entityName.length || !localState.fileType.length}>{saveBtn ? "Save" : "Update"}</Button>
                            </Grid>
                            <Grid item xs={2}>
                                <Button variant='contained' size='medium' fullWidth onClick={HandleFieldMappingSave} disabled={!fieldIds.length || !localState.entityName || !localState.fileType}>{fieldBtn ? "Save Fields" : "Update Fields"}</Button>
                            </Grid>
                            <Grid item xs={2}>
                                <FileEntityRules entityId={localState?.entityId} field={placementFieldName} entityBtn={entityBtn} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} sx={{ marginTop: 1 }}>
                            <Grid item xs={12}>
                                <TableContainer component={Paper} sx={{ mt: "5px" }}>
                                    <Table aria-label="customized table" size="small" stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className={classes1.TableCellStyle}>
                                                    <Checkbox size='small' checked={selectAll} onChange={handleSelectAllChange} style={{ color: 'white' }} disabled={!localState.entityName || !localState.fileType} />
                                                </TableCell>
                                                <TableCell align="left" className={classes1.TableCellStyle}>FIELDS NAME</TableCell>
                                                <TableCell align="center" className={classes1.TableCellStyle}>RULES</TableCell>
                                                <TableCell align="center" className={classes1.TableCellStyle}>HISTORY</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {placementFieldName?.map((r) => (
                                                <TableRow key={r.fieldId}>
                                                    <TableCell align="left" className={classes1.TableCellBody}>
                                                        <Checkbox size='small' checked={checkedFields[r?.fieldId] || false} onChange={() => handleCheckboxChange(r?.fieldId)} disabled={!localState.entityName || !localState.fileType} />
                                                    </TableCell>
                                                    <TableCell align="left" className={classes1.TableCellBody}>
                                                        <Typography variant='body2'>
                                                            {r?.fieldDisplayName}{r.isRequired && <span style={{ color: 'red' }}>*</span>}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="left" className={classes1.TableCellBody}>
                                                        <MapPlacementRules ruleFieldName={r} entityId={localState?.entityId} apiKeyId={apiKeyId} isButtonDisabled={!checkedFields[r?.fieldId]} />
                                                    </TableCell>
                                                    <TableCell align="center" className={classes1.TableCellBody}>
                                                        <IconButton style={{ padding: "5px" }}>
                                                            <HistoryIcon style={{ color: "#B30987" }} />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </Container>
                </DialogContent>
            </Dialog>
            <Snackbar
                className="snackBarStyle"
                open={isEntitySaved}
                autoHideDuration={4000}
                onClose={closeSnackbar}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <AlertMsg onClose={closeSnackbar} severity="success" className="alertStyle">
                    Entity Details Saved Successfully!
                </AlertMsg>
            </Snackbar>
            <Snackbar
                className="snackBarStyle"
                open={isFieldSaved}
                autoHideDuration={4000}
                onClose={closeSnackbar}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <AlertMsg onClose={closeSnackbar} severity="success" className="alertStyle">
                    Entity Field Details Saved Successfully!
                </AlertMsg>
            </Snackbar>
        </React.Fragment>
    );
};

export default ManagePlacementFields;

