import { useMsal } from '@azure/msal-react';
import {
    Box, Grid, IconButton, InputAdornment, TablePagination, TableSortLabel, TextField
} from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Typography from '@material-ui/core/Typography';
import SearchIcon from "@material-ui/icons/Search";
import { Paper, styled, Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import React, { useEffect, useState } from 'react';
import PuffLoader from "react-spinners/PuffLoader";
import Footer from '../../components/Footer/Footer';
import CreateReport from "../../components/Report/CreateReport";
import ExpandableRow from '../../components/Report/ReportsList';
import VideoPlayer from '../../components/YouTubePlayer/VideoPlayer';
import * as constant from "../../constants/Constant";
import { IReportField } from '../../models/Reports/IReportField';
import { IReportFieldsValues } from '../../models/Reports/IReportFieldsValues';
import { IReportOperator } from '../../models/Reports/IReportOperator';
import { IReportsList } from '../../models/Reports/ReportsList';
import { IAdminGetClients } from '../../models/UploadLog/AdminGetClients';
import { useGlobalState } from '../../store/GlobalStore';
import { useFetch, usePost } from '../../utils/apiHelper';
import { useStyles } from '../common/ReportListCss';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        background: "#007FFF",
        color: theme.palette.common.white,
        fontSize: 12,
        padding: '2px 5px',
        textTransform: 'uppercase',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
        padding: 0
    }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0
    }
}));

const WebviewReports = () => {
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const { state } = useGlobalState();
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState(" ");
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [selected, setSelected] = useState<string>();
    const [userId, setUserId] = useState(state?.userAccessContext?.id);

    const [Phases, setPhases] = useState([]);
    const [States, setStates] = useState([]);
    const [Statuses, setStatuses] = useState([]);
    const [Clients, setClientsList] = useState([]);
    const [getClient, setGetClientsList] = useState([]);
    const [fieldsList, setFieldsList] = useState<IReportField[]>([]);
    const [ReportsList, setReportsList] = useState<IReportsList[]>([]);
    const [OperatorsList, setOperatorsList] = useState<IReportOperator[]>([]);

    const [isValid, setIsValid] = useState(false);
    const [ProgressBar, setshowProgressBar] = useState(false);
    const { accounts } = useMsal();
    const [searchReportType, setSearchReportType] = useState<string>('');

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    useEffect(() => {
        getReportsAsync();
    }, []);

    async function getReportsAsync() {
        setshowProgressBar(true);
        if (state.userAccessContext?.id !== undefined) {
            let request = {
                "userId": state.userAccessContext?.id
            }
            usePost<IReportsList[]>("ReportGetByUser", request).then((reportList) => {
                setReportsList(reportList.data);
            }).finally(() => {
                setshowProgressBar(false);
            })
        } else {
            let requestAccount = { "userName": accounts[0]?.username };
            usePost<any>("User/GetUserByEmail", requestAccount).then((userAcc) => {
                let request = {
                    "userId": state.userAccessContext?.id
                }
                usePost<IReportsList[]>("ReportGetByUser", request).then((reportList) => {
                    setReportsList(reportList.data);
                    setUserId(userAcc?.data?.id);
                })
            }).finally(() => {
                setshowProgressBar(false);
            })
        }
    }

    useEffect(() => {
        (async () => {
            setshowProgressBar(true);
            if (state.userAccessContext?.role === 2 || state.userAccessContext?.role === 3) {
                await useFetch<IAdminGetClients[]>("UploadLog/ClientCodeGetPlacement").then((AdminGetClients) => {
                    setClientsList(AdminGetClients.data.map(clients => { return { "id": clients.client_code, "name": clients.client_name + ` (${clients.client_code})` } }));
                });
            }
            else if (state.userAccessContext?.role === 1 && userId !== undefined) {
                await useFetch<IAdminGetClients[]>(`UploadLog/ClientGetPlacement?userId=${userId}`).then((AdminGetClients) => {
                    setClientsList(AdminGetClients.data.map(clients => { return { "id": clients.client_code, "name": clients.client_name + ` (${clients.client_code})` } }));
                });
            }
            else if (state.userAccessContext?.role === 0 && userId !== undefined) {
                await useFetch<IAdminGetClients[]>(`UploadLog/ClientGetPlacement?userId=${userId}`).then((AdminGetClients) => {
                    setClientsList(AdminGetClients.data.map(clients => { return { "id": clients.client_code, "name": clients.client_name + ` (${clients.client_code})` } }));
                });
            }

            await useFetch<IReportFieldsValues>("ReportOperatorsFieldsValues").then((reportField) => {
                setStates(reportField.data.states.map(stateCode => { return { "id": stateCode.id, "name": stateCode.name + ` (${stateCode.id})` } }));
                setPhases(reportField.data.phases.map(phase => { return { "id": phase.id, "name": phase.name + ` (${phase.id})` } }));
                setStatuses(reportField.data.statuses.map(status => { return { "id": status.status_class_id, "name": status.status_class } }));
                setOperatorsList(reportField.data.operators);
                setFieldsList(reportField.data.fields);
            });
            setshowProgressBar(false);
        })()
    }, [])


    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleRowClick = (accountId: string) => {
        setSelected(selected == accountId ? "" : accountId)
    }

    function getComparator(order, orderBy) {
        return order === "desc"
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map(el => el[0]);
    }

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    const headCells = [
        { id: "name", numeric: false, disablePadding: false, label: "NAME", sortable: true },
        { id: "date_created", numeric: false, disablePadding: false, label: "DATE CREATED", sortable: true },
        { id: "action", numeric: false, disablePadding: false, label: "ACTION", sortable: false },
        // { id: "share", numeric: false, disablePadding: false, label: "SHARE", sortable: false },
        // { id: "unSubscription", numeric: false, disablePadding: false, label: "UNSUBSCRIBE", sortable: false },
        { id: "remove", numeric: false, disablePadding: false, label: "REMOVE", sortable: false },
    ];

    function EnhancedTableHead(props) {
        const {
            classes,
            order,
            orderBy,
            onRequestSort
        } = props;
        const createSortHandler = property => event => {
            onRequestSort(event, property);
        };

        return (
            <TableHead>
                <StyledTableRow>
                    {headCells.map(headCell => (
                        <StyledTableCell
                            key={headCell.id}
                            sortDirection={orderBy === headCell.id ? order : false}>
                            <TableSortLabel active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                                onClick={createSortHandler(headCell.id)}>
                                {headCell.label || headCell.sortable}
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </StyledTableCell>
                    ))}
                </StyledTableRow>
            </TableHead>
        );
    }

    useEffect(() => {
        (async () => {
            if (state?.userAccessContext?.role === 2 || state?.userAccessContext?.role === 3) {
                await useFetch<IAdminGetClients[]>("UploadLog/ClientCodeGetPlacement").then((AdminGetClients) => {
                    setGetClientsList(AdminGetClients.data);
                });
            }
            else if (state?.userAccessContext?.role === 1 && state.userAccessContext?.id !== undefined) {
                await useFetch<IAdminGetClients[]>(`UploadLog/ClientGetPlacement?userId=${state.userAccessContext?.id}`).then((AdminGetClients) => {
                    setGetClientsList(AdminGetClients.data);
                });
            }
            else if (state?.userAccessContext?.role === 0 && state.userAccessContext?.id !== undefined) {
                await useFetch<IAdminGetClients[]>(`UploadLog/ClientGetPlacement?userId=${state.userAccessContext?.id}`).then((AdminGetClients) => {
                    setGetClientsList(AdminGetClients.data);
                });
            }
        })()
    }, [])

    return (
        <React.Fragment>
            <div className={classes.div}>
                <Backdrop className={classes.backdrop} open={ProgressBar}>
                    <PuffLoader size={80} color={"white"} speedMultiplier={1} />
                </Backdrop>
                <Grid container spacing={0} className={classes.maingrid}>
                    <Box component="span" m={0} width="20%" >
                        <Typography variant="h5" gutterBottom className={`${classes.textStyle} ${"headertitle"}`}>
                            <b> WEBVIEW REPORT </b>
                        </Typography>
                    </Box>

                    <Box component="span" m={0} width="58%" >
                        <TextField
                            fullWidth
                            size="small"
                            id="standard-bare"
                            variant="outlined"
                            className={classes.searchBox}
                            type='text'
                            placeholder="Search Webview Reports"
                            onChange={e => setSearchReportType(e.target.value)}
                            inputProps={{ maxLength: 255 }}
                            InputProps={{
                                classes: {
                                    root: classes.outlinedInput,
                                    notchedOutline: classes.notchedOutline,
                                    input: classes.searchInput,
                                },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton >
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Box>

                    <Box component="span" width="17%" justifyContent="flex-end" display="flex">
                        <CreateReport getReports={getReportsAsync} reportId="-1" states={States} statuses={Statuses} phases={Phases} clients={Clients} />
                    </Box>
                    <Box component="span" m={0} width="5%" justifyContent="flex-end" display="flex" style={{ marginTop: '50px' }}>
                        <VideoPlayer video={constant?.video} title={constant?.videoTitle} />
                    </Box>

                    <Grid component={Paper} xs={12} sm={12} lg={12} >
                        <TableContainer component={Paper} className={`${classes.tableBody} ${"scrollbox"} ${"on-scrollbar"}`}>
                            <Table sx={{ minWidth: 650 }} aria-label="customized table" size="small" stickyHeader>
                                <EnhancedTableHead
                                    classes={classes}
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                />
                                <TableBody>
                                    {stableSort(ReportsList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage), getComparator(order, orderBy)).map(
                                        (row) => {
                                            return (
                                                <ExpandableRow onClick={handleRowClick} onDelete={getReportsAsync} selected={selected === row.id} key={row.id}
                                                    onCreate={getReportsAsync} row={row} clients={Clients} states={States} statuses={Statuses} phases={Phases}
                                                    isValid={isValid} operators={OperatorsList} fieldLists={fieldsList} />
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
                <Grid component={Paper} container spacing={0}>
                    <Box m={0} width="60%">
                        <div className="footer">
                            <Footer />
                        </div>
                    </Box>
                    <Box m={0} width="40%">
                        <div className="pagination">
                            <TablePagination
                                id="Report_TablePagination"
                                rowsPerPageOptions={[15, 25, 50]}
                                component="div"
                                count={ReportsList?.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </div>
                    </Box>
                </Grid>
            </div >
        </React.Fragment >
    )
}

export default WebviewReports

