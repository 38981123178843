import React, { useState } from 'react';
import { PuffLoader } from 'react-spinners';
import { useStyles } from '../../ManagementCSS';
import { Backdrop, Button, Dialog, DialogActions, DialogContent, Grid, TextField, Typography } from '@mui/material';
import { DialogTitleHeader } from '../../../../GlobalStyles/DialogStyle';
import { Transition } from '../../../../GlobalStyles/DialogBoxTransition';
import { IInsertRecordTypeDetail } from '../../../../../models/accesscontrol/PlacementTemplateRule';
import { usePost } from '../../../../../utils/apiHelper';
import * as constant from '../../../../../constants/Constant';
import { Snackbar } from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

const initialValue: IInsertRecordTypeDetail = {
  recordTypeId: 0,
  recordType: '',
  description: ''
}

const CreateRecordType: React.FC<{ onCreate: () => void }> = (props) => {
  const classes = useStyles();
  const { onCreate } = props;
  const [open, setOpen] = useState(false);
  const [ProgressBar, setShowProgressBar] = useState(false);
  const [recordTypeError, setRecordTypeError] = useState("");
  const [createRecordType, setCreateRecordType] = useState(false);
  const [recordTypeDetail, setRecordTypeDetail] = useState<IInsertRecordTypeDetail>(initialValue);

  const handleClose = () => {
    setOpen(false);
    setRecordTypeDetail(initialValue);
  }

  const handleOpen = () => {
    setOpen(true);
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setRecordTypeDetail(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = () => {
    setShowProgressBar(false);
    (async () => {
      let request: IInsertRecordTypeDetail = {
        recordTypeId: 0,
        recordType: recordTypeDetail.recordType,
        description: recordTypeDetail.description
      }
      await usePost("GlobalSettings/ManageRecordTypeDetail", request).then((r) => {
        onCreate();
        setCreateRecordType(true);
      }).finally(() => {
        setShowProgressBar(false);
        handleClose();
        setRecordTypeDetail(initialValue);
      });
    })()
  }

  const validateRecordTypeName = (e) => {
    const recordType = e.target.value;
    if (constant.NameRegex.test(recordType)) {
      setRecordTypeError("");
    } else {
      setRecordTypeError("Please enter valid Record Type");
    }
  };

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setCreateRecordType(false);
  };

  return (
    <React.Fragment>
      <Button variant='contained' color='primary' size='small' sx={{ mt: 2 }} onClick={handleOpen}>Add New Record Type</Button>
      <Dialog open={open} PaperProps={{ style: { borderRadius: 15 } }} maxWidth={'sm'} TransitionComponent={Transition}
        onClose={handleClose} aria-labelledby="responsive-dialog-title" >
        <Backdrop className={classes.backdrop} open={ProgressBar}>
          <PuffLoader size={80} color={"white"} speedMultiplier={1} />
        </Backdrop>
        <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
          <Typography variant="h6" gutterBottom className={classes.titleheader} fontWeight={600}>
            ADD NEW RECORD TYPE
          </Typography>
        </DialogTitleHeader>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
              <Typography variant='subtitle1' className={classes.headerName}>
                <b>Record Type</b>
              </Typography>
              <TextField
                name="recordType"
                value={recordTypeDetail?.recordType}
                onChange={(e) => { handleInputChange(e); validateRecordTypeName(e); }}
                label="Record Type"
                autoComplete="off"
                size="small"
                className={classes.keyTextFiled}
                fullWidth
                variant="outlined"
                inputProps={{ maxLength: 100 }}
                sx={{ mt: 1 }}
                required helperText={recordTypeError} error={!!recordTypeError}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography variant='subtitle1' className={classes.headerName}>
                <b>Description</b>
              </Typography>
              <TextField
                name="description"
                value={recordTypeDetail?.description}
                onChange={(e) => { handleInputChange(e); }}
                label="Description"
                autoComplete="off"
                size="small"
                className={classes.keyTextFiled}
                fullWidth
                variant="outlined"
                multiline
                rows={3}
                inputProps={{ maxLength: 100 }}
                sx={{ mt: 1 }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' size='small' color='primary' sx={{ mr: 1 }} onClick={handleSubmit} disabled={!recordTypeDetail.recordType.length || !recordTypeDetail.description.length}>
            Submit
          </Button>
          <Button variant='contained' size='small' color='error' sx={{ mr: 2 }} onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        className="snackBarStyle"
        open={createRecordType}
        autoHideDuration={4000}
        onClose={closeSnackbar}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Alert onClose={closeSnackbar} severity="success" className="alertStyle">
          Record Type Created Successfully!
        </Alert>
      </Snackbar>
    </React.Fragment>
  )
}

export default CreateRecordType