import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
        overflowY: 'hidden',
        minHeight: '40px !important',
        borderBottom: '2px solid #0072E5 !important'
    },
    title: {
        flex: 1,
        color: '#0072E5 !important',
        textAlign: 'center',
        fontSize: 18,
        marginRight: '100px'
    },
    iconvisible: {
        float: 'left',
        marginLeft: '15px'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    div: {
        flexGrow: 1,
        overflowY: 'hidden',
        overflowX: 'hidden',
    },
    IconStyle: {
        fontSize: 20,
        color: '#AEAEAE'
    },
    VisiblityIconStyle: {
        fontSize: 20,
        color: '#1E90FF'
    },
    menuButton: {
        marginTop: '5px',
        marginBottom: '5px',
        height: '40px',
        backgroundColor: 'white',
        cursor: 'default',
        '&:hover': {
            backgroundColor: 'white',
        }
    },
    menuButton1: {
        width: '120px',
        height: '40px'
    },
    dialog: {
        '& .MuiDialog-paper': {
            overflowY: 'hidden'
        }
    },
    toolbarStyle: {
        backgroundColor: 'white',
        minHeight: '40px'
    },
    closeIcon: {
        color: '#000',
        fontSize: 22
    }
}));

export default useStyles