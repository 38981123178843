import CloseIcon from '@material-ui/icons/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import TabContext from '@mui/lab/TabContext';
import Switch from '@mui/material/Switch';
import {
    AppBar, Backdrop, Box, Button, Checkbox, CircularProgress, Container, Dialog, DialogContent, FormControlLabel, FormGroup, Grid, IconButton,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Toolbar, Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { PuffLoader } from 'react-spinners';
import { Paper, Snackbar } from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { ARMLogo } from '../../../../../constants/Constant';
import { Transition } from '../../../../GlobalStyles/DialogBoxTransition';
import TooltipSmall from '../../../../GlobalStyles/TooltipSmall';
import { useStyles } from '../../../../../pages/NotificationCenter/NotificationCenterCss';
import { MapRuleCss } from '../ManageRuleCss';
import { EntityFieldName, ICreateAccount, IGetDefaultEntityRules, IGetValidFieldResult, ISelectedRule } from '../../../../../models/accesscontrol/PlacementTemplateRule';
import { useFetch, usePost } from '../../../../../utils/apiHelper';
import QueryBuilder from './QueryBuilder';
import { fileMockData } from './LoadMoackData';

const initialValue: ICreateAccount = {
    AccountNumber: '',
    ClientCode: '',
    FirstNameResponsibleParty: '',
    LastNameResponsibleParty: '',
    BillingAddressLine1: '',
    BillingAddressLine2: '',
    BillingCity: '',
    BillingState: '',
    BillingZipCode: '',
    BillingPhoneNumber: '',
    FirstDateOfDelinquency: '',
    TotalDue: '',
    ItemizationDate: '',
    BalanceAsOfItemizationDate: '',
    FeesAddSinceItemizationDate: '',
    InterestAddSinceItemizationDate: '',
    PaymentAppliedSinceItemizationDate: '',
    CreditsAppliedSinceItemizationDate: '',
    Phase: ''
}

const FileEntityRules: React.FC<{ entityId: number, field: EntityFieldName[], entityBtn: boolean }> = (props) => {
    const classes = useStyles();
    const classes1 = MapRuleCss();
    const { entityId, field } = props;
    const [open, setOpen] = useState(false);
    const [ProgressBar, setShowProgressBar] = useState(false);
    const [selectedTab, setSelectedTab] = useState<number>(1);
    const [formValues, setFormValues] = useState<ICreateAccount>(initialValue);
    const [results, setResults] = useState<IGetValidFieldResult[]>([]);
    const [expandedRow, setExpandedRow] = useState<number[]>([]);
    const [defaultEntityRules, setDefaultEntityRules] = useState<IGetDefaultEntityRules[]>([]);
    const [formattedValue, setFormattedValue] = useState<string>('');
    const [rulesSaved, setRulesSaved] = useState<boolean>(false);
    const [selectedRules, setSelectedRules] = useState<ISelectedRule[]>([]);
    const [loading, setLoading] = useState(false);
    const [entityRule, setEntityRule] = useState<IGetDefaultEntityRules[]>([]);
    const [useMockData, setUseMockData] = useState(false);

    const handleSwitchChange = (event) => {
        const useMock = event.target.checked;
        setUseMockData(useMock);
        if (useMock) {
            setFormValues(fileMockData);
        } else {
            setFormValues(initialValue);
        }
    };

    const handleOpen = () => {
        setOpen(true);
        getDefaultEntityRules();
        getEntityLevelRule();
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedTab(1);
        setFormValues(initialValue);
        setUseMockData(false);
    };

    const handleTabChange = (tabId: number) => {
        if (tabId !== selectedTab) {
            setSelectedTab(tabId);
        }
    };

    const handleEquationChange = (equation: string) => {
        setFormattedValue(equation);
    };

    const handleSaveEntityRule = () => {
        setShowProgressBar(true);
        selectedRules.forEach((selectedRule) => {
            const { ruleCategoryId, ruleOperationTypeEnumId } = selectedRule;
            (async () => {
                let request = {
                    fieldRuleId: 0,
                    ruleCategoryId: ruleCategoryId,
                    ruleOperationTypeEnumId: ruleOperationTypeEnumId,
                    entityId: entityId,
                    ruleCondition: formattedValue
                }
                await usePost('GlobalSettings/ManageEntityLevelRule', request).then(() => {
                    setShowProgressBar(false);
                }).finally(() => {
                    setRulesSaved(true);
                    getEntityLevelRule();
                })
            })();
        });
    }

    const handleInputChange = (name, value) => {
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const ValidateEntityRule = () => {
        setLoading(true);
        const createAccount: ICreateAccount = {
            ...formValues,
        };
        (async () => {
            let request = {
                "createAccount": createAccount,
                "entityId": entityId,
            };
            // API Validation
            await usePost<IGetValidFieldResult[]>('ARMPlacementAccountTest/ValidateARMPlacementEntityLevel', request).then((response) => {
                setResults(response?.data);
            }).catch((error) => {
                console.error('Error during API call:', error);
            }).finally(() => {
                setLoading(false);
            })
        })()
    }

    const getDefaultEntityRules = () => {
        setShowProgressBar(true);
        (async () => {
            await useFetch<IGetDefaultEntityRules[]>(`GlobalSettings/GetDefaultEntityRules`).then((result) => {
                setDefaultEntityRules(result?.data);
            }).finally(() => {
                setShowProgressBar(false);
            })
        })();
    };

    const getEntityLevelRule = () => {
        setShowProgressBar(true);
        (async () => {
            await useFetch<IGetDefaultEntityRules[]>(`GlobalSettings/GetEntityLevelRule?EntityId=${entityId}`).then((result) => {
                const savedRules = result?.data;
                setEntityRule(result?.data);
                const mappedSelectedRules = savedRules?.map(rule => ({
                    ruleCategoryId: rule?.ruleCategoryId,
                    ruleOperationTypeEnumId: rule?.ruleOperationTypeEnumId,
                    ruleCondition: rule?.ruleCondition
                }));
                setSelectedRules(mappedSelectedRules);
                const operationIds = savedRules?.map(rule => rule?.ruleOperationTypeEnumId);
                setExpandedRow(operationIds);
            }).finally(() => {
                setShowProgressBar(false);
            })
        })();
    }

    const isSelected = (ruleOperationTypeEnumId: number) => {
        return selectedRules.some(rule => rule?.ruleOperationTypeEnumId === ruleOperationTypeEnumId);
    };

    const handleCheckboxChange = (ruleCategoryId: number, ruleOperationTypeEnumId: number, checked: boolean) => {
        if (checked) {
            setSelectedRules(prev => [...prev, { ruleCategoryId, ruleOperationTypeEnumId }]);
        } else {
            setSelectedRules(prev => prev.filter(rule => rule?.ruleOperationTypeEnumId !== ruleOperationTypeEnumId));
        }
    };

    useEffect(() => {
        const operationIds = selectedRules.map(rule => rule?.ruleOperationTypeEnumId);
        setExpandedRow(operationIds);
    }, [selectedRules]);

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setRulesSaved(false);
    };

    return (
        <React.Fragment>
            <Button variant='contained' size='medium' fullWidth onClick={handleOpen}>
                File Rules
            </Button>
            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition} className={classes.dialog}>
                <AppBar position="fixed" className={classes.appBar}>
                    <Toolbar className={classes.toolbarStyle}>
                        <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                            <img alt="ARM_Solutions" className={classes.menuButton1} src={ARMLogo} />
                        </IconButton>
                        <Typography variant="body1" className={classes.title}>
                            <b>FILE RULES</b>
                        </Typography>
                        <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close" style={{ padding: '5px' }}>
                            <CloseIcon className={classes.closeIcon} />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Backdrop className={classes.backdrop} open={ProgressBar}>
                    <PuffLoader size={80} color={"white"} speedMultiplier={1} />
                </Backdrop>
                <DialogContent sx={{ mt: 6, padding: 0 }}>
                    <Container maxWidth="xl">
                        <Grid container spacing={0} mt={1}>
                            <Grid item xs={12}>
                                <Box width={'100%'} borderRadius={'10px'} sx={{ background: 'white' }}>
                                    <TabContext value={selectedTab.toString()}>
                                        <Box display={'flex'} borderBottom={'1px solid #ccc'} p={1}>
                                            <Button
                                                key={1}
                                                onClick={() => handleTabChange(1)}
                                                aria-selected={selectedTab === 1}
                                                sx={{
                                                    flex: 1,
                                                    m: 1,
                                                    border: selectedTab === 1 ? '2px solid #1976d2' : '.5px solid Black',
                                                    backgroundColor: selectedTab === 1 ? '#e3f2fd' : 'transparent',
                                                    color: selectedTab === 1 ? '#1976d2' : '#000'
                                                }}
                                            >
                                                Entity Rules
                                            </Button>
                                            <Button
                                                key={2}
                                                onClick={() => handleTabChange(2)}
                                                aria-selected={selectedTab === 2}
                                                sx={{
                                                    flex: 1,
                                                    m: 1,
                                                    border: selectedTab === 2 ? '2px solid #1976d2' : '.5px solid Black',
                                                    backgroundColor: selectedTab === 2 ? '#e3f2fd' : 'transparent',
                                                    color: selectedTab === 2 ? '#1976d2' : '#000'
                                                }}
                                            >
                                                Verify and Validate
                                            </Button>
                                        </Box>

                                        {selectedTab === 1 && (
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <TableContainer className={`scrollbox on-scrollbar ${classes.ruleTable}`}>
                                                        <Table aria-label="dense table" stickyHeader>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell></TableCell>
                                                                    <TableCell>RULE TYPE</TableCell>
                                                                    <TableCell align="left">OPERATION NAME</TableCell>
                                                                    <TableCell align="center">DESCRIPTION</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {defaultEntityRules.map((row) => (
                                                                    <React.Fragment key={row?.ruleOperationTypeEnumId}>
                                                                        <TableRow>
                                                                            <TableCell align="left" className={classes1.TableCellBody}>
                                                                                <Checkbox
                                                                                    size="medium"
                                                                                    checked={isSelected(row?.ruleOperationTypeEnumId)}
                                                                                    onChange={(e) => handleCheckboxChange(row?.ruleCategoryId, row?.ruleOperationTypeEnumId, e.target.checked)}
                                                                                />
                                                                            </TableCell>
                                                                            <TableCell align="left" className={classes1.TableCellBody}>
                                                                                {row?.ruleCategoryName.toString().toUpperCase()}
                                                                            </TableCell>
                                                                            <TableCell align="left" className={classes1.TableCellBody}>
                                                                                {row?.ruleOperationName.toUpperCase()}
                                                                            </TableCell>
                                                                            <TableCell align="center" className={classes1.TableCellBody}>
                                                                                <IconButton>
                                                                                    <TooltipSmall title={row?.description}>
                                                                                        <InfoOutlinedIcon color="primary" />
                                                                                    </TooltipSmall>
                                                                                </IconButton>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        {expandedRow.includes(row?.ruleOperationTypeEnumId) && (
                                                                            <TableRow>
                                                                                <TableCell colSpan={4} >
                                                                                    <Box sx={{ padding: 1, borderTop: '1px solid lightgray', bgcolor: '#F4F6F8', borderRadius: '8px' }}>
                                                                                        <QueryBuilder ruleName={row?.ruleOperationName} onEquationChange={handleEquationChange} ruleCondition={entityRule[0]?.ruleCondition} />
                                                                                    </Box>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        )}
                                                                    </React.Fragment>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                        <Box m={0} display={'flex'} justifyContent={'flex-end'} sx={{ padding: '16px' }}>
                                                            <Button variant="contained" size="small" sx={{ mt: 1, width: '250px' }} onClick={handleSaveEntityRule}>
                                                                Submit
                                                            </Button>
                                                        </Box>
                                                    </TableContainer>
                                                </Grid>
                                            </Grid>
                                        )}

                                        {selectedTab === 2 && (
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <TableContainer
                                                        sx={{
                                                            minHeight: 'calc(100vh - 200px)',
                                                            maxHeight: 'calc(100vh - 200px)',
                                                            border: '1px solid #007FFF',
                                                            borderRadius: '5px',
                                                            padding: 2,
                                                        }}
                                                        className={`scrollbox on-scrollbar`}
                                                    >
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={6}>
                                                                <Typography variant="h6" gutterBottom>
                                                                    File Level Validation
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={6} display="flex" justifyContent="flex-end">
                                                                <FormGroup>
                                                                    <FormControlLabel labelPlacement="start" control={<Switch checked={useMockData} onChange={handleSwitchChange} />} label="Load Data" />
                                                                </FormGroup>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container spacing={1} mt={1}>
                                                            {field?.filter(r => r.isRequired)?.map((r) => (
                                                                <Grid item xs={4}>
                                                                    <TextField
                                                                        label={r.fieldDisplayName}
                                                                        variant="outlined"
                                                                        size='small'
                                                                        name={r.fieldName}
                                                                        onChange={(e) => handleInputChange(r.fieldName, e.target.value)}
                                                                        value={formValues[r.fieldName]} 
                                                                        fullWidth
                                                                        InputProps={{
                                                                            readOnly: false
                                                                        }}
                                                                        sx={{ marginBottom: 2 }}
                                                                    />
                                                                </Grid>
                                                            ))}
                                                        </Grid>
                                                        <Box m={0} display={'flex'} justifyContent={'flex-end'} sx={{ padding: '16px' }}>
                                                            <Button variant="contained" color="primary" sx={{ marginBottom: 2 }} onClick={ValidateEntityRule} disabled={loading} size='small'>
                                                                {loading ? <CircularProgress size={24} /> : 'Validate'}
                                                            </Button>
                                                        </Box>
                                                        {results.length > 0 && (
                                                            <TableContainer component={Paper}>
                                                                <Table>
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <TableCell align="left" className={classes1.TableCellStyle}>VALUE</TableCell>
                                                                            <TableCell align="left" className={classes1.TableCellStyle}>VALID</TableCell>
                                                                            <TableCell align="left" className={classes1.TableCellStyle}>ERROR MESSAGE</TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {results?.map((result, index) => (
                                                                            <TableRow key={index}>
                                                                                <TableCell>{result?.fieldValue}</TableCell>
                                                                                <TableCell >{result?.isValid ? <span className={classes1.isDataValid}>Yes</span> : <span className={classes1.isDataInvalid}>No</span>}</TableCell>
                                                                                <TableCell>{result?.errorMessage || 'N/A'}</TableCell>
                                                                            </TableRow>
                                                                        ))}
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        )}
                                                    </TableContainer>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </TabContext>
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </DialogContent>
            </Dialog>
            <Snackbar
                className="snackBarStyle"
                open={rulesSaved}
                autoHideDuration={4000}
                onClose={closeSnackbar}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Alert onClose={closeSnackbar} severity="success" className="alertStyle">
                    Rules Saved Successfully!
                </Alert>
            </Snackbar>
        </React.Fragment>
    );
};

export default FileEntityRules;
