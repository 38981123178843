import {
    Box, Button, Dialog, DialogActions, DialogContent, Snackbar, Typography, useMediaQuery, useTheme
} from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import React, { useState } from 'react';
import { IUserInfo } from '../../../models/accesscontrol/IUserInfo';
import { GlobalStateAction, useGlobalState } from '../../../store/GlobalStore';
import { useFetch, usePost } from '../../../utils/apiHelper';
// Icons
import CancelIcon from '@material-ui/icons/Cancel';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import PendingIcon from '@mui/icons-material/Pending';
// Componant
import important from '../../../images/users/warning.svg';
import { Transition } from '../../GlobalStyles/DialogBoxTransition';
import { DialogTitleHeader } from '../../GlobalStyles/DialogStyle';
import { useStyles } from './UsersCss';

const InviteUser: React.FC<{ userRow: IUserInfo, onInvite: () => void, checkSuperAdmin: boolean }> = (props) => {
    const theme = useTheme();
    const classes = useStyles();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { state, dispatch } = useGlobalState();
    const [sendInvite, setInvite] = useState(false);
    const [open, setOpen] = useState(false);
    const [CheckEmail, setCheckEmail] = useState(false);
    const [message, setMessage] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string>("");

    const handleClose = () => {
        setOpen(false);
        setCheckEmail(false);
        setErrorMessage(null);
    };

    const handleCloseDialog = () => {
        setCheckEmail(false);
        setOpen(false);
        setErrorMessage(null);
    };

    const checkSecondaryEmail = () => {
        (async () => {
            let request = {
                "email": props?.userRow?.userName
            }
            try {
                const response = await fetch("https://webviewuatapi.armsolutions.com/api/UserSignup/CheckEmailExist?userEmail=" + props?.userRow?.userName);
                const data = await response.json();
                setErrorMessage("User cannot be invited because the email already exists in Azure AD. Please delete this user from Azure AD, then invite them again");
                console.log(data);
                setCheckEmail(true);
            } catch (error) {
                await usePost<any>("User/CheckSecondaryEmail", request).then((r) => {
                    if (r.data?.isExists === true && props?.userRow?.portalUserLogOn === true) {
                        setErrorMessage("Email id conflicts with existing primary email id");
                        setCheckEmail(true);
                    } else {
                        let a = props.userRow.portalUserExist ? "Re-Invite" : "Invite";
                        setMessage(a);
                        setOpen(true);
                    }
                });
            }
        })().finally(() => {
            props.onInvite();
        })
    }

    async function getInviteUsersAsync() {
        setOpen(false);
        dispatch({ type: GlobalStateAction.Busy });
        try {
            await useFetch<IUserInfo>("User/InviteUser?userId=" + props.userRow.id);
            props.onInvite();
        }
        catch (ex) {
            dispatch({ type: GlobalStateAction.Error, error: ex });
            dispatch({ type: GlobalStateAction.Idle });
        }
        finally {
            dispatch({ type: GlobalStateAction.Idle });
        }
    }

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setInvite(false);
    };

    return (
        <div>
            <Dialog TransitionComponent={Transition}
                fullScreen={fullScreen} open={open} onClose={handleClose}
                classes={{ paper: classes.dialogeInvitePaper }}
                aria-labelledby="responsive-dialog-title" PaperProps={{ style: { borderRadius: 10 } }} >
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                    <Typography variant="h6" className={classes.titleheader}>
                        {message?.toUpperCase()} USER
                    </Typography>
                </DialogTitleHeader>
                <DialogContent>
                    <Typography variant="h6" className={classes.textprop} gutterBottom>
                        Are you sure, you want to {message} this User ?
                    </Typography>
                </DialogContent>
                <DialogActions >
                    <Button size="small" id="DU_ok_btn" variant="contained" startIcon={<DoneAllIcon />} onClick={(_) => { getInviteUsersAsync(); setInvite(true); }} className={classes.yesButton}>
                        Yes
                    </Button>
                    <Button size="small" id="DU_Cancel_btn" variant="contained" startIcon={<CancelIcon />} autoFocus onClick={handleClose} color="primary" className={classes.noButton}>
                        No
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog TransitionComponent={Transition}
                fullScreen={fullScreen} open={CheckEmail} onClose={handleCloseDialog} maxWidth={'md'}
                aria-labelledby="responsive-dialog-title" PaperProps={{ style: { borderRadius: 10 } }} >
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                    <Typography variant="h6" gutterBottom className={classes.titleheader}>
                        USER INFO
                    </Typography>
                </DialogTitleHeader>
                <DialogContent>
                    <Box display="flex" alignItems="center" justifyContent="center">
                        <img src={important} alt="Icon for Invite user Warning" className={classes.imageSize} />
                    </Box>
                    <Typography variant="h6" className={classes.textprop} gutterBottom>
                        <b className={classes.textcolor}>"{props?.userRow?.userName}"</b> {errorMessage}.
                        Please contact <b className={classes.textcolor}>"ClientService@armsolutions.com"</b> for resolution.
                    </Typography>
                </DialogContent>
                <DialogActions >
                    <Button id="DU_Cancel_btn" variant="contained" size="small" autoFocus onClick={handleCloseDialog} color="primary" className={classes.noButton}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Button size="small" id="InviteUser_btn" className={props?.userRow?.isProspectUser ? classes.buttonStyle : classes.button}
                startIcon={props.userRow.portalUserLogOn && !props?.userRow?.isProspectUser ? <DoneAllIcon />
                    : props.userRow.portalUserExist && !props?.userRow?.isProspectUser ? <ContactMailIcon />
                        : props?.userRow?.isProspectUser ?
                            <DoneAllIcon />
                            : <ContactMailIcon />}
                variant="contained" color="primary" onClick={(_) => { checkSecondaryEmail(); }} disabled={props.userRow.portalUserLogOn || (props.checkSuperAdmin && state.userAccessContext?.role === 2)} style={{ borderRadius: 20 }}>
                {props.userRow.portalUserLogOn && !props?.userRow?.isProspectUser ? "Invited"
                    : props.userRow.portalUserExist && !props?.userRow?.isProspectUser ? "Re-Invite"
                        : props?.userRow?.isProspectUser ?
                            "Approved"
                            : "Invite"}
            </Button>
            <Snackbar open={sendInvite} className="snackBarStyle" anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={closeSnackbar}>
                <Alert onClose={closeSnackbar} severity="success" className="alertStyle">
                    Invitation Sent Successfully!
                </Alert>
            </Snackbar>
        </div>
    );

}

export default InviteUser