import { makeStyles } from "@material-ui/core";

const useStylesTrustStatementReport = makeStyles((theme) => ({
    div: {
        flexGrow: 1,
        padding: '8px'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    maingrid: {
        backgroundColor: 'white',
        borderRadius: '10px',
        // border: '1px solid gray',
        boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
        filter: 'drop-shadow(0 -6mm 4mm gray))'
    },
    textStyle: {
        marginLeft: '5px',
        marginTop: '10px',
        fontSize: '13px !important'
    },
    tableContainer: {
        maxHeight: 505,
        minHeight: 505,
        [theme.breakpoints.up('lg')]: {
            maxHeight: 'calc( 100vh - 290px)',
            minHeight: 'calc( 100vh - 290px)',
        },
    },
    backButton: {
        borderRadius: "20px !important",
        marginTop: '5px !important',
        marginRight: '5px !important',
        fontSize: '12px !important',
    },
    searchClientFiles: {
        borderRadius: "5px !important",
        border: '1px lightgray solid !important',
        width: '100% !important',
        height: '35px !important',
        textIndent: '7px !important',
        paddingLeft: '0px !important',
        backgroundColor: 'white',
        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important',
        marginTop: '5px !important',
    },
    ftpoutlinedInput1: {
        fontSize: 12,
        color: "black",
        marginTop: '-9px',
        '&$focused $notchedOutline': {
            color: 'white',
            border: '0px'
        },
        '&.MuiOutlinedInput-adornedEnd': {
            paddingRight: '5px'
        }
    },
    ftpoutlinedInput: {
        fontSize: 12,
        color: "black",
        marginTop: '-8px',
        '&$focused $notchedOutline': {
            color: 'white',
            border: '0px'
        },
        '&.MuiOutlinedInput-adornedEnd': {
            paddingRight: '5px'
        }
    },
    ftpnotchedOutline: {
        color: 'white',
        border: '0px'
    },
    dateStyle: {
        width: '100%',
        marginTop: '10px',
        marginLeft: '5px',
        '& .MuiInputLabel-root': {
            fontSize: 14
        },
        '& .MuiInputBase-input': {
            fontSize: 14
        },
        '& .MuiIconButton-root': {
            padding: '0px'
        }
    },
    smallFontSize: {
        fontSize: "14px"
    },

    closeIconButton: {
        color: "#000",
        fontSize: 22,
    },
    tablebody1: {
        maxHeight: 500,
        minHeight: 500,
        marginTop: "70px !important",
        [theme.breakpoints.up("lg")]: {
            maxHeight: "calc( 100vh - 185px)",
            minHeight: "calc( 100vh - 185px)",
        },
    },
    ErrorMessageStyle1: {
        color: "red",
        marginTop: "20px !important",
        marginBottom: "20px !important",
        fontSize: "16px !important",
    },
    TableCellHeadStyle: {
        background: "#007FFF !important",
        color: "white !important",
        fontSize: "12px !important",
        padding: '3px 0px 3px 10px !important'
    },
}))

export { useStylesTrustStatementReport }